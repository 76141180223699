import React, { useState } from "react";
import Index from "../Index";
import { toast } from "react-toastify";
import PageIndex from "../../container/PageIndex";
import LogoutModal from "./LogoutModal";
import { setAdminData } from "../../redux/features/admin/adminSlice";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

export default function AdminHeader({ toggleSidebar }) {
  const navigate = Index.useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { adminData } = Index.useSelector((state) => state.admin);
  const dispatch = Index.useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    toggleSidebar();
  };
  const token = localStorage.getItem("token");

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(setAdminData({}));

    toast.success("Admin logout successfully");
    setOpenModal(false);
    navigate("/");
  };
  console.log("isOpen", isOpen)
  return (
    <>
      <Index.Box className="admin-header-main">
        <Index.Box className="admin-header-row">
          <Index.Box className="admin-header-left-main">
            <Index.Button
              className={`hamburger-menu my-logo ${isOpen ? "open" : ""}`}
              onClick={toggleMenu}
            >
              <img src={PageIndex.Svg.mobileMenuIcon} alt="hamburger" className="mobile-menu-icon" />
            </Index.Button>
            <Index.Typography component="p" variant="p" className="header-text">
              Hello, {adminData?.firstName}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="admin-header-drop-main">
            <Index.Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className='admin-drop-header-btn'
            >
              <img
                src={process.env.REACT_APP_IMAGE_URL + adminData?.profile}
                alt="profile"
                className="admin-header-profile-img"
                onError={(e) => {
                  e.target.src = PageIndex.Png.happy_ganesh;
                }}
              />
              <Index.Box className="admin-header-drop-main">
                <Index.Typography variant="h5" component="h5" className='admin-header-menu-name'>{adminData?.firstName + " " + adminData?.lastName}</Index.Typography>
                <Index.Typography variant="h5" component="h5" className='admin-header-menu-position'>{adminData?.role?.role}</Index.Typography>
              </Index.Box>
            </Index.Button>
            <Index.Menu
              id="basic-menu"
              className='admin-drop-header-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Index.Box className="profile-content-main">
                {/* <Index.Box className="admin-flex-drop-main">
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL + adminData?.profile
                    }
                    alt="profile"
                    className="admin-profile-img"
                    onError={(e) => {
                      e.target.src = PageIndex.Png.happy_ganesh;
                    }}
                  />
                </Index.Box> */}
                <Index.MenuItem
                  onClick={() => {
                    navigate("/admin/profile");
                    handleClose();
                  }}
                  className='admin-drop-header-menuitem'
                >
                  <Index.PersonIcon /> Profile
                </Index.MenuItem>
                <Index.MenuItem
                  onClick={() => {
                    navigate("/admin/change-password");
                    handleClose();
                  }}
                  className='admin-drop-header-menuitem'
                >
                  <Index.SettingsIcon /> Setting
                </Index.MenuItem>
                <Index.MenuItem
                  className='admin-drop-header-menuitem'
                  onClick={() => {
                    setOpenModal(true);
                    handleClose();
                  }}
                >
                  <Index.LogoutIcon />
                  Logout
                </Index.MenuItem>
              </Index.Box>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box >
      <LogoutModal
        open={openModal}
        setOpen={setOpenModal}
        handleLogout={handleLogout}
      />
    </>
  );
}
