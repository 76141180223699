import React, { useState } from "react";
import Index from "../Index";
import logo from "../../assets/images/png/web-logo.png";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

export default function AdminSidebar({ toggleSidebar }) {
  const [openSetting, setOpenSetting] = useState(false);
  const location = Index.useLocation();
  const navigate = Index.useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const handleClickAccount = () => {
    setOpenSetting(!openSetting);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [queryOpen, setQueryOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    toggleSidebar();
  };
  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
    setQueryOpen(false);
    setCategoryOpen(false);
  };
  const toggleQuerymenu = () => {
    setQueryOpen(!queryOpen);
    setSubmenuOpen(false);
    setCategoryOpen(false);
  };
  const toggleCategory = () => {
    setCategoryOpen(!categoryOpen);
    setSubmenuOpen(false);
    setQueryOpen(false);
  };

  return (
    <Index.Box className="admin-sidebar-main">
      <Index.Box className="admin-sidebar-logo">
        <img src={logo} className="sidebar-logo" alt="Arrow"></img>
        <Index.Typography component="p" variant="p" className="logo-text">
          Inventory
        </Index.Typography>
      </Index.Box>

      {/* <Index.Box className="profile-name-image">
        <Index.Box className="profile-image-sidebar">
          <img src={Index.Png.sidebar_profile}></img>
        </Index.Box>
        <Index.Box className="profile-name-sidebar">
          <Index.Typography component="p" variant="p">
            5852 2525
          </Index.Typography>
        </Index.Box>
      </Index.Box> */}

      <Index.Box className="admin-nav-main">
        <Index.List className="admin-sidebar-list">
          <Index.Tooltip
            title="Dashboard"
            arrow
            placement="right"
            className="admin-tooltip"
          >
            <Index.ListItem
              className={
                location.pathname === "/admin/dashboard"
                  ? "sidebar-list-item sidebar-list-item-active"
                  : "sidebar-list-item"
              }
            >
              <Index.Link to="/admin/dashboard" className="sidebar-nav-link">
                <Index.Box className="sidebar-nav-svg">
                  <Index.DashboardIcon />
                </Index.Box>

                <Index.Typography className="sidebar-nav-text">
                  Dashboard
                </Index.Typography>
              </Index.Link>
            </Index.ListItem>
          </Index.Tooltip>

          {permissions?.UserManagement?.View && (
            <Index.Tooltip
              title="Users"
              arrow
              placement="right"
              className="admin-tooltip"
            >
              <Index.ListItem
                className={
                  location.pathname === "/admin/user"
                    ? "sidebar-list-item sidebar-list-item-active"
                    : "sidebar-list-item"
                }
              >
                <Index.Link to="/admin/user" className="sidebar-nav-link">
                  <Index.Box className="sidebar-nav-svg">
                    <Index.ManageAccountsOutlined />
                  </Index.Box>

                  <Index.Typography className="sidebar-nav-text">
                    Users
                  </Index.Typography>
                </Index.Link>
              </Index.ListItem>
            </Index.Tooltip>
          )}

          {permissions?.Bills?.View && (
            <Index.Tooltip
              title="Bills"
              arrow
              placement="right"
              className="admin-tooltip"
            >
              <Index.ListItem
                className={
                  location.pathname === "/admin/bill/get-all-bill"
                    ? "sidebar-list-item sidebar-list-item-active"
                    : "sidebar-list-item"
                }
              >
                <Index.Link
                  to="/admin/bill/get-all-bill"
                  className="sidebar-nav-link"
                >
                  <Index.Box className="sidebar-nav-svg">
                    <Index.ReceiptIcon />
                  </Index.Box>
                  <Index.Typography className="sidebar-nav-text">
                    Bills
                  </Index.Typography>
                </Index.Link>
              </Index.ListItem>
            </Index.Tooltip>
          )}

          {permissions?.RoleManagement?.View && (
            <Index.Tooltip
              title="Roles"
              arrow
              placement="right"
              className="admin-tooltip"
            >
              <Index.ListItem
                className={
                  location.pathname === "/admin/role-permission" ||
                    location.pathname === "/admin/role-permission/add-edit"
                    ? "sidebar-list-item sidebar-list-item-active"
                    : "sidebar-list-item"
                }
              >
                <Index.Link
                  to="/admin/role-permission"
                  className="sidebar-nav-link"
                >
                  <Index.Box className="sidebar-nav-svg">
                    <Index.PeopleAltIcon />
                  </Index.Box>
                  <Index.Typography className="sidebar-nav-text">
                    Roles
                  </Index.Typography>
                </Index.Link>
              </Index.ListItem>
            </Index.Tooltip>
          )}
          {permissions?.IndentManagement?.View && (
            <Index.Tooltip
              title="Indents"
              arrow
              placement="right"
              className="admin-tooltip"
            >
              <Index.ListItem
                className={
                  location.pathname === "/admin/indent-request-list" ||
                    location.pathname === "/admin/indent-request/add-edit"
                    ? "sidebar-list-item sidebar-list-item-active"
                    : "sidebar-list-item"
                }
              >
                <Index.Link
                  to="/admin/indent-request-list"
                  className="sidebar-nav-link"
                >
                  <Index.Box className="sidebar-nav-svg">
                    <Index.ScatterPlotOutlined />
                  </Index.Box>
                  <Index.Typography className="sidebar-nav-text">
                    Indents
                  </Index.Typography>
                </Index.Link>
              </Index.ListItem>
            </Index.Tooltip>
          )}
          {permissions?.POGenerate?.View && (
            <Index.Tooltip
              title="Pogenerate"
              arrow
              placement="right"
              className="admin-tooltip"
            >
              <Index.ListItem
                className={
                  location.pathname === "/admin/po-generate"
                    ? "sidebar-list-item sidebar-list-item-active"
                    : "sidebar-list-item"
                }
              >
                <Index.Link to="/admin/po-generate" className="sidebar-nav-link">
                  <Index.Box className="sidebar-nav-svg">
                    <Index.RecentActorsOutlinedIcon />
                  </Index.Box>
                  <Index.Typography className="sidebar-nav-text">
                    Pogenerate
                  </Index.Typography>
                </Index.Link>
              </Index.ListItem>
            </Index.Tooltip>
          )}
          {/* Stock  */}
          {permissions?.StockManagement?.View && (
            <Index.Tooltip
              title="Stock"
              arrow
              placement="right"
              className="admin-tooltip"
            >
              <Index.ListItem
                className={
                  location.pathname === "/admin/stock"
                    ? "sidebar-list-item sidebar-list-item-active"
                    : "sidebar-list-item"
                }
              >
                <Index.Link to="/admin/stock" className="sidebar-nav-link">
                  <Index.Box className="sidebar-nav-svg">
                    <Index.InventoryIcon />
                  </Index.Box>
                  <Index.Typography className="sidebar-nav-text">
                    Stock
                  </Index.Typography>
                </Index.Link>
              </Index.ListItem>
            </Index.Tooltip>
          )}
          {(permissions?.CountryManagement?.View ||
            permissions?.StateManagement?.View ||
            permissions?.CityManagement?.View ||
            permissions?.UnitManagement?.View ||
            permissions?.CategoryManagement?.View ||
            permissions?.BrandManagement?.View ||
            permissions?.ProductManagement?.View ||
            permissions?.VendorManagement?.View) && (
              <Index.Tooltip
                title="Setup"
                arrow
                placement="right"
                className="admin-tooltip"
              >
                <Index.ListItem
                  className={
                    location.pathname === "/admin/role-permission" ||
                      location.pathname === "/admin/role-permission/add-edit"
                      ? "sidebar-list-item"
                      : "sidebar-list-item"
                  }
                  onClick={handleClickAccount}
                >
                  <Index.Link className="sidebar-nav-link">
                    <Index.Box className="sidebar-nav-svg">
                      <Index.SchoolOutlinedIcon />
                    </Index.Box>
                    <Index.Typography className="sidebar-nav-text">
                      Setup
                    </Index.Typography>
                    {openSetting ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Link>
                </Index.ListItem>
              </Index.Tooltip>
            )}
          <Index.Collapse
            in={openSetting}
            timeout="auto"
            unmountOnExit
            className="submenu-collapse"
          >
            <Index.List component="div" disablePadding>
              {permissions?.CountryManagement?.View && (
                <Index.Tooltip
                  title="Country"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.ListItem
                    className={
                      location.pathname === "/admin/country-master"
                        ? "sidebar-list-item sidebar-list-item-active"
                        : "sidebar-list-item"
                    }
                  >
                    <Index.Link
                      to="/admin/country-master"
                      className="sidebar-nav-link"
                    >
                      <Index.Box className="sidebar-nav-svg">
                        <Index.PublicOutlinedIcon />
                      </Index.Box>
                      <Index.Typography className="sidebar-nav-text">
                        Country
                      </Index.Typography>
                    </Index.Link>
                  </Index.ListItem>
                </Index.Tooltip>
              )}

              {permissions?.StateManagement?.View && (
                <Index.Tooltip
                  title="States"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.ListItem
                    className={
                      location.pathname === "/admin/state-master"
                        ? "sidebar-list-item sidebar-list-item-active"
                        : "sidebar-list-item"
                    }
                  >
                    <Index.Link
                      to="/admin/state-master"
                      className="sidebar-nav-link"
                    >
                      <Index.Box className="sidebar-nav-svg">
                        <Index.MapOutlinedIcon />
                      </Index.Box>
                      <Index.Typography className="sidebar-nav-text">
                        States
                      </Index.Typography>
                    </Index.Link>
                  </Index.ListItem>
                </Index.Tooltip>
              )}

              {permissions?.CityManagement?.View && (
                <Index.Tooltip
                  title="Cities"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.ListItem
                    className={
                      location.pathname === "/admin/city-master"
                        ? "sidebar-list-item sidebar-list-item-active"
                        : "sidebar-list-item"
                    }
                  >
                    <Index.Link
                      to="/admin/city-master"
                      className="sidebar-nav-link"
                    >
                      <Index.Box className="sidebar-nav-svg">
                        <Index.LocationCityOutlinedIcon />
                      </Index.Box>
                      <Index.Typography className="sidebar-nav-text">
                        Cities
                      </Index.Typography>
                    </Index.Link>
                  </Index.ListItem>
                </Index.Tooltip>
              )}

              {permissions?.UnitManagement?.View && (
                <Index.Tooltip
                  title="Units"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.ListItem
                    className={
                      location.pathname === "/admin/unit-master"
                        ? "sidebar-list-item sidebar-list-item-active"
                        : "sidebar-list-item"
                    }
                  >
                    <Index.Link
                      to="/admin/unit-master"
                      className="sidebar-nav-link"
                    >
                      <Index.Box className="sidebar-nav-svg">
                        <Index.BalanceOutlinedIcon />
                      </Index.Box>
                      <Index.Typography className="sidebar-nav-text">
                        Units
                      </Index.Typography>
                    </Index.Link>
                  </Index.ListItem>
                </Index.Tooltip>
              )}

              {permissions?.BrandManagement?.View && (
                <Index.Tooltip
                  title="Brands"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.ListItem
                    className={
                      location.pathname === "/admin/brand-master"
                        ? "sidebar-list-item sidebar-list-item-active"
                        : "sidebar-list-item"
                    }
                  >
                    <Index.Link
                      to="/admin/brand-master"
                      className="sidebar-nav-link"
                    >
                      <Index.Box className="sidebar-nav-svg">
                        <Index.BrandIcon />
                      </Index.Box>
                      <Index.Typography className="sidebar-nav-text">
                        Brands
                      </Index.Typography>
                    </Index.Link>
                  </Index.ListItem>
                </Index.Tooltip>
              )}

              {permissions?.CategoryManagement?.View && (
                <Index.Tooltip
                  title="Categories"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.ListItem
                    className={
                      location.pathname === "/admin/category"
                        ? "sidebar-list-item sidebar-list-item-active"
                        : "sidebar-list-item"
                    }
                  >
                    <Index.Link to="/admin/category" className="sidebar-nav-link">
                      <Index.Box className="sidebar-nav-svg">
                        <Index.CategoryOutlinedIcon />
                      </Index.Box>
                      <Index.Typography className="sidebar-nav-text">
                        Categories
                      </Index.Typography>
                    </Index.Link>
                  </Index.ListItem>
                </Index.Tooltip>
              )}

              {permissions?.ProductManagement?.View && (
                <Index.Tooltip
                  title="Products"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.ListItem
                    className={
                      location.pathname === "/admin/product-master" ||
                        location.pathname === "/admin/product-master/add-edit"
                        ? "sidebar-list-item sidebar-list-item-active"
                        : "sidebar-list-item"
                    }
                  >
                    <Index.Link
                      to="/admin/product-master"
                      className="sidebar-nav-link"
                    >
                      <Index.Box className="sidebar-nav-svg">
                        <Index.ProductIcon />
                      </Index.Box>
                      <Index.Typography className="sidebar-nav-text">
                        Products
                      </Index.Typography>
                    </Index.Link>
                  </Index.ListItem>
                </Index.Tooltip>
              )}

              {permissions?.VendorManagement?.View && (
                <Index.Tooltip
                  title="Vendors"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.ListItem
                    className={
                      location.pathname === "/admin/vendor-master" ||
                        location.pathname === "/admin/vendor-master/add-edit"
                        ? "sidebar-list-item sidebar-list-item-active"
                        : "sidebar-list-item"
                    }
                  >
                    <Index.Link
                      to="/admin/vendor-master"
                      className="sidebar-nav-link"
                    >
                      <Index.Box className="sidebar-nav-svg">
                        <Index.RecentActorsOutlinedIcon />
                      </Index.Box>
                      <Index.Typography className="sidebar-nav-text">
                        Vendors
                      </Index.Typography>
                    </Index.Link>
                  </Index.ListItem>
                </Index.Tooltip>
              )}
            </Index.List>
          </Index.Collapse>
        </Index.List>
      </Index.Box>
      {/* <Index.Box
        className={`hamburger-menu my-logo ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        {isOpen ? <ArrowRightIcon /> : <ArrowLeftIcon />}
      </Index.Box> */}
    </Index.Box>
  );
}
