import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getIndentRequestAction,
  indentApproveRejectAction,
} from "../../../../redux/features/admin/AdminServices";
import PageIndex from "../../../PageIndex";
import PrintButton from "./PrintButton";
import PrintButtonInward from "./PrintButtonInward";

const IndentRequestList = () => {
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [indentRequest, setIndentRequest] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [approveRejectStatus, setApproveRejectStatus] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [productData, setProductData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.IndentManagement;

  // modal start

  const modalOpen = (data) => {
    setIsOpen(!isOpen);
    setProductData(data);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // Approve reject
  const handleApproveModal = (data) => {
    setApproveRejectStatus(data);
    setApproveModalOpen(true);
  };

  const handleApproveReject = () => {
    setBtnLoading(true);
    indentApproveRejectAction(approveRejectStatus)
      .then((res) => {
        if (res.status == 200) {
          setBtnLoading(false);
          setApproveModalOpen(false);
          setApproveRejectStatus({});
          if (process.env.REACT_APP_ROLE_INVENTORY_OWNER && tabValue == 2) {
            setIndentRequest((prevData) =>
              prevData.filter((item) => {
                if (item._id != res?.data?._id) {
                  return {
                    item,
                  };
                }
              })
            );
          } else {
            setIndentRequest((prevData) =>
              prevData.map((item) => {
                if (item._id == res?.data?._id) {
                  return {
                    ...item,
                    headApproval: res?.data?.headApproval,
                    indentOwnerApproval: res?.data?.indentOwnerApproval,
                    status: res?.data?.status,
                  };
                }
                return item;
              })
            );
          }
        }
      })
      .catch(() => {
        setBtnLoading(false);
        setApproveModalOpen(false);
        setApproveRejectStatus({});
      });
  };

  const getAllIndentRequest = (searchTerm, pageNo, dataPerPage, tabNo) => {
    setLoading(true);
    const data = {
      page: pageNo ?? page,
      rowsPerPage: dataPerPage ?? rowsPerPage,
      search: searchTerm ?? search,
      type:
        (tabNo ?? tabValue) == 0
          ? "all"
          : (tabNo ?? tabValue) == 1
            ? "headPending"
            : (tabNo ?? tabValue) == 2
              ? "ownerPending"
              : null,
    };

    getIndentRequestAction(data).then((res) => {
      if (res?.status == 200) {
        setLoading(false);
        setIndentRequest(res?.data || []);
        setTotalCount(res?.pagination?.totalDocuments || 0);
        setRowsPerPage(res?.pagination?.pageSize);
        setPage(res?.pagination?.currentPage);
      } else {
        setLoading(false);
      }
    });
  };

  const {
    page,
    tabValue,
    search,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    handleSearchChange,
    handlePageChange,
    handleRowsPerPageChange,
    handleTabChange,
  } = PageIndex.useDebounceHook(getAllIndentRequest, setIndentRequest);

  useEffect(() => {
    getAllIndentRequest();
  }, []);

  if (!permissions?.View) {
    navigate("/admin/user");
    return null;
  }

  const handleEdit = (item) => {
    navigate("/admin/indent-request/add-edit", {
      state: item,
    });
  };
  // const handleInward = (item) => {
  //   navigate("/admin/indent-request/inward", {
  //     state: item,
  //   });
  // };
  if (permissions?.View) {
    return (
      <Index.Box className="admin-dashboard-content admin-indent-containt">
        <Index.Box className="admin-user-list-flex">
          <Index.Box className="admin-breadcrumb-main">
            <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
            <Index.Button className='admin-breadcrumb-text' onClick={() => {
              navigate("/admin/dashboard");
            }}>Dashboard</Index.Button>
            <Index.Typography className='admin-breadcrumb-text'>/</Index.Typography>
            <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'>Indent Request</Index.Button>
          </Index.Box>



          <Index.Box className="admin-search-right-main">
            <Index.Box className="search-input-box admin-input-box">
              <Index.Box className="admin-form-group">
                <Index.TextField
                  size="small"
                  placeholder="Search"
                  className="admin-form-control"
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/^\s+/, "")
                      .replace(/\s+/g, " ")
                      .slice(0, 30);
                    setSearchTerm(value);
                    handleSearchChange(value);
                  }}
                  value={searchTerm}
                />
                <img src={PageIndex.Svg.searchIcon} className="search-grey-img" alt="search" />
              </Index.Box>
            </Index.Box>
            {permissions?.Add && (
              <Index.Box className="primary-btn-main add-btn-main">
                <Index.CommonButton
                  startIcon={<Index.AddCircleOutlinedIcon fontSize="small" />}
                  btnLabel="Add Indent"
                  className={"primary-btn add-btn"}
                  onClick={() => navigate("/admin/indent-request/add-edit")}
                />
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        {adminData?.role?.role ==
          process.env.REACT_APP_ROLE_INVENTORY_OWNER && (
            <Index.Box>
              <Index.Tabs
                className="indent-tabs"
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Index.Tab label="All Request" {...a11yProps(0)} />
                <Index.Tab label="Waiting for Ho Approval" {...a11yProps(1)} />
                <Index.Tab label="Management Approval" {...a11yProps(2)} />
              </Index.Tabs>
            </Index.Box>
          )}
        <Index.Box className="common-card">
          <Index.Box className="page-table-main user-table-main">
            <Index.Paper
              // sx={{ width: "100%", overflow: "hidden" }}
              className="table-paper"
            >
              <Index.TableContainer
                // sx={{ maxHeight: 480 }}
                className="table-container"
              >
                <Index.Table
                  stickyHeader
                  aria-label="sticky table"
                  className="table"
                >
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell width="3%" className="table-th" variant="th">Sr.No</Index.TableCell>
                      <Index.TableCell width="7%" className="table-th" variant="th">Indent No.</Index.TableCell>
                      <Index.TableCell width="15%" className="table-th" variant="th">Products Name</Index.TableCell>
                      <Index.TableCell width="10%" className="table-th" variant="th">Created By</Index.TableCell>
                      <Index.TableCell width="10%" className="table-th" variant="th">Created Date</Index.TableCell>
                      <Index.TableCell width="5%" className="table-th">Status</Index.TableCell>
                      {(permissions?.Edit ||
                        permissions?.Delete ||
                        permissions?.View) && (
                          <Index.TableCell width="5%" className="table-th" variant="th" align="right">Action</Index.TableCell>
                        )}
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {indentRequest.length > 0 ? (
                      indentRequest.map((row, index) => (
                        <Index.TableRow key={row._id} className="table-row">
                          <Index.TableCell className="table-td" variant="td">
                            <Index.Typography className="admin-table-data-text">
                              {rowsPerPage * (page - 1) + index + 1}
                            </Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell className="table-td" variant="td">
                            <Index.Typography className="admin-table-data-text">
                              {row?.IndentNo || "-"}
                            </Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell className="table-td" variant="td">
                            {row?.items.length > 1 ? (
                              <Index.Box
                                className="product-qty-box"
                                onClick={() => modalOpen(row?.items)}
                              >
                                <Index.Typography className="admin-table-data-text">
                                  {row?.items[0]?.product?.productName}
                                </Index.Typography>
                                <span className="qty-title">
                                  {`(${PageIndex.numeralValueConverter(
                                    row?.items[0]?.quantity
                                  )})`}
                                </span>
                                <span className="qty-title">
                                  +{row?.items.length - 1}
                                </span>
                              </Index.Box>
                            ) : (
                              <Index.Box className="product-qty-box">
                                <Index.Typography className="admin-table-data-text">
                                  {row?.items[0]?.product?.productName}
                                </Index.Typography>
                                <span className="qty-title">
                                  {`(${PageIndex.numeralValueConverter(
                                    row?.items[0]?.quantity
                                  )})`}
                                </span>
                              </Index.Box>
                            )}
                          </Index.TableCell>
                          <Index.TableCell className="table-td" variant="td">
                            <Index.Box className="admin-table-data-flex">
                              {row?.createdBy
                                ? `${row.createdBy.firstName} ${row.createdBy.lastName}`
                                : "-"}
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell className="table-td" variant="td">
                            <Index.Box className="admin-table-data-flex">
                              {row?.createdAt
                                ? Index.moment(row?.createdAt).format(
                                  "DD-MMM-YYYY"
                                )
                                : "-"}
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell className="table-td" variant="td">
                            {row?.headApproval === "Pending" ? (
                              <Index.Box className="indent-status-main-box">
                                <span className="waiting-ho-approval">
                                  Waiting for HO Approval
                                </span>
                              </Index.Box>
                            ) : row?.indentOwnerApproval === "Pending" &&
                              row?.headApproval === "Approved" ? (
                              <Index.Box className="indent-status-main-box">
                                <span className="waiting-owner-approval">
                                  Waiting for Owner Approval
                                </span>
                              </Index.Box>
                            ) : row?.indentOwnerApproval === "Approved" && !row?.isInward ? (
                              <Index.Box className="indent-status-main-box">
                                <span className="po-genrated">
                                  PO Generated
                                </span>
                              </Index.Box>
                            ) : row?.indentOwnerApproval === "Rejected" ||
                              row?.headApproval === "Rejected" ? (
                              <Index.Box className="indent-status-main-box">
                                <span className="rejected">Rejected</span>
                              </Index.Box>
                            ) :
                              row?.indentOwnerApproval === "Approved" && row?.isInward
                                ? (
                                  <Index.Box className="indent-status-main-box">
                                    <span className="rejected">Inwarded</span>
                                  </Index.Box>
                                ) :
                                (
                                  "-"
                                )}
                          </Index.TableCell>

                          {(permissions?.Edit ||
                            permissions?.View ||
                            adminData?.role?.role ===
                            process.env.REACT_APP_ROLE_HEAD ||
                            process.env.REACT_APP_ROLE_INVENTORY_OWNER) && (
                              <Index.TableCell className="table-td" align="right">
                                <Index.Box className="admin-table-data-btn-flex">
                                  {
                                    !(row?.headApproval == "Approved" || row?.indentOwnerApproval == "Approved") &&
                                    permissions?.Edit &&
                                    (adminData?.role?.role ===
                                      process.env.REACT_APP_ROLE_HEAD ||
                                      adminData?.role?.role ===
                                      process.env.REACT_APP_ROLE_EMPLOYEE) && (
                                      <Index.Tooltip
                                        title="Edit"
                                        arrow
                                        placement="bottom"
                                        className="admin-tooltip"
                                      >
                                        <Index.Button
                                          className="admin-table-data-btn admin-table-edit-btn"
                                          onClick={() => handleEdit(row)}
                                          disabled={
                                            row?.headApproval == "Approved" ||
                                            row?.indentOwnerApproval ==
                                            "Approved" ||
                                            row?.headApproval == "Rejected" ||
                                            row?.indentOwnerApproval == "Rejected"
                                          }
                                        >
                                          <img
                                            src={PageIndex.Svg.Edit}
                                            alt="Edit Icon"
                                            className="table-icon"
                                          />
                                        </Index.Button>
                                      </Index.Tooltip>
                                    )}
                                  {permissions?.View && (
                                    <Index.Tooltip
                                      title="View"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn admin-table-view-btn"
                                        onClick={() => {
                                          navigate(
                                            "/admin/indent-request/view-Indent",
                                            { state: { viewData: row, _id: row._id } }
                                          );
                                        }}
                                      >
                                        <img
                                          src={PageIndex.Png.view}
                                          alt="View Icon"
                                          className="table-icon"
                                        />
                                      </Index.Button>
                                    </Index.Tooltip>
                                  )}
                                  {adminData?.role?.role ==
                                    process.env.REACT_APP_ROLE_HEAD ? (
                                    <>
                                      {(row?.headApproval == "Pending" ||
                                        row?.headApproval == "Approved") && (
                                          <Index.Tooltip
                                            title="Approve"
                                            arrow
                                            placement="bottom"
                                            className="admin-tooltip"
                                          >
                                            <Index.IconButton
                                              className="admin-table-data-btn admin-table-approve-btn"
                                              onClick={() => {
                                                handleApproveModal({
                                                  indentRequestId: row._id,
                                                  action: "approve",
                                                });
                                              }}
                                              disabled={
                                                row?.headApproval == "Approved"
                                              }
                                            >
                                              <img
                                                src={PageIndex.Svg.Approve}
                                                alt="Approve Icon"
                                                className="table-icon"
                                              />
                                            </Index.IconButton>
                                          </Index.Tooltip>
                                        )}
                                      {(row?.headApproval == "Pending" ||
                                        row?.headApproval == "Rejected") && (
                                          <Index.Tooltip
                                            title="Rejected"
                                            arrow
                                            placement="bottom"
                                            className="admin-tooltip"
                                          >
                                            <Index.IconButton
                                              className="admin-table-data-btn admin-table-reject-btn"
                                              onClick={() => {
                                                handleApproveModal({
                                                  indentRequestId: row._id,
                                                  action: "reject",
                                                });
                                              }}
                                              disabled={
                                                row?.headApproval == "Rejected"
                                              }
                                            >
                                              <img
                                                src={PageIndex.Svg.Reject}
                                                alt="Reject Icon"
                                                className="table-icon"
                                              />
                                            </Index.IconButton>
                                          </Index.Tooltip>
                                        )}
                                    </>
                                  ) : null}
                                  {adminData?.role?.role ==
                                    process.env.REACT_APP_ROLE_INVENTORY_OWNER &&

                                    row?.headApproval == "Approved" ? (
                                    <>
                                      {(row?.indentOwnerApproval == "Pending" ||
                                        row?.indentOwnerApproval ==
                                        "Approved") && (
                                          <Index.Tooltip
                                            title="Rejected"
                                            arrow
                                            placement="bottom"
                                            className="admin-tooltip"
                                          >
                                            <Index.IconButton
                                              className="admin-table-data-btn admin-table-reject-btn"
                                              onClick={() => {
                                                handleApproveModal({
                                                  indentRequestId: row._id,
                                                  action: "approve",
                                                });
                                              }}
                                              disabled={
                                                row?.indentOwnerApproval == "Approved"
                                              }
                                            >
                                              <img
                                                src={PageIndex.Svg.Approve}
                                                alt="Approve Icon"
                                                className="table-icon"
                                              />
                                            </Index.IconButton>
                                          </Index.Tooltip>
                                        )}
                                      {(row?.indentOwnerApproval == "Pending" ||
                                        row?.indentOwnerApproval ===
                                        "Rejected") && (
                                          <Index.Tooltip
                                            title="Rejected"
                                            arrow
                                            placement="bottom"
                                            className="admin-tooltip"
                                          >
                                            <Index.IconButton
                                              className="admin-table-data-btn admin-table-reject-btn"
                                              onClick={() => {
                                                handleApproveModal({
                                                  indentRequestId: row._id,
                                                  action: "reject",
                                                });
                                              }}
                                              disabled={
                                                row?.indentOwnerApproval ===
                                                "Rejected"
                                              }
                                            >
                                              <img
                                                src={PageIndex.Svg.Reject}
                                                alt="Reject Icon"
                                                className="table-icon"
                                              />
                                            </Index.IconButton>
                                          </Index.Tooltip>
                                        )}
                                    </>
                                  ) : null}
                                  {
                                    (row?.headApproval == "Approved" && row?.indentOwnerApproval == "Approved") && (
                                      <>
                                        <Index.Tooltip
                                          title="Print"
                                          arrow
                                          placement="bottom"
                                          className="admin-tooltip"
                                        >
                                          <Index.Button className="admin-table-print-btn admin-table-data-btn">
                                            <PrintButton printData={row} className="table-icon" />
                                          </Index.Button>
                                        </Index.Tooltip>
                                      </>
                                    )
                                  }
                                </Index.Box>
                              </Index.TableCell>
                            )}
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          colSpan={10}
                          align="center"
                          className="data-not-found-main"
                        >
                          <p className="data-not-found-text">
                            {loading ? (
                              <PageIndex.PageLoader />
                            ) : (
                              "No data found..."
                            )}
                          </p>
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Paper>
            {totalCount > 10 && (
              <Index.Box className="table-pagination">
                <Index.TablePagination
                  component="div"
                  rowsPerPageOptions={[10, 20, 50]}
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>
        <PageIndex.ApproveModal
          open={approveModalOpen}
          setOpen={setApproveModalOpen}
          status={approveRejectStatus}
          handleApproveReject={handleApproveReject}
          loading={btnLoading}
        />
        <PageIndex.IndentModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          productData={productData}
          setProductData={setProductData}
        />
      </Index.Box>
    );
  } else {
    navigate("/admin/user");
  }
};

export default IndentRequestList;
