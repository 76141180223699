import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const AddUnit = (props) => {
  const dispatch = Index.useDispatch();
  const editData = props?.editData;
  const [loading, setLoading] = Index.useState(false);

  const initialValues = {
    name: editData?.name || "",
  };

  const handleBack = () => {
    props.setOpenDrawer(false);
    props.setEditData(null);
  };

  const addEditCity = (formData) => {
    setLoading(true);
    dispatch(PageIndex.addEditUnit(formData))
      .then((res) => {
        if (res?.payload) {
          props.setOpenDrawer(false);
          props.setEditData(null);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = Index.useFormik({
    initialValues: initialValues,
    validationSchema: Index.addUnitValidation,
    onSubmit: (values) => {
      const formData = {
        ...(editData?._id ? { id: editData._id } : {}),
        name: values.name?.trim(),
      };
      addEditCity(formData);
    },
  });

  return (
    <Index.Box className="drawer-main">
      <Index.Box className="drawer-header">
        <Index.Typography className="drawer-header-title">
          {editData ? "Update" : "Add"} Unit
        </Index.Typography>
        <Index.Box>
          <Index.CloseIcon
            className="drawer-cancel-icon"
            onClick={handleBack}
          />
        </Index.Box>
      </Index.Box>

      <form onSubmit={handleSubmit}>
        <Index.Box className="drawer-body">
          <Index.Box sx={{ width: 1 }} className="grid-main add-unit-row">
            <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
              <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                className="grid-column">
                <Index.Box className="add-unit-input-main admin-input-box">
                  <Index.FormHelperText className='admin-form-lable'>Unit Name</Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <Index.TextField
                      className="admin-form-control"
                      placeholder="Enter Unit name"
                      type="text"
                      size="small"
                      name="name"
                      value={values.name}
                      inputProps={{ maxLength: 30 }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^[a-zA-Z\s.]*$/.test(inputValue)) {
                          setFieldValue(
                            "name",
                            inputValue.replace(/^\s+/, "").replace(/\s+/g, " ")
                          );
                        }
                      }}
                      onBlur={handleBlur}
                    />
                    {errors?.name ? (
                      <Index.Typography className="admin-error-text">
                        {errors?.name}
                      </Index.Typography>
                    ) : null}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="drawer-footer">
          <Index.Box className="drawer-footer-button-main">
            <Index.Button className="drawer-footer-button" onClick={handleBack}>
              Cancel
            </Index.Button>
            <Index.CommonButton
              btnLabel={editData ? "Update" : "Add"}
              type="submit"
              loading={loading}
              className="drawer-footer-button"
            />
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Box>
  );
};

export default AddUnit;
