import React from 'react'
import Index from '../../../Index';
import PageIndex from '../../../PageIndex';
import { ActivityLog } from '../activityLog/ActivityLog';


export const ViewPoGenetare = () => {
    const { adminData } = Index.useSelector((state) => state.admin);
    const permissions = adminData?.role?.permissions?.POGenerate;
    const navigate = Index.useNavigate();
    const { state } = Index.useLocation()
    if (permissions?.View) {
        return (
            <>

                <Index.Box className="admin-dashboard-content admin-user-containt">
                    <Index.Box className="admin-user-list-flex">
                        <Index.Box className="admin-breadcrumb-main">
                            <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
                            <Index.Button className='admin-breadcrumb-text' onClick={() => {
                                navigate("/admin/po-generate");
                            }}>PO</Index.Button>
                            <Index.Typography className='admin-breadcrumb-text'>/</Index.Typography>
                            <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'>View</Index.Button>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>

                <Index.Box className="common-card view-pogenrate-card">
                    <Index.Box className="card-title-main">
                        <Index.Typography className="card-title">
                            View Indent
                        </Index.Typography>
                    </Index.Box>

                    <Index.Box className="view-page-main">
                        <Index.Box className="page-detail-flex">
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-lable"
                            >
                                Indent No. <span>:</span>
                            </Index.Typography>

                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-value"
                            >
                                {state?.viewData?.IndentNo}
                            </Index.Typography>
                        </Index.Box>

                        <Index.Box className="page-detail-flex">
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-lable"
                            >
                                Created By <span>:</span>
                            </Index.Typography>
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-value"
                            >
                                {`${state?.viewData?.createdBy.firstName} ${state?.viewData?.createdBy.lastName}`}
                            </Index.Typography>
                        </Index.Box>
                        <Index.Box className="page-detail-flex">
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-lable"
                            >
                                Created Date <span>:</span>
                            </Index.Typography>
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-value"
                            >
                                {Index.moment(state?.viewData?.createdAt).format("DD-MMM-YYYY")}
                            </Index.Typography>
                        </Index.Box>
                        <Index.Box className="page-detail-flex">
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-lable"
                            >
                                Status <span>:</span>
                            </Index.Typography>
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-value"
                            >
                                {!state?.viewData?.isInward ? (
                                    <span >PO Generated</span>
                                ) : state?.viewData?.isInward ? (
                                    <span >Inwarded</span>
                                ) :
                                    (
                                        '-'
                                    )}

                            </Index.Typography>
                        </Index.Box>
                        <Index.Box className="page-detail-flex">
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-lable"
                            >
                                Remarks <span>:</span>
                            </Index.Typography>
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-value"
                            >
                                {state?.viewData?.remark}
                            </Index.Typography>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>

                <Index.Box className="common-card">
                    <Index.Box className="page-table-main view-po-generate-table-main">
                        <Index.Paper
                            sx={{ width: "100%", overflow: "hidden" }}
                            className="table-paper"
                        >
                            <Index.TableContainer
                                // sx={{ maxHeight: 300 }}
                                className="table-container"
                            >
                                <Index.Table
                                    className="table custom-table"
                                    stickyHeader
                                    aria-label="sticky table"
                                >
                                    <Index.TableHead className="table-head">
                                        <Index.TableRow className="table-row">
                                            <Index.TableCell width="5%" className="table-th" variant="th">Sr.No.</Index.TableCell>
                                            <Index.TableCell width="10%" className="table-th" variant="th">Product Name</Index.TableCell>
                                            <Index.TableCell width="10%" className="table-th" variant="th">Product Code</Index.TableCell>
                                            <Index.TableCell width="10%" className="table-th" variant="th">Quantity</Index.TableCell>
                                            {/* <Index.TableCell align="left">Vendor Name</Index.TableCell> */}

                                        </Index.TableRow>
                                    </Index.TableHead>
                                    <Index.TableBody className="table-body">
                                        {state?.viewData?.items.map((product, index) => (
                                            <Index.TableRow key={product?.product} className="table-row">
                                                <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">
                                                        {index + 1}
                                                    </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">
                                                        {product?.product?.productName || '-'}
                                                    </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">
                                                        {product?.product?.productCode || '-'}
                                                    </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">
                                                        {PageIndex.numeralValueConverter(product?.quantity)}
                                                    </Index.Typography>
                                                </Index.TableCell>
                                                {/* <Index.TableCell>{product?.vendor?.vendorName || '-'}</Index.TableCell> */}
                                            </Index.TableRow>
                                        ))}

                                    </Index.TableBody>
                                </Index.Table>
                            </Index.TableContainer>
                        </Index.Paper>
                    </Index.Box>
                </Index.Box>

                {/* Activity Logs */}
                <ActivityLog moduleId={state?._id} />
            </>
        );
    } else {
        navigate("/admin/dashboard");
    }
}
