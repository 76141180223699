import { createSlice } from "@reduxjs/toolkit";
import { adminLogin } from "./AdminServices";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    adminData: {},
    adminToken: "",
    isLoading: true,
    isAuthenticated: false,
  },
  reducers: {
    adminLogout: (state) => {
      state.adminData = {};
      state.adminToken = "";
      state.isAuthenticated = false;
    },
    setAdminData: (state, action) => {
      state.adminData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.adminData = action.payload?.data;
      state.adminToken = action.payload?.data?.token;
    });
  },
});
export const { adminLogout, setAdminData } = adminSlice.actions;
export default adminSlice.reducer;
