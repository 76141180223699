import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { vendorMasterSchema } from "../../../../validation/Validation";

const AddEditVendor = () => {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const { state } = Index.useLocation();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.VendorManagement;

  const stateIds = states?.map((stateObj) => stateObj._id);
  const cityIds = cities?.map((cityObj) => cityObj._id);
  let editData = state?.item;

  let initialValues = {
    vendorName: editData?.vendorName || "",
    country: editData?.country || "",
    state: editData?.state || "",
    city: editData?.city || "",
    address: editData?.address || "",
    contactNo: editData?.contactNo || "",
    email: editData?.email || "",
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);

    const data = {
      vendorName: values.vendorName,
      country: values.country,
      state: values.state,
      city: values.city,
      address: values.address,
      email: values.email,
      contactNo: values.contactNo,
      ...(editData?._id ? { _id: editData._id } : {}),
    };
    const res = await PageIndex.addEditVendor(data);
    if ([200, 201].includes(res?.status)) {
      navigate("/admin/vendor-master");
    }
    setLoading(false);
  };

  const handleInput = (event) => {
    let value = event.target.value;
    value = value
      .replace(/[^a-zA-Z\s]/g, "")
      .replace(/^\s+/, "")
      .replace(/\s\s+/g, " ")
      .slice(0, 30);

    event.target.value = value;
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
    values,
    errors,
  } = Index.useFormik({
    initialValues,
    validationSchema: vendorMasterSchema,
    onSubmit: handleFormSubmit,
  });

  const getAllCountries = async () => {
    dispatch(PageIndex.getAllCountry())
      .then((res) => {
        setCountries(res?.payload?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllStates = async () => {
    dispatch(PageIndex.getStateListByCountry(values.country))
      .then((res) => {
        setStates(res?.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCities = async () => {
    dispatch(PageIndex.getCityListByState(values.state))
      .then((res) => {
        setCities(res?.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountryById = (id) =>
    countries?.find((country) => country._id === id) || null;

  const getStateById = (id) =>
    states?.find((state) => state._id === id) || null;

  const getCityById = (id) => cities?.find((city) => city._id === id) || null;

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    getAllStates();
  }, [values.country]);

  useEffect(() => {
    getAllCities();
  }, [values.state]);

  if (permissions?.Add || permissions?.Edit) {
    return (
      <>
        <Index.Box className="admin-dashboard-content add-vendor-containt">
          <Index.Box className="admin-user-list-flex">
            <Index.Box className="admin-breadcrumb-main">
              <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
              <Index.Button className='admin-breadcrumb-text' onClick={() => {
                navigate("/admin/vendor-master");
              }}>Vendor</Index.Button>
              <Index.Typography className='admin-breadcrumb-text'>/</Index.Typography>
              <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'>{editData ? "Update" : "Add"} Vendor</Index.Button>
            </Index.Box>
          </Index.Box>

          <Index.Box className="common-card">
            <Index.Box className="card-title-main">
              <Index.Typography className="card-title">
                Vendor Information
              </Index.Typography>
            </Index.Box>

            <form onSubmit={handleSubmit}>
              <Index.Box sx={{ width: 1 }} className="grid-main add-vendor-row">
                <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }}
                    className="grid-column">
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className='admin-form-lable'>Vendor Name</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Vendor name"
                          className="admin-form-control"
                          name="vendorName"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue?.toString().length <= 30) {
                              setFieldValue(
                                "vendorName",
                                inputValue
                                  .replace(/^\s+/, "")
                                  .replace(/\s+/g, " ")
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          value={values?.vendorName}
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {touched?.vendorName && errors?.vendorName}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }}
                    className="grid-column">
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className='admin-form-lable'>Select Country</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Autocomplete
                          className="admin-autocomplete admin-form-control"
                          size="small"
                          name="country"
                          disablePortal
                          options={countries}
                          getOptionLabel={(option) => option.countryName}
                          value={getCountryById(values.country) || null}
                          onChange={(event, newValue) => {
                            handleChange({
                              target: {
                                name: "country",
                                value: newValue ? newValue._id : "",
                              },
                            });
                          }}
                          renderOption={(props, option) => (
                            <Index.Box component="li" {...props} className="admin-autocomplete-option">
                              {option?.countryName}
                            </Index.Box>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Search Country"
                              onInput={handleInput}
                              className="admin-form-control"
                            />
                          )}
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {touched?.country && errors?.country}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }}
                    className="grid-column">
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className='admin-form-lable'>Select State</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Autocomplete
                          disabled={!values.country}
                          className="admin-autocomplete admin-form-control"
                          size="small"
                          name="state"
                          disablePortal
                          options={states}
                          getOptionLabel={(option) => option.stateName}
                          value={getStateById(values.state) || null}
                          onChange={(event, newValue) => {
                            handleChange({
                              target: {
                                name: "state",
                                value: newValue ? newValue._id : "",
                              },
                            });
                          }}
                          renderOption={(props, option) => (
                            <Index.Box component="li" {...props} className="admin-autocomplete-option">
                              {option?.stateName}
                            </Index.Box>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Search State"
                              onInput={handleInput}
                              className="admin-form-control"
                            />
                          )}
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {touched?.state && errors?.state}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }}
                    className="grid-column">
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className='admin-form-lable'>Select City</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Autocomplete
                          disabled={!values.state}
                          className="admin-autocomplete admin-form-control"
                          size="small"
                          name="city"
                          disablePortal
                          options={cities}
                          getOptionLabel={(option) => option.cityName}
                          value={getCityById(values.city) || null}
                          onChange={(event, newValue) => {
                            handleChange({
                              target: {
                                name: "city",
                                value: newValue ? newValue._id : "",
                              },
                            });
                          }}
                          renderOption={(props, option) => (
                            <Index.Box component="li" {...props} className="admin-autocomplete-option">
                              {option?.cityName}
                            </Index.Box>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Search City"
                              onInput={handleInput}
                              className="admin-form-control"
                            />
                          )}
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {touched?.city && errors?.city}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }}
                    className="grid-column">
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className='admin-form-lable'>Address</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          className="admin-form-control"
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Address"
                          autoComplete="off"
                          name="address"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue?.length <= 100) {
                              setFieldValue(
                                "address",
                                inputValue
                                  .replace(/^\s+/, "")
                                  .replace(/\s+/g, " ")
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          value={values?.address}
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {touched?.address && errors?.address}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }}
                    className="grid-column">
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className='admin-form-lable'>Contact Number</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          fullWidth
                          id="filled-hidden-label-normal"
                          type="text"
                          placeholder="Contact number"
                          className="admin-form-control"
                          name="contactNo"
                          onBlur={handleBlur}
                          value={values?.contactNo}
                          onChange={(e) => {
                            const inputValue = e.target.value.replace(
                              /[^\d]/g,
                              ""
                            );
                            if (inputValue.toString().length <= 10) {
                              setFieldValue("contactNo", inputValue);
                            }
                          }}
                          startadornment={
                            <Index.InputAdornment position="start"></Index.InputAdornment>
                          }
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {touched?.contactNo && errors?.contactNo}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}
                    className="grid-column">
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className='admin-form-lable'>Email</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          className="admin-form-control"
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Email"
                          autoComplete="off"
                          name="email"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setFieldValue("email", inputValue.trim());
                          }}
                          onBlur={handleBlur}
                          value={values?.email}
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {touched?.email && errors?.email}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="admin-btn-flex-end primary-btn-main">
                <Index.Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  className="primary-btn"
                >
                  {editData ? "Update" : "Submit"}
                </Index.Button>
              </Index.Box>
            </form>
          </Index.Box>
        </Index.Box>

      </>
    );
  } else {
    navigate("/admin/dashboard");
  }
};

export default AddEditVendor;
