import React, { useRef } from "react";
import PrintableComponent from "./PrintableComponent";
import { useLocation } from "react-router-dom";

const BillPdf = () => {
  const billRef = useRef();
  const location = useLocation();
  const { billData, index } = location?.state || {}; // Get the bill data passed through location state

  return (
    <div>
      <PrintableComponent ref={billRef} {...billData} index={index} />
    </div>
  );
};

export default BillPdf;
