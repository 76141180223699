import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { thunk } from "redux-thunk";
import AdminReducer from "../features/admin/adminSlice";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, AdminReducer);

export const store = configureStore({
  reducer: {
    admin: persistedReducer,
    // devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
  },
});
export const persistor = persistStore(store);
