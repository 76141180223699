import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { indentInwardValidation } from "../../../../validation/Validation";
import {
  addIndentRequestAction,
  addInward,
  getAllProductWithoutPagination,
  getAllVendorWithoutPagination,
  updateIndentRequestAction,
} from "../../../../redux/features/admin/AdminServices";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const IndentInward = () => {
  const navigate = Index.useNavigate();
  const location = Index.useLocation();
  const editData = location?.state;
  // console.log("editData", editData);

  const [btnLoading, setBtnLoading] = useState(false);
  // const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  // const [removeId, setRemoveId] = useState(null);
  // const [getAllProduct, setGetAllProduct] = useState([]);
  // const [getAllVendor, setGetAllVendor] = useState([]);

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.IndentManagement;

  const editProductdata = editData?.items.map((item) => {
    return {
      product: item?.product?._id,
      productName: item?.product?.productName,
      requestQuantity: item.quantity,
      quantity: item.quantity ? item.quantity : "",
      batchNo: item.batchNo ? item.batchNo : "",
      expireDate: item.expireDate ? item.expireDate : "",
      invoiceNo: item.invoiceNo ? item.invoiceNo : "",
      invoiceDate: item.invoiceDate ? item.invoiceDate : "",
      invoiceAmount: item.invoiceAmount ? item.invoiceAmount : "",
      supplierName: item.supplierName ? item.supplierName : "",
    };
  });

  const initialValues = {
    items: editData ? editProductdata : [],
    remark: editData?.remark || "",
    ...(editData ? { indentId: editData._id } : {}),
  };

  const handleFormSubmit = (values) => {
    setBtnLoading(true);
    if (editData) {
      const formData = new FormData();
      formData.append("_id", editData._id);
      formData.append("IndentId", editData.IndentId);
      formData.append("IndentNo", editData.IndentNo);
      formData.append("items", JSON.stringify(values.items));
      formData.append("invoiceNo", values.invoiceNo);
      formData.append("invoiceDate", values.invoiceDate);
      formData.append("invoiceAmount", values.invoiceAmount);
      formData.append("supplierName", values.supplierName);
      formData.append("image", image);
      formData.append("remark", values.remark);

      addInward(formData).then((res) => {
        if (res?.status == 200) {
          setBtnLoading(false);
          navigate("/admin/po-generate");
        }
      });
    } else {
      navigate("/admin/po-generate");
    }
  };

  // const handleGetAllProduct = () => {
  //   getAllProductWithoutPagination().then((res) => {
  //     setGetAllProduct(
  //       res?.data?.map((item) => {
  //         return {
  //           product: item?._id,
  //           productName: item?.productName,
  //           vendor: "",
  //           quantity: "",
  //         };
  //       })
  //     );
  //   });
  // };

  // useEffect(() => {
  //   handleGetAllProduct();
  // }, []);

  if (permissions?.Add || permissions?.Edit) {
    return (
      <>
        <Index.Box className="admin-dashboard-content admin-add-user-containt">
          <Index.Box className="admin-user-list-flex">
            <Index.Box className="admin-breadcrumb-main">
              <img
                src={PageIndex.Svg.homeIcon}
                className="admin-breadcrumb-img"
                alt="breadcrumb"
              />
              <Index.Button
                className="admin-breadcrumb-text"
                onClick={() => {
                  navigate("/admin/po-generate");
                }}
              >
                {/* Plamingo Orthopedic */}
                PO
              </Index.Button>
              <Index.Typography className="admin-breadcrumb-text">
                /
              </Index.Typography>
              <Index.Button className="admin-breadcrumb-text admin-breadcrumb-active">
                Inward
              </Index.Button>
            </Index.Box>
          </Index.Box>


          <Index.Formik
            initialValues={initialValues}
            validationSchema={indentInwardValidation}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              handleChange,
              setTouched,
              touched,
              handleSubmit,
              setFieldValue,
              errors,
              handleBlur,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                {/* <Index.Grid item xs={12} sm={4} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label input-label-bold"
                      >
                        Select Products
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Autocomplete
                          multiple
                          // limitTags={2}
                          name="items"
                          id="multiple-limit-tags-products"
                          className="placeholder-set-text-box user-select autocomplete custom-user-common-select"
                          options={getAllProduct}
                          getOptionLabel={(option) => option.productName}
                          value={values.items.map(
                            (item) =>
                              getAllProduct.find(
                                (product) => product.product === item.product
                              ) || null
                          )}
                          onBlur={(event) => {
                            handleBlur(event);
                            setTouched({ ...touched, items: true });
                          }}
                          onChange={(e, newValue) => {
                            const newItems = newValue.map((product) => {
                              const existingItem = values.items.find(
                                (item) => item.product === product.product
                              );
                              return existingItem
                                ? existingItem
                                : {
                                  product: product.product,
                                  productName: product.productName,
                                  vendor: "",
                                  quantity: "",
                                };
                            });
                            setFieldValue("items", newItems);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              onBlur={handleBlur}
                              {...params}
                              placeholder="Select products"
                              onInput={handleInput}
                            />
                          )}
                          sx={{ width: "500px" }}
                          renderTags={() => []}
                        />
                        <Index.Box className="error">
                          <Index.FormHelperText className="error-message">
                            {touched?.items &&
                              typeof errors.items == "string" &&
                              errors.items}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid> */}
                <Index.Box className="common-card">
                  {values?.items?.length ? (
                    <Index.Box className="page-table-main indent-inward-table-main common-card">
                      <Index.Paper
                        // sx={{ width: "100%", overflow: "hidden" }}
                        className="table-paper"
                      >
                        <Index.TableContainer
                          // sx={{ maxHeight: 300 }}
                          className="table-container"
                        >
                          <Index.Table
                            className="table custom-table"
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell width="3%" className="table-th" variant="th">
                                  Sr.No.
                                </Index.TableCell>
                                <Index.TableCell width="10%" className="table-th" variant="th">
                                  Product Name
                                </Index.TableCell>
                                <Index.TableCell width="10%" className="table-th" variant="th">
                                  Requested Quantity
                                </Index.TableCell>
                                <Index.TableCell width="10%" className="table-th" variant="th">
                                  Inward Quantity
                                </Index.TableCell>
                                <Index.TableCell width="10%" className="table-th" variant="th">
                                  Batch No.
                                </Index.TableCell>
                                <Index.TableCell width="10%" className="table-th" variant="th">
                                  Expiry Date
                                </Index.TableCell>
                                {/* <Index.TableCell width="10%" className="table-th" variant="th">
                                          Action
                                        </Index.TableCell> */}
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {values.items.map((product, index) => (
                                <Index.TableRow key={product?.product} className="table-row">
                                  <Index.TableCell className="table-td" variant="td">
                                    <Index.Typography className="admin-table-data-text">
                                      {index + 1}
                                    </Index.Typography>
                                  </Index.TableCell>
                                  <Index.TableCell className="table-td" variant="td">
                                    <Index.Typography className="admin-table-data-text">
                                      {product?.productName}
                                    </Index.Typography>
                                  </Index.TableCell>
                                  <Index.TableCell className="table-td" variant="td">
                                    <Index.Box className="add-user-input-main admin-input-box">
                                      <Index.Box className="admin-form-group">
                                        <Index.TextField
                                          hiddenLabel
                                          small
                                          // id={`requested-quantity-${product?.product}`}
                                          placeholder="Resquested Quantity"
                                          className="admin-form-control"
                                          name={`items[${index}].requestQuantity`}
                                          value={product.requestQuantity || ""}
                                          disabled
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.TableCell>
                                  <Index.TableCell className="table-td" variant="td">
                                    <Index.Box className="add-user-input-main admin-input-box">
                                      <Index.Box className="admin-form-group">
                                        <Index.TextField
                                          hiddenLabel
                                          small
                                          id={`quantity-${product?.product}`}
                                          placeholder="Quantity"
                                          className="admin-form-control"
                                          name={`items[${index}].quantity`}
                                          onChange={(e) => {
                                            const inputValue =
                                              e.target.value.replace(
                                                /[^\d]/g,
                                                ""
                                              );

                                            setFieldValue(
                                              `items[${index}].quantity`,
                                              inputValue
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          value={product.quantity || ""}
                                        />
                                        <Index.FormHelperText className="admin-error-text">
                                          {touched.items?.[index]
                                            ?.quantity &&
                                            errors.items?.[index]
                                              ?.quantity}
                                        </Index.FormHelperText>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.TableCell>
                                  <Index.TableCell className="table-td" variant="td">
                                    <Index.Box className="add-user-input-main admin-input-box">
                                      <Index.Box className="admin-form-group">
                                        <Index.TextField
                                          hiddenLabel
                                          small
                                          id={`batchNo-${product?.product}`}
                                          placeholder="Batch No"
                                          className="admin-form-control"
                                          name={`items[${index}].batchNo`}
                                          onChange={(e) => {
                                            const inputValue = e.target.value;

                                            setFieldValue(
                                              `items[${index}].batchNo`,
                                              inputValue
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          value={product.batchNo || ""}
                                        />
                                        <Index.FormHelperText className="admin-error-text">
                                          {touched.items?.[index]
                                            ?.batchNo &&
                                            errors.items?.[index]
                                              ?.batchNo}
                                        </Index.FormHelperText>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.TableCell>
                                  <Index.TableCell className="table-td" variant="td">
                                    <Index.Box className="add-user-input-main admin-input-box" position="relative">
                                      <Index.Box className="admin-form-group">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                          <DemoContainer components={['DatePicker']}>
                                            < DatePicker
                                              format="DD-MMM-YYYY"
                                              inputFormat="DD-MM-YYYY"
                                              className="admin-form-date-picker"
                                              id={`expireDate-${product?.product}`}
                                              name={`items[${index}].expireDate`}
                                              onChange={(date) => {
                                                setFieldValue(
                                                  `items[${index}].expireDate`,
                                                  date.$d
                                                );
                                              }}
                                            // onBlur={handleBlur}
                                            // value={product.expireDate || ""}
                                            />
                                          </DemoContainer>
                                          <Index.FormHelperText className="admin-error-text">
                                            {touched.items?.[index]
                                              ?.expireDate &&
                                              errors.items?.[index]
                                                ?.expireDate}
                                          </Index.FormHelperText>
                                        </LocalizationProvider>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Paper>
                    </Index.Box>
                  ) : null}
                </Index.Box>

                {/* Upload Bills */}

                <Index.Box className="indent-inward-card-main common-card">
                  <Index.Box className="card-title-main">
                    <Index.Typography className="card-title">
                      Uploads Bills
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="grid-main">
                    <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                      <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }} className="grid-column">
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable">Invoice No.</Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Invoice No."
                              className="admin-form-control"
                              name="invoiceNo"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue) {
                                  setFieldValue(
                                    "invoiceNo",
                                    inputValue
                                  );
                                }
                              }}

                              onBlur={handleBlur}
                              value={values?.invoiceNo}
                            />
                            <Index.FormHelperText className="admin-error-text">
                              {errors?.invoiceNo && errors?.invoiceNo}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }} className="grid-column">
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable">Invoice Date</Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker']}>
                                < DatePicker
                                  format="DD-MMM-YYYY"
                                  inputFormat="DD-MM-YYYY"
                                  className="admin-form-date-picker"
                                  name="invoiceDate"
                                  onChange={(date) => {
                                    setFieldValue(
                                      "invoiceDate",
                                      date.$d
                                    );
                                  }}
                                  // onBlur={handleBlur}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                // value={values?.invoiceDate || ""}
                                />
                              </DemoContainer>
                              <Index.FormHelperText className="admin-error-text">
                                {errors?.invoiceDate && errors?.invoiceDate}
                              </Index.FormHelperText>
                            </LocalizationProvider>

                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }} className="grid-column">
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable">Invoice Amount</Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Invoice Amount"
                              className="admin-form-control"
                              name="invoiceAmount"
                              onChange={(e) => {
                                const inputValue =
                                  e.target.value.replace(
                                    /[^\d]/g,
                                    ""
                                  );
                                if (inputValue) {
                                  setFieldValue(
                                    "invoiceAmount",
                                    inputValue
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              value={values?.invoiceAmount}
                            />
                            <Index.FormHelperText className="admin-error-text">
                              {errors?.invoiceAmount && errors?.invoiceAmount}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }} className="grid-column">
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable">Supplier Name</Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Supplier Name"
                              className="admin-form-control"
                              name="supplierName"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                                  setFieldValue(
                                    "supplierName",
                                    inputValue
                                      .replace(/^\s+/, "")
                                      .replace(/\s+/g, " ")
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              value={values?.supplierName}
                            />
                            <Index.FormHelperText className="admin-error-text">
                              {errors?.supplierName && errors?.supplierName}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }} className="grid-column">
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable"> Bill :<span className="sign-required">*</span></Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className={`${values?.image ? "main-upload-image-box custom-show-main-box" : "main-upload-image-box"}`}>
                              <Index.Box className="main-upload-image-input">
                                <Index.Box className="input-image-tag">
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    className="admin-form-control"
                                    type="file"
                                    name="image"
                                    accept=".jpeg, .jpg, .png,.pdf"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      try {
                                        if (
                                          e.currentTarget.files &&
                                          e.currentTarget.files[0]
                                        ) {
                                          setFieldValue(
                                            "image",
                                            e.currentTarget.files[0]
                                          );
                                          setImage(e.currentTarget.files[0]);
                                        }
                                      } catch (error) {
                                        e.currentTarget.value = null;
                                      }
                                    }}
                                  />
                                </Index.Box>
                              </Index.Box>
                              <Index.FormHelperText className="admin-error-text">
                                {errors?.image && !values?.image && errors?.image}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      {/* Upload Bills End */}
                      <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 12" }} className="grid-column">
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable">Remark</Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextareaAutosize
                              id="remark"
                              type="text"
                              placeholder="Enter your remarks"
                              multiline
                              minRows={3}
                              maxRows={3}
                              className="admin-form-control-textarea"
                              name="remark"
                              // onChange={handleChange}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                                  setFieldValue(
                                    "remark",
                                    inputValue
                                      .replace(/^\s+/, "")
                                      .replace(/\s+/g, " ")
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.remark}
                            />
                            <Index.FormHelperText className="admin-error-text">
                              {errors.remark && errors.remark}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="admin-btn-flex-end primary-btn-main">
                  <Index.CommonButton
                    btnLabel="Submit"
                    type="submit"
                    loading={btnLoading}
                    className="primary-btn"
                  />
                </Index.Box>
              </form>
            )}
          </Index.Formik>
        </Index.Box>
      </>
    );
  } else {
    navigate("/admin/user");
  }
}

export default IndentInward;
