import React, { useEffect, useState } from 'react'
import PageIndex from '../../../PageIndex';
import Index from '../../../Index';
import "./activityLog.css"
import { Avatar } from '@mui/material';
import moment from 'moment';
import { getAllActivityLogsByModule } from '../../../../redux/features/admin/AdminServices';

export const ActivityLog = ({ moduleId }) => {
    const [activityList, setActivityList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);

    const getAllActivityLogs = async () => {
        setLoading(true);
        const res = await getAllActivityLogsByModule(moduleId);
        if (res?.status === 200) {
            setActivityList(res?.data);
        }
        setLoading(false);
    };

    // const {
    //     page,
    //     search,
    //     rowsPerPage,
    //     setPage,
    //     setRowsPerPage,
    //     handleSearchChange,
    //     handlePageChange,
    //     handleRowsPerPageChange,
    // } = PageIndex.useDebounceHook(getAllActivityLogs, setActivityList);
    // console.log('fgjkdfjgk');

    useEffect(() => {
        getAllActivityLogs()
    }, []);
    return (
        <>
            <Index.Box className="common-card indent-activity-log-card">
                <Index.Box className="card-title-main">
                    <Index.Typography className="card-title">
                        Activity Logs
                    </Index.Typography>
                </Index.Box>
                <Index.Box className="activity-log-main">
                    {
                        activityList?.map((activity) => (
                            <Index.Box className="activity-log-flex">
                                <Index.Box className="activity-user-main">
                                    <Avatar
                                        className='activity-user-img'
                                        src={
                                            process.env.REACT_APP_IMAGE_URL +
                                            activity?.userId?.profile
                                        }
                                    />
                                </Index.Box>
                                <Index.Box className="activity-details">
                                    <Index.Box className="activity-log-inner-flex">
                                        <Index.Typography className="activity-user-name">
                                            {`${activity?.userId?.firstName} ${activity?.userId?.lastName} ${activity?.userId?.role?.role ? `(${activity?.userId?.role?.role})` : ""}`}
                                        </Index.Typography>
                                        <Index.Typography className="activity-time">
                                            {moment(activity.createdAt).format("DD MMM YYYY [at] h:mm:ss A")}
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="activity-message">{activity?.message}</Index.Box>
                                </Index.Box>
                            </Index.Box>
                        ))
                    }
                </Index.Box>
            </Index.Box>
        </>
    )
}
