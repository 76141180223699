import { useEffect, useRef } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const AddCity = (props) => {
  const dispatch = Index.useDispatch();
  const editData = props?.editData;
  const [countryList, setCountryList] = Index.useState([]);
  const [stateList, setStateList] = Index.useState([]);
  const [loading, setLoading] = Index.useState(false);

  const getCountryById = (id) =>
    countryList?.find((country) => country._id === id) || null;

  const getStateById = (id) =>
    stateList?.find((state) => state._id === id) || null;

  const initialValues = {
    cityName: editData?.cityName || "",
    stateId: editData?.stateId?._id || "",
    countryId: editData?.stateId?.countryId._id || "",
  };

  const addEditCity = (formData) => {
    setLoading(true);
    dispatch(PageIndex.addEditCity(formData))
      .then((res) => {
        if (res?.payload) {
          props.setOpenDrawer(false);
          props.setEditData(null);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInput = (event) => {
    let value = event.target.value;
    value = value
      .replace(/[^a-zA-Z\s]/g, "")
      .replace(/^\s+/, "")
      .replace(/\s\s+/g, " ")
      .slice(0, 30);

    event.target.value = value;
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    setTouched,
    touched,
  } = Index.useFormik({
    initialValues: initialValues,
    validationSchema: Index.addCityValidation,
    onSubmit: (values) => {
      const formData = {
        ...(editData?._id ? { id: editData._id } : {}),
        countryId: values.countryId,
        stateId: values.stateId,
        cityName: values.cityName?.trim(),
      };
      addEditCity(formData);
    },
  });

  const getStateList = async () => {
    dispatch(PageIndex.getStateListByCountry(values.countryId))
      .then((res) => {
        setStateList(res?.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountryList = async () => {
    dispatch(PageIndex.getAllCountry())
      .then((res) => {
        setCountryList(res?.payload?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    getStateList();
  }, [values.countryId]);

  const handleBack = () => {
    props.setOpenDrawer(false);
    props.setEditData(null);
  };

  return (
    <Index.Box className="drawer-main">
      <Index.Box className="drawer-header">
        <Index.Typography className="drawer-header-title">
          {editData ? "Update" : "Add"} City
        </Index.Typography>
        <Index.Box>
          <Index.CloseIcon
            className="drawer-cancel-icon"
            onClick={handleBack}
          />
        </Index.Box>
      </Index.Box>


      <form onSubmit={handleSubmit}>
        <Index.Box className="drawer-body">
            <Index.Box sx={{ width: 1 }} className="grid-main add-city-row">
              <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                  className="grid-column">
                  <Index.Box className="add-city-input-main admin-input-box">
                    <Index.FormHelperText className='admin-form-lable'> Country Name</Index.FormHelperText>
                    <Index.Box className="admin-input-box add-city-input-box">
                      <Index.Box className="admin-form-group">
                        <Index.Autocomplete
                          className="admin-autocomplete admin-form-control"
                          size="small"
                          name="countryId"
                          disablePortal
                          options={countryList}
                          onBlur={(event) => {
                            handleBlur(event);
                            setTouched({ ...touched, countryId: true });
                          }}
                          getOptionLabel={(option) => option.countryName}
                          value={getCountryById(values.countryId) || null}
                          onChange={(event, newValue) => {
                            handleChange({
                              target: {
                                name: "countryId",
                                value: newValue ? newValue._id : "",
                              },
                            });
                          }}
                          renderOption={(props, option) => (
                            <Index.Box component="li" {...props} className="admin-autocomplete-option">
                              {option.countryName}
                            </Index.Box>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Search Country"
                              className="admin-form-control"
                              onInput={handleInput}
                            />
                          )}
                        />
                        <Index.Typography className="admin-error-text">
                          {touched?.countryId && errors?.countryId}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>


                <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                  className="grid-column">
                  <Index.Box className="add-city-input-main admin-input-box">
                    <Index.FormHelperText className='admin-form-lable'>State Name</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Autocomplete
                          disabled={!values.countryId}
                          className="admin-autocomplete admin-form-control"
                          size="small"
                          name="stateId"
                          disablePortal
                          options={stateList}
                          getOptionLabel={(option) => option.stateName}
                          onBlur={(event) => {
                            handleBlur(event);
                            setTouched({ ...touched, stateId: true });
                          }}
                          value={getStateById(values.stateId) || null}
                          onChange={(event, newValue) => {
                            handleChange({
                              target: {
                                name: "stateId",
                                value: newValue ? newValue._id : "",
                              },
                            });
                          }}
                          renderOption={(props, option) => (
                            <Index.Box component="li" {...props} className="admin-autocomplete-option">
                              {option?.stateName}
                            </Index.Box>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Search State"
                              className="admin-form-control"
                              onInput={handleInput}
                            />
                          )}
                        />
                        <Index.Typography className="admin-error-text">
                          {touched?.stateId && errors?.stateId}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                </Index.Box>

                <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                  className="grid-column">
                  <Index.Box className="add-city-input-main admin-input-box">
                    <Index.FormHelperText className='admin-form-lable'>City Name{" "}</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          className="admin-form-control"
                          placeholder="Enter City name"
                          type="text"
                          size="small"
                          name="cityName"
                          value={values.cityName}
                          inputProps={{ maxLength: 30 }}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                              setFieldValue(
                                "cityName",
                                inputValue.replace(/^\s+/, "").replace(/\s+/g, " ")
                              );
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </Index.Box>
                      <Index.Typography className="admin-error-text">
                        {touched?.cityName && errors?.cityName}
                      </Index.Typography>
                    </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
        </Index.Box>
        <Index.Box className="drawer-footer">
          <Index.Box className="drawer-footer-button-main">
            <Index.Button className="drawer-footer-button" onClick={handleBack}>
              Cancel
            </Index.Button>
            <Index.CommonButton
              btnLabel={editData ? "Update" : "Add"}
              type="submit"
              loading={loading}
              className="drawer-footer-button"
            />
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Box>
  );
};

export default AddCity;
