import React, { useEffect, useState } from "react";

import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { addIndentRequestValidation } from "../../../../validation/Validation";
import {
  addIndentRequestAction,
  getAllProductWithoutPagination,
  getAllVendorWithoutPagination,
  updateIndentRequestAction,
} from "../../../../redux/features/admin/AdminServices";

const AddIndentRequest = () => {
  const navigate = Index.useNavigate();
  const location = Index.useLocation();
  const editData = location?.state;

  const [btnLoading, setBtnLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [removeId, setRemoveId] = useState(null);
  const [getAllProduct, setGetAllProduct] = useState([]);
  // const [getAllVendor, setGetAllVendor] = useState([]);

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.IndentManagement;

  const editProductdata = editData?.items.map((item) => {
    return {
      product: item?.product?._id,
      productName: item?.product?.productName,
      vendor: item?.vendor?._id,
      quantity: item.quantity,
    };
  });

  const initialValues = {
    items: editData ? editProductdata : [],
    remark: editData?.remark || "",
    ...(editData ? { indentId: editData._id } : {}),
  };

  const handleInput = (event) => {
    let value = event.target.value;
    value = value
      .replace(/[^a-zA-Z\s]/g, "")
      .replace(/^\s+/, "")
      .replace(/\s\s+/g, " ")
      .slice(0, 30);

    event.target.value = value;
  };

  const handleClick = () => {
    navigate("/admin/indent-request-list");
  };

  const handleDelete = (values, setFieldValue, productId) => {
    setOpen(false);
    setRemoveId(null);
    setFieldValue(
      "items",
      values.items.filter((product) => product.product !== productId)
    );
  };

  const handleFormSubmit = (values) => {
    if (editData) {
      updateIndentRequestAction(values).then((res) => {
        if (res?.status == 200) {
          navigate("/admin/indent-request-list");
        }
      });
    } else {
      addIndentRequestAction(values).then((res) => {
        if (res?.status == 200) {
          navigate("/admin/indent-request-list");
        }
      });
    }
  };

  const handleGetAllProduct = () => {
    getAllProductWithoutPagination().then((res) => {
      setGetAllProduct(
        res?.data?.map((item) => {
          return {
            product: item?._id,
            productName: item?.productName,
            vendor: "",
            quantity: "",
          };
        })
      );
    });
  };
  // const handleGetAllVendor = () => {
  //   getAllVendorWithoutPagination().then((res) => {
  //     setGetAllVendor(res?.data);
  //   });
  // };
  // useEffect(() => {
  //   handleGetAllVendor();
  // }, []);

  useEffect(() => {
    handleGetAllProduct();
  }, []);

  if (permissions?.Add || permissions?.Edit) {
    return (
      <>
        <Index.Box className="admin-dashboard-content admin-role-containt">
          <Index.Box className="admin-user-list-flex">
            <Index.Box className="admin-breadcrumb-main">
              <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
              <Index.Button className='admin-breadcrumb-text' onClick={() => {
                navigate("/admin/indent-request-list");
              }}>Indent Request</Index.Button>
              <Index.Typography className='admin-breadcrumb-text'>/</Index.Typography>
              <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'>{editData?._id ? "Edit Indent" : "Add Indent"}</Index.Button>
            </Index.Box>
          </Index.Box>

          {/* <Index.Box>
            <Index.CloseIcon
              className="cancel-icon"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/admin/indent-request-list")}
            />
          </Index.Box> */}
        </Index.Box>
        <Index.Box className="common-card">
          <Index.Box className="card-title-main">
            <Index.Typography className="card-title">
              {editData ? "Edit" : "Add"} Indent
            </Index.Typography>
          </Index.Box>
          <Index.Formik
            initialValues={initialValues}
            validationSchema={addIndentRequestValidation}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              handleChange,
              setTouched,
              touched,
              handleSubmit,
              setFieldValue,
              errors,
              handleBlur,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>


                <Index.Box sx={{ width: 1 }} className="grid-main add-indent-row">
                  <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                    <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }}
                      className="grid-column">
                      <Index.Box className="add-indent-input-main admin-input-box">
                        <Index.FormHelperText className='admin-form-lable'>Select Products</Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.Autocomplete
                            multiple
                            // limitTags={2}
                            name="items"
                            id="multiple-limit-tags-products"
                            className="admin-form-control admin-autocomplete"
                            options={getAllProduct}
                            getOptionLabel={(option) => option.productName}
                            value={values.items.map(
                              (item) =>
                                getAllProduct.find(
                                  (product) => product.product === item.product
                                ) || null
                            )}
                            onBlur={(event) => {
                              handleBlur(event);
                              setTouched({ ...touched, items: true });
                            }}
                            onChange={(e, newValue) => {
                              const newItems = newValue.map((product) => {
                                const existingItem = values.items.find(
                                  (item) => item.product === product.product
                                );
                                return existingItem
                                  ? existingItem
                                  : {
                                    product: product.product,
                                    productName: product.productName,
                                    vendor: "",
                                    quantity: "",
                                  };
                              });
                              setFieldValue("items", newItems);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                onBlur={handleBlur}
                                {...params}
                                placeholder="Select products"
                                onInput={handleInput}
                                className="admin-form-control"
                              />
                            )}
                            renderTags={() => []}
                          />
                          <Index.FormHelperText className="admin-error-text">
                            {touched?.items &&
                              typeof errors.items == "string" &&
                              errors.items}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>


                {values?.items?.length ? (
                  <>
                    <Index.Box className="page-table-main add-indent-table-main">
                      <Index.Paper
                        // sx={{ width: "100%", overflow: "hidden" }}
                        className="table-paper"
                      >
                        <Index.TableContainer
                          // sx={{ maxHeight: 300 }}
                          className="table-container"
                        >
                          <Index.Table
                            className="table custom-table"
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow>
                                <Index.TableCell className="table-th" variant="th">
                                  Sr.No.
                                </Index.TableCell>
                                <Index.TableCell className="table-th" variant="th">
                                  Product Name
                                </Index.TableCell>
                                <Index.TableCell className="table-th" variant="th">
                                  Quantity
                                </Index.TableCell>
                                {/* <Index.TableCell align="left">
                                          Vendor
                                        </Index.TableCell> */}
                                <Index.TableCell className="table-th" variant="th" align="right">
                                  Action
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {values.items.map((product, index) => (
                                <Index.TableRow key={product?.product} className="table-row">
                                  <Index.TableCell className="table-td" variant="td">
                                    <Index.Typography className="admin-table-data-text">
                                      {index + 1}
                                    </Index.Typography>
                                  </Index.TableCell>
                                  <Index.TableCell className="table-td" variant="td">
                                    <Index.Typography className="admin-table-data-text">
                                      {/* {product?.product?.productName} */}
                                      {product?.productName}
                                    </Index.Typography>
                                  </Index.TableCell>
                                  <Index.TableCell className="table-td" variant="td">
                                    <Index.Box className="admin-input-box">
                                      <Index.Box className="admin-form-group">
                                        <Index.TextField
                                          hiddenLabel
                                          small
                                          id={`quantity-${product?.product}`}
                                          placeholder="Quantity"
                                          className="admin-form-control"
                                          name={`items[${index}].quantity`}
                                          onChange={(e) => {
                                            const inputValue =
                                              e.target.value.replace(
                                                /[^\d]/g,
                                                ""
                                              );

                                            setFieldValue(
                                              `items[${index}].quantity`,
                                              inputValue
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          value={product.quantity || ""}
                                        />
                                      </Index.Box>
                                      <Index.FormHelperText className="admin-error-text">
                                        {touched.items?.[index]
                                          ?.quantity &&
                                          errors.items?.[index]
                                            ?.quantity}
                                      </Index.FormHelperText>
                                    </Index.Box>
                                  </Index.TableCell>
                                  {/* <Index.TableCell>
                                            <Index.Box className="input-design-div with-border">
                                              <Index.Autocomplete
                                                size="small"
                                                className="placeholder-set-text-box user-select custom-user-common-select autocomplete"
                                                fullWidth
                                                name={`items[${index}].vendor`}
                                                options={getAllVendor}
                                                onBlur={(event) => {
                                                  handleBlur(event);
                                                  setTouched({
                                                    ...touched,
                                                    [`items[${index}].vendor`]: true,
                                                  });
                                                }}
                                                getOptionLabel={(option) =>
                                                  option.vendorName || ""
                                                }
                                                value={
                                                  getAllVendor.find(
                                                    (vendor) =>
                                                      vendor._id ==
                                                      product?.vendor
                                                  ) || null
                                                }
                                                onChange={(e, newValue) => {
                                                  console.log(newValue);

                                                  setFieldValue(
                                                    `items[${index}].vendor`,
                                                    newValue ? newValue._id : ""
                                                  );
                                                }}
                                                renderInput={(params) => (
                                                  <Index.TextField
                                                    {...params}
                                                    placeholder="Select vendor"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    className="placeholder-set-text-box user-select"
                                                    onInput={handleInput}
                                                  />
                                                )}
                                                classes={{
                                                  paper:
                                                    "custom-product-dropdowns",
                                                }}
                                                // disableClearable
                                              />

                                              <Index.Box className="error">
                                                <Index.FormHelperText className="error-message">
                                                  {touched.items?.[index]
                                                    ?.vendor &&
                                                    errors.items?.[index]
                                                      ?.vendor}
                                                </Index.FormHelperText>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.TableCell> */}
                                  <Index.TableCell className="table-td action-row">
                                    <Index.Box className="admin-table-data-btn-flex">
                                      <Index.Button
                                        className="admin-table-data-btn admin-table-edit-btn"
                                        onClick={() => {
                                          handleDelete(
                                            values,
                                            setFieldValue,
                                            product?.product
                                          );
                                        }}
                                      >
                                        <img
                                          src={PageIndex.Svg.Delete}
                                          alt="Delete Icon"
                                          className="table-icon"
                                        />
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Paper>
                    </Index.Box>
                  </>
                ) : null}



                <Index.Box className="grid-main add-indent-row">
                  <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                    <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                      className="grid-column">
                      <Index.Box className="add-user-input-main admin-input-box">
                        <Index.FormHelperText className='admin-form-lable'>Remark</Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextareaAutosize
                            id="remark"
                            type="text"
                            placeholder="Enter your remarks"
                            multiline
                            minRows={3}
                            maxRows={3}
                            className="admin-form-control admin-form-control-textarea"
                            name="remark"
                            // onChange={handleChange}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                                setFieldValue(
                                  "remark",
                                  inputValue
                                    .replace(/^\s+/, "")
                                    .replace(/\s+/g, " ")
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.remark}
                          />
                          <Index.FormHelperText className="admin-error-text">
                            {touched.remark && errors.remark}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>



                <Index.Box className="admin-btn-flex-end primary-btn-main">
                  <Index.CommonButton
                    btnLabel={editData ? "Update" : "Add"}
                    type="submit"
                    loading={btnLoading}
                    className="primary-btn"
                  />
                </Index.Box>
              </form>
            )}
          </Index.Formik>
        </Index.Box>
      </>
    );
  } else {
    navigate("/admin/dashboard");
  }
};

export default AddIndentRequest;
