import React from "react";
import Index from "../../Index";
import { ButtonLoader } from "./ButtonLoader.js";

function CommonButton(props) {
  return (
    <>
      <Index.Box className="primary-btn-main">
        <Index.Button
          variant={props.variant}
          className={props.className}
          startIcon={props.startIcon}
          onClick={props.onClick}
          disableRipple
          disabled={props?.loading}
          type={props.type === "submit" ? "submit" : "button"}
        >
          {props?.loading ? <ButtonLoader /> : props.btnLabel}
        </Index.Button>
      </Index.Box>
    </>
  );
}

export default CommonButton;
