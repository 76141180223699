import React, { useState } from "react";
import Index from "../Index";
import { Outlet } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";

export default function AdminLayout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((e) => !e);
  };

  const closeSidebar = () => {
    setIsSidebarOpen((e) => !e);
  };

  return (
    <Index.Box
      id="sidebar-open"
      className={`admin-layout-main ${isSidebarOpen ? "sidebar-open" : ""}`}
    >
      <Index.Box className="overlay-sidebar" onClick={closeSidebar}></Index.Box>
      <Index.Box className="admin-layout-left-main">
        <AdminSidebar toggleSidebar={toggleSidebar} />
      </Index.Box>
      <Index.Box className="admin-layout-right-main">
        <AdminHeader toggleSidebar={toggleSidebar} />
        <Index.Box className="admin-layoyt-content-main">
          <Outlet />
        </Index.Box>
      </Index.Box>
      {/* <PagesIndex.AdminFooter /> */}
    </Index.Box>
  );
}


