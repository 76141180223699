import {
  Box,
  Chip,
  List,
  ListItem,
  Button,
  Menu,
  MenuItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Breadcrumbs,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Modal,
  FormHelperText,
  TextField,
  FormControl,
  TextareaAutosize,
  Select,
  OutlinedInput,
  InputAdornment,
  IconButton,
  styled,
  alpha,
  InputBase,
  FormControlLabel,
  Checkbox,
  Rating,
  Tabs,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  Avatar,
  Badge,
  Switch,
  Drawer,
  RadioGroup,
  Radio,
  Autocomplete,
} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import {
  ManageAccountsOutlined,
  AppRegistrationOutlined,
  ScatterPlotOutlined,
  SettingsOutlined,
  MenuOutlined,
  KeyboardArrowLeft,
  Edit,
  CloudUpload,
  ChevronRight,
} from "@mui/icons-material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import PercentIcon from "@mui/icons-material/Percent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { DialogContent, DialogTitle } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import CommonButton from "../component/common/button/CommonButton";
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import {
  addCityValidation,
  addCountryValidation,
  addStateValidation,
  addUnitValidation,
  addCategoryValidation,
  profileValidation,
  changePasswordValidation,
} from "../../src/validation/Validation";
import { getSingleUser } from "../redux/features/admin/AdminServices";
import dayjs from "dayjs";
import numeral from "numeral";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PrintIcon from '@mui/icons-material/Print';
import moment from "moment";
export default {
  PrintIcon,
  moment,
  addCategoryValidation,
  ExpandMoreIcon,
  ExpandLessIcon,
  Paper,
  UploadFileIcon,
  ArrowBackIcon,
  toast,
  DeleteIcon,
  EditIcon,
  useLocation,
  SendIcon,
  CircularProgress,
  Box,
  Chip,
  Link,
  Paper,
  List,
  ListItem,
  Button,
  Menu,
  MenuItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ExpandLess,
  ExpandMore,
  Collapse,
  Breadcrumbs,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Modal,
  TextField,
  FormControl,
  Select,
  TextareaAutosize,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Visibility,
  VisibilityOff,
  styled,
  alpha,
  InputBase,
  SearchIcon,
  FormControlLabel,
  Checkbox,
  CloudUploadIcon,
  AddCircleOutlinedIcon,
  Typography,
  Rating,
  MoreVertIcon,
  ClickAwayListener,
  Tabs,
  Tab,
  DialogTitle,
  DialogContent,
  CloseIcon,
  PercentIcon,
  AttachMoneyIcon,
  ArrowForwardIosSharpIcon,
  MuiAccordion,
  MuiAccordionSummary,
  MuiAccordionDetails,
  Accordion,
  AccordionDetails,
  // AccordionSummary,
  Stack,
  Formik,
  Form,
  useDispatch,
  useSelector,
  useNavigate,
  MuiOtpInput,
  CommonButton,
  FormHelperText,
  Avatar,
  Badge,
  addCountryValidation,
  addStateValidation,
  addCityValidation,
  addUnitValidation,
  useFormik,
  useEffect,
  Switch,
  ManageAccountsOutlined,
  AppRegistrationOutlined,
  ScatterPlotOutlined,
  SettingsOutlined,
  MenuOutlined,
  EditIcon,
  DeleteIcon,
  useState,
  Collapse,
  Drawer,
  KeyboardArrowLeft,
  CloudUpload,
  Edit,
  RadioGroup,
  Radio,
  ChevronRight,
  Autocomplete,
  getSingleUser,
  profileValidation,
  dayjs,
  changePasswordValidation,
  numeral,
  useRef,
  DashboardIcon
};
