

import React, { forwardRef } from "react";
import moment from "moment";
import logo from "../../../../assets/images/png/virtualHeightLogo.png";

const PrintableComponent = forwardRef(
  (
   props, ref
  ) => {
    function getFormattedInvoiceNumber() {
      return `#${String(billData?.index + 1).padStart(4, "0")}`; 
    }

    const { billData } = props;
    

    return (
      <table ref={ref} className="bill-container" cellSpacing="0" cellPadding="0" style={{ width: "100%", maxWidth: "700px", margin: "auto", border: "1px solid #e4e4e4", padding: "25px", backgroundColor: "#fff" }}>
        <tr>
          <td colSpan={2} style={{ borderBottom: "1px solid #e4e4e4", padding: "0px 0px 10px" }}>
            <table>
              <tr>
                <td style={{ textAlign: "left", width: "30%" }}>
                  <img src={logo} alt="Company Logo" style={{ width: "100%", maxWidth: "150px", height: "60px", objectFit: "contain" }}></img>
                </td>
                <td style={{ textAlign: "right", width: "70%" }}>
                  <h2 style={{ textAlign: "right", fontWeight: 600, marginBottom: "5px", fontSize: "13px", lineHeight: "23px", color: "#0a2540" }}>Virtual Height</h2>
                  <p style={{ textAlign: "right", fontWeight: 400, marginBottom: "5px", fontSize: "13px", lineHeight: "20px", color: "#687d92" }}>
                    {" "}
                    602, 655, 702/703, Iscon Emporio, Nr. Star Bazaar, Jodhpur Cross
                    Roads, Satellite, Ahmedabad-380015
                  </p>
                  <p style={{ textAlign: "right", fontWeight: 400, marginBottom: "0px", fontSize: "13px", lineHeight: "20px", color: "#687d92" }}>Phone : 951-098-7700</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td style={{ padding: "10px 0px" }}>
            <h2 style={{ fontWeight: 500, marginBottom: "3px", fontSize: "13px", lineHeight: "19px", color: "#1c252e" }}>BILL TO</h2>
            <p style={{ fontWeight: 400, marginBottom: "3px", fontSize: "13px", lineHeight: "19px", color: "#687d92" }}>Name: {billData?.row?.customerName}</p>
            <p style={{ fontWeight: 400, marginBottom: "3px", fontSize: "13px", lineHeight: "19px", color: "#687d92" }}>Mobile Number: {billData?.row?.mobileNumber}</p>
          </td>
          <td style={{ padding: "10px 0px", verticalAlign: "top" }}>
            <h2 style={{ fontWeight: 500, marginBottom: "3px", fontSize: "13px", lineHeight: "19px", color: "#1c252e", textAlign: "right" }}>INVOICE NO.
              <span>
                {billData?.row?.products?.length > 0
                  ? getFormattedInvoiceNumber(0)
                  : getFormattedInvoiceNumber(0)}
              </span>
            </h2>
            <p style={{ fontWeight: 400, marginBottom: "3px", fontSize: "13px", lineHeight: "19px", color: "#687d92", textAlign: "right" }}>Date: Invoice Date{moment().format("DD/MM/YYYY")}
              <span>
                {moment().format("DD/MM/YYYY")}
              </span>
            </p>
          </td>
        </tr>


        <tr>
          <td colSpan={2}>
            <table style={{ width: "100%", border: "1px solid #e4e4e4" }}>
              <thead>
                <tr>
                  <th style={{ borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4", fontWeight: 400, fontSize: "13px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", textAlign: "left" }}>Product Name</th>
                  <th style={{ borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4", fontWeight: 400, fontSize: "13px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", textAlign: "left" }}>Qty</th>
                  <th style={{ borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4", fontWeight: 400, fontSize: "13px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", textAlign: "left" }}>Unit Price</th>
                  <th style={{ borderBottom: "1px solid #e4e4e4", fontWeight: 400, fontSize: "13px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", textAlign: "right" }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {billData?.row?.products?.map((product, index) => (
                  <tr key={index}>
                    <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4" }}>{product?.productId?.productName}</td>
                    <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4" }}>{product?.productQuantity}</td>
                    <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4" }}>{product?.productId.MRP}</td>
                    <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", textAlign: "right" }}>{product?.price * product?.productQuantity}</td>
                  </tr>
                ))}
                {/* <tr>
                  <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4" }}>Discount</td>
                  <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4" }}></td>
                  <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4", }}>{billData?.row?.discount}</td>
                  <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", textAlign: "right" }}>{billData?.row?.discount}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4" }}>Discount Amount</td>
                  <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4" }}></td>
                  <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4", }}>{billData?.row?.discountPrice}</td>
                  <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", textAlign: "right" }}>{billData?.row?.discountPrice}</td>
                </tr> */}
                <tr>
                  <td colSpan={3} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4" }}>
                    Sub Total
                  </td>
                  <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", textAlign: "right" }}>
                    {billData?.row?.totalPrice - (billData?.row?.sGst + billData?.row?.cGst)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4" }}>
                    SGST (9%)
                  </td>
                  <td style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", textAlign: "right" }}>
                    {billData?.row?.sGst}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", borderRight: "1px solid #e4e4e4" }}>
                    CGST (9%)
                  </td>
                  <td colSpan={3} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderBottom: "1px solid #e4e4e4", textAlign: "right" }}>
                    {billData?.row?.cGst}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ fontWeight: 400, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", borderRight: "1px solid #e4e4e4" }}>
                    Total Price
                  </td>
                  <td style={{ fontWeight: 500, fontSize: "12px", lineHeight: "19px", color: "#687d92", padding: "5px 10px", textAlign: "right" }}>
                    {billData?.row?.totalPrice}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td colSpan={3}>
            <p style={{ textAlign: "center", fontWeight: 400, fontSize: "15px", margin: "10px 0px 0px", lineHeight: "22px", color: "#687d92" }}>
              Thank you for your business!
            </p>
          </td>
        </tr>
      </table>
    );
  }
);

export default PrintableComponent;
