// MyComponent.js
import React from 'react';
import './printComponent.css';
import PageIndex from '../../../PageIndex';
import moment from 'moment';

const POGeneratePrintComponent = React.forwardRef((props, ref) => {
  const { printData } = props;
  return (
    <div ref={ref} className='print-container'>
       <div className='print-main'>
        {/* <div className='print-logo'>
          <img src={PageIndex.Png.VHLogo} />
        </div> */}
        <div className='print-detail'>
          <div className='print-detail-left'>
            <div>
              <h3>To</h3>
              <p>
                Virtual Height
              </p>
              <p>
                Off: 719, Shekhar central, AB Rd, Manorama Ganj, Indore, Madhya Pradesh 452018
              </p>
            </div>

            <div>
              <h3>From</h3>
              <p>
                Off: 602, 655, 702/703, Iscon Emporio,
                Nr. Star Bazaar, Jodhpur Cross Roads,
                Satellite, Ahmedabad-380015
                +91 951-098-7700
              </p>
            </div>

          </div>
          <div className='print-detail-right-main'>
            <div className='print-logo'>
              <img src={PageIndex.Png.VHLogo} />
            </div>
            <div className='print-detail-right'>
              <table>
                <tbody>
                  <tr>
                    <th>Indent NO:</th>
                    <td>{printData?.IndentNo || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>BILL DATE:</th>
                    <td>{moment().format("DD-MMM-YYYY") || 'N/A'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='print-title'>
        <h1>Plamingo Orthopedic</h1>
      </div> */}
      <div className='pritn-data-table'>
        <table>
          <thead>
            <tr>
              <th>Sr No.</th>
              {/* <th>Category</th> */}
              <th>Product Name</th>
              <th>Qty.</th>
              <th>Rate.</th>
              {/* <th>Amount Rs.</th> */}
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {printData?.items && printData.items.length > 0 ? (
              <>
                {
                  printData.items.map((item, index) => (
                    <tr key={index} style={{ background: '#fff', color: '#000' }}>
                      <td>{index + 1}</td>
                      {/* <td>{item?.product?.productCategory?.category || 'N/A'}</td> */}
                      <td>{item?.product?.productName || 'N/A'}</td>
                      <td>{item?.quantity || 0}</td>
                      <td>{item?.product?.purchaseRate || 0}</td>
                      {/* <td>{item?.product?.MRP || 0}</td> */}
                      {/* <td>{(Number(item?.product?.MRP) * Number(item?.quantity)).toFixed(2)}</td> */}
                      <td>{(Number(item?.product?.purchaseRate) * Number(item?.quantity)).toFixed(2)}</td>
                    </tr>
                  ))
                }
                {/* Total Payable calculation */}
                <tr style={{ background: '#fff', color: '#000' }}>
                  <td colSpan="3"></td>
                  <td>Total Amount Rs.</td>
                  <td>
                    {
                      // printData.items
                      //   .reduce((total, item) => total + Number(item?.product?.MRP) * Number(item?.quantity), 0)
                      //   .toFixed(2)
                        printData.items
                        .reduce((total, item) => total + Number(item?.product?.purchaseRate) * Number(item?.quantity), 0)
                        .toFixed(2)
                    }
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="7">No items found</td>
              </tr>
            )}
          </tbody>

        </table>
      </div>
      {/* <div className='pritn-footer'>
        <div className='print-fotter-logo'>
          <img src={PageIndex.Png.VHLogo} />
        </div>
        <div className='print-footer-detail'>
          <h4>VIRTUAL HEIGHT PVT.LTD.</h4>
          <p>602, 655, 702/703, Iscon Emporio,</p>
          <p>Nr. Star Bazaar, Jodhpur Cross Roads,</p>
          <p>Satellite, Ahmedabad-380015</p>
          <p>https://pms.virtualheight.com | info@virtualheight.com </p>
        </div>
      </div> */}
    </div>
  );
});

export default POGeneratePrintComponent;
