import Index from "../../../container/Index";
import PageIndex from "../../../container/PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const BillModal = (props) => {
  //   const formattedItems = props?.productData?.map(
  //     (row) => row?.productId?.productName (row?.productId?.productQuantity),

  //   );

  //   const joinedItems = formattedItems?.join(", ");

  console.log(props.productData,"hjhjhhh");
  
  const formatQuantity = (productQuantity) => {
    if (productQuantity == null) return "0";
    return PageIndex.numeralValueConverter(productQuantity);
  };

  const formattedItems = props.productData.map((row) => {
    const productName = row?.productId?.productName;
    const productQuantity = formatQuantity(row?.productQuantity);
    return `${productName} (${productQuantity})`;
  });

  const joinedItems = formattedItems.join(", ");

  return (
    <Index.Modal
      className="admin-modal"
      open={props.isOpen}
      onClose={() => {
        props?.setIsOpen(false);
        props?.setProductData([]);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Index.Box
        sx={style}
        className="admin-modal-inner admin-delete-modal-inner-main"
      >
        <Index.Box className="admin-modal-header">
          <Index.Typography className="admin-modal-title">
            Product Name
          </Index.Typography>
          <Index.CloseIcon
            className="admin-modal-close-icon"
            onClick={() => props.setIsOpen(false)}
          />
        </Index.Box>
        <Index.Box className="product-box">
          <Index.Box className="product-qty-box">
            <span className="qty-title">{joinedItems}</span>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Modal>
  );
};
