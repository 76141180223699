import Png from "../assets/Png";
import { useNavigate } from "react-router-dom";


const PageIndex = {
  Png,
  useNavigate,
};

export default PageIndex;
