import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { userValidationSchema } from "../../../../validation/Validation";
import { styled } from "@mui/material";
import PageIndex from "../../../PageIndex";
import CloseIcon from "@mui/icons-material/Close";
import { addEditUser } from "../../../../redux/features/admin/AdminServices";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AddUser = () => {
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [image, setImage] = useState("");

  const dispatch = Index.useDispatch();
  const { state } = Index.useLocation();
  let editData = state?.item;

  // password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.UserManagement;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  let initialValues = {};
  if (editData) {
    initialValues = editData;
    initialValues.roleType = editData?.role?._id;
    initialValues.profile = editData?.profile || "";
    initialValues.password = ""; // Edit then password will blank
    initialValues.confirmPassword = "";
  } else {
    initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      initialValues: "",
      mobileNumber: "",
      roleType: "",
      profile: "",
      isEdit: editData ? true : false,
    };
  }
  const handleFormSubmit = async (values) => {
    setLoading(true);
    const data = new FormData();
    if (values?.firstName) {
      data.append("firstName", values.firstName);
    }
    if (values?.lastName) {
      data.append("lastName", values.lastName);
    }
    if (values?.email) {
      data.append("email", values.email);
    }
    if (values?.password !== "" && values?.password !== null) {
      data.append("password", values.password);
    }
    if (values?.mobileNumber) {
      data.append("mobileNumber", values.mobileNumber);
    }

    if (values?.roleType) {
      data.append("roleType", values.roleType);
    }
    if (values?.profile) {
      data.append("profile", values.profile);
    }

    if (editData) {
      data.append("adminId", editData?._id);
    }
    const res = await addEditUser(data);

    if (res) {
      setLoading(false);
      navigate("/admin/user");
    } else {
      setLoading(false);
    }
  };

  const getAllRoleData = async () => {
    try {
      const res = await PageIndex.getAllRole();
      if (res?.status === 200) {
        setRoleData(res?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getAllRoleData();
  }, []);

  if (permissions?.Add || permissions?.Edit) {
    return (
      <>
        <Index.Box className="admin-dashboard-content admin-add-user-containt">
          <Index.Box className="admin-user-list-flex">
            <Index.Box className="admin-breadcrumb-main">
              <img
                src={PageIndex.Svg.homeIcon}
                className="admin-breadcrumb-img"
                alt="breadcrumb"
              />
              <Index.Button
                className="admin-breadcrumb-text"
                onClick={() => {
                  navigate("/admin/user");
                }}
              >
                User
              </Index.Button>
              <Index.Typography className="admin-breadcrumb-text">
                /
              </Index.Typography>
              <Index.Button className="admin-breadcrumb-text admin-breadcrumb-active">
                {" "}
                {editData ? "Edit User" : "Add User"}
              </Index.Button>
            </Index.Box>
          </Index.Box>



          <Index.Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={userValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="common-card">
                  <Index.Box className="card-title-main">
                    <Index.Typography className="card-title">
                      User Information
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box
                    className="grid-main add-user-row"
                  >
                    <Index.Box className="add-user-left-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="add-user-input-main admin-input-box add-user-upload-input">
                            {/* <Index.FormHelperText className="admin-form-lable">
                            Profile image
                            <span className="required-sign">*</span>
                          </Index.FormHelperText> */}
                            {/* <Index.Box className="admin-form-group"> */}
                            {/* <Index.TextField
                                        hiddenLabel
                                        id="filled-hidden-label-normal"
                           className="admin-form-control"
                                        type="file"
                                        name="profile"
                                        // accept="image/*"
                                        accept=".jpeg, .jpg, .png"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          try {
                                            if (
                                              e.currentTarget.files &&
                                              e.currentTarget.files[0]
                                            ) {
                                              setFieldValue(
                                                "profile",
                                                e.currentTarget.files[0]
                                              );
                                              setImage(e.currentTarget.files[0]);
                                            }
                                          } catch (error) {
                                            e.currentTarget.value = null;
                                          }
                                        }}
                                      /> */}
                            {/* <Index.Box className="user-img-main overlap-img-box">
                                        <img
                                          src={
                                            image
                                              ? URL.createObjectURL(image)
                                              : editData?.profile
                                                ? `${process.env.REACT_APP_IMAGE_URL}${editData?.profile}`
                                                : ""
                                          }
                                          onError={(e) => {
                                            e.target.src = PageIndex.Png.profile;
                                          }}
                                          alt="error"
                                          className="user-img"
                                        />
                                      </Index.Box> */}
                            {/* </Index.Box> */}

                            <Index.Box
                              className={`${values?.profile
                                ? "file-upload-main file-upload-show-box"
                                : "file-upload-main"
                                }`}
                            >
                              {/* <Index.Box className="file-upload-inner">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                className="admin-form-control file-upload-control"
                                type="file"
                                name="profile"
                                // accept="image/*"
                                accept=".jpeg, .jpg, .png"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  try {
                                    if (
                                      e.currentTarget.files &&
                                      e.currentTarget.files[0]
                                    ) {
                                      setFieldValue(
                                        "profile",
                                        e.currentTarget.files[0]
                                      );
                                      setImage(e.currentTarget.files[0]);
                                    }
                                  } catch (error) {
                                    e.currentTarget.value = null;
                                  }
                                }}
                              />
                            </Index.Box> */}

                              <Index.Box className="admin-file-upload-btn-main">
                                {!errors?.profile &&
                                  (image || editData?.profile) ? (
                                  <img
                                    className="admin-upload-profile-img"
                                    src={
                                      image
                                        ? URL.createObjectURL(image)
                                        : editData?.profile
                                          ? `${process.env.REACT_APP_IMAGE_URL}${editData?.profile}`
                                          : ""
                                    }
                                    onError={(e) => {
                                      e.target.src = PageIndex.Svg.avtarIcon;
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="admin-upload-profile-img"
                                    src={PageIndex.Svg.avtarIcon}
                                    alt=""
                                  />
                                )}
                                <Index.Button
                                  variant="contained"
                                  component="label"
                                  className="admin-file-upload-btn"
                                >
                                  <img
                                    src={PageIndex.Svg.pencilIcon}
                                    className="admin-upload-icon"
                                    alt="upload img"
                                  ></img>
                                  <input
                                    type="file"
                                    name="profile"
                                    accept=".jpeg, .jpg, .png"
                                    className="upload-image-input"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      try {
                                        if (
                                          e.currentTarget.files &&
                                          e.currentTarget.files[0]
                                        ) {
                                          setFieldValue(
                                            "profile",
                                            e.currentTarget.files[0]
                                          );
                                          setImage(e.currentTarget.files[0]);
                                        }
                                      } catch (error) {
                                        e.currentTarget.value = null;
                                      }
                                    }}
                                    hidden
                                    multiple
                                  />
                                </Index.Button>
                                {/* 
                              <Index.Button
                                variant="contained"
                                component="label"
                                className="admin-file-upload-btn"
                              >
                                <img
                                  src={PageIndex.Svg.Edit}
                                  className="admin-upload-icon"
                                  alt="upload img"
                                ></img>
                                <input
                                  type="file"
                                  name="profile"
                                  accept=".jpeg, .jpg, .png"
                                  className="upload-image-input"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    try {
                                      if (
                                        e.currentTarget.files &&
                                        e.currentTarget.files[0]
                                      ) {
                                        setFieldValue(
                                          "profile",
                                          e.currentTarget.files[0]
                                        );
                                        setImage(e.currentTarget.files[0]);
                                      }
                                    } catch (error) {
                                      e.currentTarget.value = null;
                                    }
                                  }}
                                  hidden
                                  multiple
                                />
                              </Index.Button> */}
                              </Index.Box>

                              {/* <Index.Box className="file-upload-show-img">
                                <Index.Box className="admin-file-upload-btn">
                                  <img
                                    src={PageIndex.Svg.Edit}
                                    className="admin-upload-icon"
                                    alt="upload img"
                                  ></img>
                                </Index.Box>
                                {!errors?.profile &&
                                (image || editData?.profile) ? (
                                  <img
                                    src={
                                      image
                                        ? URL.createObjectURL(image)
                                        : editData?.profile
                                        ? `${process.env.REACT_APP_IMAGE_URL}${editData?.profile}`
                                        : ""
                                    }
                                    onError={(e) => {
                                      e.target.src = PageIndex.Svg.avtarIcon;
                                    }}
                                    alt="error"
                                    className="file-upload-img"
                                  />
                                ) : (
                                  <img
                                    // className="admin-upload-profile-img"
                                    src={PageIndex.Svg.avtarIcon}
                                    alt=""
                                    className="file-upload-img"
                                  />
                                )}
                              </Index.Box> */}
                            </Index.Box>
                            <Index.FormHelperText className="admin-error-text">
                              {touched?.profile &&
                                !values?.profile &&
                                errors?.profile}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="add-user-right-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="add-user-input-main admin-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              First Name<span className="required-sign">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="First name"
                                className="admin-form-control"
                                name="firstName"
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (inputValue?.toString().length <= 30) {
                                    setFieldValue(
                                      "firstName",
                                      inputValue
                                        .replace(/^\s+/, "")
                                        .replace(/\s+/g, " ")
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values?.firstName}
                              />
                              <Index.FormHelperText className="admin-error-text">
                                {touched?.firstName && errors?.firstName}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="add-user-input-main admin-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Last Name<span className="required-sign">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Last name"
                                className="admin-form-control"
                                name="lastName"
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (inputValue?.toString().length <= 30) {
                                    setFieldValue(
                                      "lastName",
                                      inputValue
                                        .replace(/^\s+/, "")
                                        .replace(/\s+/g, " ")
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values?.lastName}
                              />
                              <Index.FormHelperText className="admin-error-text">
                                {touched?.lastName && errors?.lastName}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="add-user-input-main admin-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Mobile Number
                              <span className="required-sign">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                fullWidth
                                id="filled-hidden-label-normal"
                                type="text"
                                placeholder="Mobile number"
                                className="admin-form-control"
                                name="mobileNumber"
                                onBlur={handleBlur}
                                value={values.mobileNumber}
                                onChange={(e) => {
                                  const inputValue = e.target.value.replace(
                                    /[^\d]/g,
                                    ""
                                  );
                                  if (inputValue.toString().length <= 10) {
                                    setFieldValue("mobileNumber", inputValue);
                                  }
                                }}
                                startAdornment={
                                  <Index.InputAdornment position="start"></Index.InputAdornment>
                                }
                              />
                              <Index.FormHelperText className="admin-error-text">
                                {touched?.mobileNumber && errors?.mobileNumber}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        {!editData && (
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 3",
                              lg: "span 3",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="add-user-input-main admin-input-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Email<span className="required-sign">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.TextField
                                  className="admin-form-control"
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder="Email"
                                  autoComplete="off"
                                  name="email"
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue?.toString().length <= 30) {
                                      setFieldValue("email", inputValue.trim());
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  value={values?.email}
                                />
                                <Index.FormHelperText className="admin-error-text">
                                  {touched?.email && errors?.email}
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        )}

                        {!editData && (
                          <>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 3",
                                lg: "span 3",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="add-user-input-main admin-input-box">
                                <Index.FormHelperText className="admin-form-lable">
                                  Password<span className="required-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="admin-form-group">
                                  <Index.Stack component="label" noValidate>
                                    <Index.OutlinedInput
                                      // id="outlined-adornment-password"
                                      className="admin-form-control admin-form-control-eye"
                                      name="password"
                                      onBlur={handleBlur}
                                      placeholder="Password"
                                      autoComplete="new-password"
                                      type={showPassword ? "text" : "password"}
                                      inputProps={{ maxLength: 16 }}
                                      value={values.password}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "password",
                                          e.target.value.trim()
                                        );
                                      }}
                                      endAdornment={
                                        <Index.InputAdornment
                                          position="end"
                                          className="pass_position"
                                        >
                                          <Index.IconButton
                                            className="icon_end_icon eye-btn-set"
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <Index.VisibilityOff />
                                            ) : (
                                              <Index.Visibility />
                                            )}
                                          </Index.IconButton>
                                        </Index.InputAdornment>
                                      }
                                    />
                                    <Index.FormHelperText className="admin-error-text">
                                      {touched?.password && errors?.password}
                                    </Index.FormHelperText>
                                  </Index.Stack>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 3",
                                lg: "span 3",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="add-user-input-main admin-input-box">
                                <Index.FormHelperText className="admin-form-lable">
                                  Confirm password
                                  <span className="required-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="admin-form-group">
                                  <Index.Stack component="label" noValidate>
                                    <Index.OutlinedInput
                                      // id="outlined-adornment-password"
                                      className="admin-form-control admin-form-control-eye"
                                      name="confirmPassword"
                                      onBlur={handleBlur}
                                      placeholder="Confirm password"
                                      autoComplete="new-password"
                                      type={
                                        showConfirmPassword ? "text" : "password"
                                      }
                                      inputProps={{ maxLength: 16 }}
                                      value={values.confirmPassword}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "confirmPassword",
                                          e.target.value.trim()
                                        );
                                      }}
                                      endAdornment={
                                        <Index.InputAdornment
                                          position="end"
                                          className="pass_position"
                                        >
                                          <Index.IconButton
                                            className="icon_end_icon eye-btn-set"
                                            aria-label="toggle password visibility"
                                            onClick={handleShowConfirmPassword}
                                            onMouseDown={
                                              handleMouseDownConfirmPassword
                                            }
                                            edge="end"
                                          >
                                            {showConfirmPassword ? (
                                              <Index.VisibilityOff />
                                            ) : (
                                              <Index.Visibility />
                                            )}
                                          </Index.IconButton>
                                        </Index.InputAdornment>
                                      }
                                    />
                                    <Index.FormHelperText className="admin-error-text">
                                      {touched?.confirmPassword &&
                                        errors?.confirmPassword}
                                    </Index.FormHelperText>
                                  </Index.Stack>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </>
                        )}

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="add-user-input-main admin-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Role<span className="required-sign">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group admin-dropdown-box">
                              <Index.Select
                                className="admin-form-control admin-dropdown-select"
                                fullWidth
                                defaultValue={
                                  values?.roleType ? values?.roleType : ""
                                }
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={(e) => {
                                  setFieldValue("roleType", e.target.value);
                                }}
                              >
                                <Index.MenuItem
                                  disabled
                                  value={""}
                                  className="select-menu-item"
                                >
                                  Select role
                                </Index.MenuItem>
                                {roleData?.map((items, index) => (
                                  <Index.MenuItem
                                    value={items?._id}
                                    key={items?._id}
                                  >
                                    {items?.role}
                                  </Index.MenuItem>
                                ))}
                              </Index.Select>
                              <Index.FormHelperText className="admin-error-text">
                                {touched?.roleType && errors?.roleType}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="admin-btn-flex-end primary-btn-main">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    className="primary-btn"
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Index.Formik>
        </Index.Box>
      </>
    );
  } else {
    navigate("/admin/dashboard");
  }
};

export default AddUser;
