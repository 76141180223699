import Index from "../../../container/Index";

export const numeralValueConverter = (value) => {
    const convertValue = value
      ? Number.isInteger(value)
        ? Index.numeral(value).format("0a")
        : Index.numeral(value).format("0.00a")
      : 0;
  return convertValue
  
  };