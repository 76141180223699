import React, { useEffect, useState } from 'react'
import Index from '../../../Index';
import PageIndex from '../../../PageIndex';
import { ActivityLog } from '../activityLog/ActivityLog';
import { getAllStockListByProductId } from '../../../../redux/features/admin/AdminServices';
import moment from 'moment';


export const ViewStock = () => {
    const { adminData } = Index.useSelector((state) => state.admin);
    const permissions = adminData?.role?.permissions?.StockManagement;
    const navigate = Index.useNavigate();
    const { state } = Index.useLocation()
    const [searchTerm, setSearchTerm] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [stockListByPoduct, setStockListByProduct] = useState([]);
    const [loading, setLoading] = useState(false);
    // console.log("state",state);
    const getAllStockByProduct = (searchTerm, pageNo, dataPerPage, tabNo) => {

        setLoading(true);
        const data = {
            page: pageNo ?? page,
            rowsPerPage: dataPerPage ?? rowsPerPage,
            search: searchTerm ?? search,
            productId: state?.viewData?.product?._id
        };

        getAllStockListByProductId(data).then((res) => {
            if (res?.status == 200) {
                // console.log(res.data);

                setLoading(false);
                setStockListByProduct(res?.data || []);
                setTotalCount(res?.pagination?.totalDocuments || 0);
                setRowsPerPage(res?.pagination?.pageSize);
                setPage(res?.pagination?.currentPage);
            } else {
                setLoading(false);
            }
        });
    };

    const {
        page,
        tabValue,
        search,
        rowsPerPage,
        setPage,
        setRowsPerPage,
        handleSearchChange,
        handlePageChange,
        handleRowsPerPageChange,
        handleTabChange,
    } = PageIndex.useDebounceHook(getAllStockByProduct, setStockListByProduct);

    useEffect(() => {
        getAllStockByProduct();
    }, []);

    if (permissions?.View) {
        return (
            <>

                <Index.Box className="admin-dashboard-content admin-user-containt">
                    <Index.Box className="admin-user-list-flex">
                        <Index.Box className="admin-breadcrumb-main">
                            <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
                            <Index.Button className='admin-breadcrumb-text' onClick={() => {
                                navigate("/admin/stock");
                            }}>Stock</Index.Button>
                            <Index.Typography className='admin-breadcrumb-text'>/</Index.Typography>
                            <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'>View</Index.Button>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>

                <Index.Box className="common-card view-pogenrate-card">
                    <Index.Box className="card-title-main">
                        <Index.Typography className="card-title">
                            View Stock
                        </Index.Typography>
                    </Index.Box>

                    <Index.Box className="view-page-main">
                        <Index.Box className="page-detail-flex">
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-lable"
                            >
                                Product Name <span>:</span>
                            </Index.Typography>

                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-value"
                            >
                                {state?.viewData?.product?.productName}
                            </Index.Typography>
                        </Index.Box>

                        {/* <Index.Box className="page-detail-flex">
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-lable"
                            >
                                Batch No. <span>:</span>
                            </Index.Typography>
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-value"
                            >
                                {state?.viewData?.batchNo}
                            </Index.Typography>
                        </Index.Box>
                        <Index.Box className="page-detail-flex">
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-lable"
                            >
                               Expire Date <span>:</span>
                            </Index.Typography>
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-value"
                            >
                                {Index.moment(state?.viewData?.expireDate).format("DD/MM/YYYY")}
                            </Index.Typography>
                        </Index.Box> */}

                        <Index.Box className="page-detail-flex">
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-lable"
                            >
                                Current Stock <span>:</span>
                            </Index.Typography>
                            <Index.Typography
                                variant="label"
                                component="label"
                                className="page-value"
                            >
                                {state?.viewData?.quantity}
                            </Index.Typography>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>

                <Index.Box className="common-card view-indent-card">
                    <Index.Box className="page-table-main user-table-main">
                        <Index.Paper
                            // sx={{ width: "100%", overflow: "hidden" }}
                            className="table-paper"
                        >
                            <Index.TableContainer
                                // sx={{ maxHeight: 300 }}
                                className="table-container"
                            >
                                <Index.Table
                                    className="table custom-table"
                                    stickyHeader
                                    aria-label="sticky table"
                                >
                                    <Index.TableHead className="table-head">
                                        <Index.TableRow className="table-row">
                                            <Index.TableCell width="5%" className="table-th" variant="th">Sr.No.</Index.TableCell>
                                            <Index.TableCell width="20%" className="table-th" variant="th">Product Name</Index.TableCell>
                                            {/* <Index.TableCell width="10%" className="table-th" variant="th">Product Code</Index.TableCell> */}
                                            <Index.TableCell width="10%" className="table-th" variant="th">Batch No.</Index.TableCell>
                                            <Index.TableCell width="10%" className="table-th" variant="th">Expiry Date</Index.TableCell>
                                            <Index.TableCell width="10%" className="table-th" variant="th">Quantity</Index.TableCell>
                                            <Index.TableCell width="10%" className="table-th" variant="th">Created Date</Index.TableCell>

                                        </Index.TableRow>
                                    </Index.TableHead>
                                    <Index.TableBody className="table-body">
                                        {stockListByPoduct?.map((product, index) => (
                                            <Index.TableRow key={product?.product} className="table-row">
                                                <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">{index + 1}
                                                    </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">
                                                        {product?.product?.productName || '-'}
                                                    </Index.Typography>
                                                </Index.TableCell>
                                                {/* <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">
                                                        {product?.product?.productCode || '-'}
                                                    </Index.Typography>
                                                </Index.TableCell> */}
                                                <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">
                                                        {product?.batchNo || '-'}
                                                    </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">
                                                        {moment(product?.expireDate).format("DD-MMM-YYYY") || '-'}
                                                    </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">
                                                        {PageIndex.numeralValueConverter(product?.quantity) || '-'}
                                                    </Index.Typography>
                                                </Index.TableCell>
                                                <Index.TableCell className="table-td" variant="td">
                                                    <Index.Typography className="admin-table-data-text">
                                                        {moment(product?.createdAt).format("DD-MMM-YYYY") || '-'}
                                                    </Index.Typography>
                                                </Index.TableCell>
                                            </Index.TableRow>
                                        ))}

                                    </Index.TableBody>
                                </Index.Table>
                            </Index.TableContainer>
                        </Index.Paper>
                    </Index.Box>
                </Index.Box>

                {/* Activity Logs */}
                <ActivityLog moduleId={state?._id} />
            </>
        );
    } else {
        navigate("/admin/user");
    }
}
