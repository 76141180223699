import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { styled } from "@mui/material";
import { setAdminData } from "../../../../redux/features/admin/adminSlice";
import moment from "moment/moment";
import { ButtonLoaderForProfile } from "../../../../component/common/button/ButtonLoader";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const EditProfile = () => {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [image, setImage] = Index.useState("");
  const { adminData } = Index.useSelector((state) => state.admin);
  const [loading, setLoading] = useState(false);

  const getProfileData = async () => {
    try {
      const res = await PageIndex.getSingleUser(adminData._id);
      if (res?.status === 200) {
        dispatch(setAdminData(res?.data));
        navigate("/admin/profile");
        setLoading(false);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleFormSubmit = async (formData) => {
    setLoading(true);
    try {
      const res = await PageIndex.editProfile(formData);
      if (res?.status == 200) {
        getProfileData();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const initialValues = {
    firstName: adminData?.firstName || "",
    lastName: adminData?.lastName || "",
    gender: adminData?.gender || "",
    dob: moment(adminData?.dob).format("YYYY-MM-DD") || "",
    profile: adminData?.profile || "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = Index.useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Index.profileValidation,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("firstName", values.firstName?.trim());
      formData.append("lastName", values.lastName?.trim());
      formData.append("gender", values.gender);
      formData.append("dob", values.dob);
      formData.append("profile", values.profile);
      formData.append("adminId", adminData._id);
      handleFormSubmit(formData);
    },
  });

  return (
    <>
    <Index.Box className="admin-dashboard-content admin-edit-profile-containt">
      <Index.Box className="admin-user-list-flex">
        <Index.Box className="admin-breadcrumb-main">
          <img
            src={PageIndex.Svg.homeIcon}
            className="admin-breadcrumb-img"
            alt="breadcrumb"
          />
          <Index.Button
            className="admin-breadcrumb-text"
            onClick={() => {
              navigate("/admin/user");
            }}
          >
            User
          </Index.Button>
          <Index.Typography className="admin-breadcrumb-text">
            /
          </Index.Typography>
          <Index.Button className="admin-breadcrumb-text admin-breadcrumb-active">
            Edit Profile
          </Index.Button>
        </Index.Box>
      </Index.Box>
      <Index.Box className="common-card">
        <form onSubmit={handleSubmit}>
          <Index.Box className="grid-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="edit-profile-input-main admin-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Profile
                    </Index.FormHelperText>
                    <Index.Box className="admin-file-upload-btn-main">
                      {!errors?.profile && (image || adminData?.profile) ? (
                        <img
                          className="admin-upload-profile-img"
                          src={
                            image
                              ? URL.createObjectURL(image)
                              : adminData?.profile
                              ? `${process.env.REACT_APP_IMAGE_URL}${adminData?.profile}`
                              : ""
                          }
                          onError={(e) => {
                            e.target.src = PageIndex.Svg.avtarIcon;
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          className="admin-upload-profile-img"
                          src={PageIndex.Svg.avtarIcon}
                          alt=""
                        />
                      )}

                      <Index.Button
                        variant="contained"
                        component="label"
                        className="admin-file-upload-btn"
                      >
                        <img
                          src={PageIndex.Svg.pencilIcon}
                          className="admin-upload-icon"
                          alt="upload img"
                        ></img>
                        <input
                          type="file"
                          name="profile"
                          accept=".jpeg, .jpg, .png"
                          className="upload-image-input"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            try {
                              if (
                                e.currentTarget.files &&
                                e.currentTarget.files[0]
                              ) {
                                setFieldValue(
                                  "profile",
                                  e.currentTarget.files[0]
                                );
                                setImage(e.currentTarget.files[0]);
                              }
                            } catch (error) {
                              e.currentTarget.value = null;
                            }
                          }}
                          hidden
                          multiple
                        />
                      </Index.Button>
                    </Index.Box>
                    <Index.FormHelperText className="admin-error-text">
                      {touched?.profile && !values?.profile && errors?.profile}
                    </Index.FormHelperText>
                    {/* <Index.Box className="upload-image-box">
                      <Index.Button
                        component="label"
                        variant="contained"
                        className="upload-image-btn"
                      >
                        <Index.EditIcon className="upload-image-icon" />
                        {!errors?.profile && (image || adminData?.profile) ? (
                          <img
                            src={
                              image
                                ? URL.createObjectURL(image)
                                : adminData?.profile
                                ? `${process.env.REACT_APP_IMAGE_URL}${adminData?.profile}`
                                : ""
                            }
                            onError={(e) => {
                              e.target.src = PageIndex.Png.profile;
                            }}
                            alt=""
                          />
                        ) : (
                          <img src={PageIndex.Png.profile} alt="" />
                        )}

                        <VisuallyHiddenInput
                          type="file"
                          name="profile"
                          // accept="image/*"
                          accept=".jpeg, .jpg, .png"
                          className="upload-image-input"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            try {
                              if (
                                e.currentTarget.files &&
                                e.currentTarget.files[0]
                              ) {
                                setFieldValue(
                                  "profile",
                                  e.currentTarget.files[0]
                                );
                                setImage(e.currentTarget.files[0]);
                              }
                            } catch (error) {
                              e.currentTarget.value = null;
                            }
                          }}
                        />
                      </Index.Button>
                      <Index.FormHelperText className="admin-error-text">
                        {touched?.profile && errors?.profile}
                      </Index.FormHelperText>
                    </Index.Box> */}
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="edit-profile-input-main admin-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      First Name
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="First name"
                        className="admin-form-control"
                        name="firstName"
                        value={values?.firstName}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            /^[a-zA-Z\s]*$/.test(inputValue) &&
                            inputValue?.toString().length <= 30
                          ) {
                            setFieldValue(
                              "firstName",
                              inputValue
                                .replace(/^\s+/, "")
                                .replace(/\s+/g, " ")
                            );
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      <Index.FormHelperText className="admn-error-text">
                        {touched.firstName && errors?.firstName}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="edit-profile-input-main admin-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Last Name
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Last name"
                        className="admin-form-control"
                        name="lastName"
                        value={values?.lastName}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            /^[a-zA-Z\s]*$/.test(inputValue) &&
                            inputValue?.toString().length <= 30
                          ) {
                            setFieldValue(
                              "lastName",
                              inputValue
                                .replace(/^\s+/, "")
                                .replace(/\s+/g, " ")
                            );
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      <Index.FormHelperText className="admin-error-text">
                        {touched.lastName && errors?.lastName}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="edit-profile-input-main admin-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Date of birth
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Date of birth"
                        className="admin-form-control"
                        name="dob"
                        type="date"
                        value={values.dob}
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue("dob", e.target.value)}
                        max={new Date().toISOString().split("T")[0]}
                      />
                      <Index.FormHelperText className="admin-error-text">
                        {touched?.dob && errors?.dob}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="edit-profile-input-main admin-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Gender
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Box className="admin-radio-main">
                        <Index.RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="male"
                          name="gender"
                          className="radio"
                          value={values.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <Index.FormControlLabel
                            className="radio-label"
                            value="male"
                            control={<Index.Radio />}
                            label="Male"
                          />
                          <Index.FormControlLabel
                            value="female"
                            className="radio-label"
                            control={<Index.Radio />}
                            label="Female"
                          />
                          <Index.FormControlLabel
                            value="other"
                            control={<Index.Radio />}
                            label="Other"
                            className="radio-label"
                          />
                        </Index.RadioGroup>
                      </Index.Box>
                      <Index.FormHelperText className="admin-error-text">
                        {touched?.gender && errors?.gender}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-btn-flex-end primary-btn-main">
            <Index.Button
              variant="contained"
              type="submit"
              className="primary-btn"
            >
              {loading ? <ButtonLoaderForProfile /> : "Update"}
            </Index.Button>
          </Index.Box>
        </form>
      </Index.Box>
      </Index.Box>
    </>
  );
};

export default EditProfile;
