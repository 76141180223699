import React from "react";
import { ButtonLoader } from "../button/ButtonLoader";
import PageIndex from "../../../container/PageIndex";
import Index from "../../../container/Index";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

const DeleteModal = ({ open, setOpen, handleDelete, loading }) => {
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <Index.Modal
            className="admin-modal"
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Index.Box sx={style} className="admin-modal-inner admin-delete-modal-inner-main">
                <Index.Box className="admin-modal-hgt-scroll">
                    <img src={PageIndex.Gif.trashGif} alt="trash" className="admin-user-circle-img" />
                    <Index.Typography
                        className="admin-delete-modal-title"
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Are You Sure ?
                    </Index.Typography>
                    <Index.Typography
                        className="admin-delete-modal-para"
                        id="modal-modal-description"
                        sx={{ mt: 2 }}
                    >
                        Do you really wants to delete this record ? This process can not be
                        undone.
                    </Index.Typography>
                </Index.Box>
                <Index.Box className="admin-delete-modal-btn-flex">
                    <Index.Button className="admin-modal-delete-btn" onClick={() => setOpen(false)}>Cancel</Index.Button>
                    {loading ?
                        <Index.Button className="admin-modal-delete-btn"><ButtonLoader /></Index.Button> :
                        <Index.Button className="admin-modal-delete-btn" onClick={handleDelete}>Delete</Index.Button>
                    }
                </Index.Box>
            </Index.Box>
        </Index.Modal>
    )
}

export default DeleteModal