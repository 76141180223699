import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  doGet,
  doPost,
  doDelete,
  doGetSingle,
  doGetQueryData,
} from "../../../config/DataService";
import Api from "../../../config/Api";

export const adminLogin = createAsyncThunk("admin/adminLogin", async (data) => {
  try {
    const response = await doPost(Api.ADMIN_LOGIN, data);
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
});

export const addEditCountry = createAsyncThunk(
  "admin/addEditCountry",
  async (data) => {
    try {
      const response = await doPost(Api.ADD_EDIT_COUNTRY, data);
      if (response?.status === 201 || response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllCountry = createAsyncThunk(
  "admin/getAllCountry",
  async ({ page = 1, rowsPerPage = 10, searchTerm = "" } = {}) => {
    try {
      const response = await doGet(
        Api.GET_ALL_COUNTRY +
        `?page=${page}&limit=${rowsPerPage}&search=${searchTerm}`
      );
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

// export const getAllCountry = createAsyncThunk(
//   "admin/getAllCountry",
//   async () => {
//     try {
//       const response = await doGet(Api.GET_ALL_COUNTRY);
//       if (response?.status === 200) {
//         return response;
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

export const activeDeactiveCountry = createAsyncThunk(
  "admin/activeDeactiveCountry",
  async ({ id, isActive }) => {
    try {
      const response = await doPost(Api.ACTIVE_DEACTIVE_COUNTRY, {
        id,
        isActive,
      });
      if (response?.data?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteCountry = createAsyncThunk(
  "admin/deleteCountry",
  async (id) => {
    try {
      const response = await doPost(Api.DELETE_COUNTRY, { id });
      if (response?.data?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const addEditState = createAsyncThunk(
  "admin/addEditState",
  async (data) => {
    try {
      const response = await doPost(Api.ADD_EDIT_STATE, data);
      if (response?.status === 201 || response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllState = createAsyncThunk(
  "admin/getAllState",
  async ({ page, rowsPerPage, searchTerm }) => {
    try {
      const response = await doGet(
        Api.GET_ALL_STATE +
        `?page=${page}&limit=${rowsPerPage}&search=${searchTerm}`
      );
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const activeDeactiveState = createAsyncThunk(
  "admin/activeDeactiveState",
  async ({ id, isActive }) => {
    try {
      const response = await doPost(Api.ACTIVE_DEACTIVE_STATE, {
        id,
        isActive,
      });
      if (response?.data?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteState = createAsyncThunk("admin/deleteState", async (id) => {
  try {
    const response = await doPost(Api.DELETE_STATE, { id });
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
});

export const getAllRole = async () => {
  try {
    const response = await doGet(Api.GET_ALL_ROLE);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getStateListByCountry = createAsyncThunk(
  "admin/getStateListByCountry",
  async (countryId) => {
    try {
      const response = await doGet(Api.GET_STATE_LIST + `/${countryId}`);
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const addEditCity = createAsyncThunk(
  "admin/addEditCity",
  async (data) => {
    try {
      const response = await doPost(Api.ADD_EDIT_CITY, data);
      if (response?.status === 201 || response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllCity = createAsyncThunk(
  "admin/getAllCity",
  async ({ page, rowsPerPage, searchTerm }) => {
    try {
      const response = await doGet(
        Api.GET_ALL_CITY +
        `?page=${page}&limit=${rowsPerPage}&search=${searchTerm}`
      );
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCityListByState = createAsyncThunk(
  "admin/getCityListByState",
  async (stateId) => {
    try {
      const response = await doGet(Api.GET_CITY_LIST + `/${stateId}`);
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const activeDeactiveCity = createAsyncThunk(
  "admin/activeDeactiveCity",
  async ({ id, isActive }) => {
    try {
      const response = await doPost(Api.ACTIVE_DEACTIVE_CITY, {
        id,
        isActive,
      });
      if (response?.data?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteCity = createAsyncThunk("admin/deleteCity", async (id) => {
  try {
    const response = await doPost(Api.DELETE_CITY, { id });
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
});

export const addEditRole = async (data) => {
  try {
    const response = await doPost(Api.ADD_EDIT_ROLE, data);
    if (response?.status === 200 || 201) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

// export const updateRole = async (data) => {
//   try {
//     const response = await doPost(Api.EDIT_ROLE, data);
//     if (response?.status === 200) {
//       return response;
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

export const deleteRole = async (data) => {
  try {
    const response = await doPost(Api.DELETE_ROLE, data);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const activeDeactiveRole = async (data) => {
  try {
    const response = await doPost(Api.ACTIVE_DEACTIVE_ROLE, data);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllUser = async () => {
  try {
    const response = await doGet(Api.GET_ALL_USER);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addEditUser = async (data) => {
  try {
    const response = await doPost(Api.ADD_EDIT_USER, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const deleteUser = async (adminId) => {
  try {
    const response = await doDelete(Api.DELETE_USER, adminId);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const activeDeActiveUser = async (data) => {
  try {
    const response = await doPost(Api.ACTIVE_DEACTIVE_USER, data);
    console.log(response, 110);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const addEditUnit = createAsyncThunk(
  "admin/addEditUnit",
  async (data) => {
    try {
      const response = await doPost(Api.ADD_EDIT_UNIT, data);
      if (response?.status === 201 || response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllUnit = createAsyncThunk(
  "admin/getAllUnit",
  async ({ page, rowsPerPage, searchTerm }) => {
    try {
      const response = await doGet(
        Api.GET_ALL_UNIT +
        `?page=${page}&limit=${rowsPerPage}&search=${searchTerm}`
      );
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const activeDeactiveUnit = createAsyncThunk(
  "admin/activeDeactiveUnit",
  async ({ id, isActive }) => {
    try {
      const response = await doPost(Api.ACTIVE_DEACTIVE_UNIT, {
        id,
        isActive,
      });
      if (response?.data?.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteUnit = createAsyncThunk("admin/deleteUnit", async (id) => {
  try {
    const response = await doPost(Api.DELETE_UNIT, { id });
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
});
// #region Brand master services
export const getAllBrandMasters = async () => {
  try {
    const response = await doGet(Api.GET_ALL_BRANDS);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const activeDeActiveBrand = async (data) => {
  try {
    const response = await doPost(Api.ACTIVE_DEACTIVE_BRAND, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const addEditBrand = async (data) => {
  try {
    const response = await doPost(Api.ADD_EDIT_BRAND, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const deleteBrand = async (brandId) => {
  try {
    const response = await doDelete(Api.DELETE_BRAND, brandId);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
// #region End brand master service

export const getAllCategory = async () => {
  try {
    const response = await doGet(Api.GET_ALL_CATEGORY);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSingleCategory = async () => {
  try {
    const response = await doGet(Api.GET_SINGLE_CATEGORY);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addEditCategory = async (data) => {
  try {
    const response = await doPost(Api.ADD_EDIT_CATEGORY, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCategory = async (adminId) => {
  try {
    const response = await doPost(Api.DELETE_CATEGORY, adminId);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const activeDeActiveCategory = async (data) => {
  try {
    const response = await doPost(Api.ACTIVE_DEACTIVE_CATEGORY, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// #region product master services
export const getAllBaseUnit = async () => {
  try {
    const response = await doGet(Api.GET_ALL_UNIT);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addEditProduct = async (data) => {
  try {
    const response = await doPost(Api.ADD_EDIT_PRODUCT, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllProduct = async ({ page, limit, search }) => {
  try {
    const response = await doGet(
      `${Api.GET_ALL_PRODUCT}?page=${page}&limit=${limit}&search=${search}`
    );
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const activeDeActiveProduct = async (data) => {
  try {
    const response = await doPost(Api.ACTIVE_DEACTIVE_PRODUCT, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProduct = async (productId) => {
  try {
    const response = await doDelete(Api.DELETE_PRODUCT, productId);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

// #region end product master services

// #region vendor master services
export const addEditVendor = async (data) => {
  try {
    const response = await doPost(Api.ADD_EDIT_VENDOR, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllVendor = async ({ page, limit, search }) => {
  try {
    const response = await doGet(
      `${Api.GET_ALL_VENDOR}?page=${page}&limit=${limit}&search=${search}`
    );
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const activeDeActiveVendor = async (data) => {
  try {
    const response = await doPost(Api.ACTIVE_DEACTIVE_VENDOR, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const deleteVendor = async (vendorId) => {
  try {
    const response = await doDelete(Api.DELETE_VENDOR, vendorId);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
// #region end vendor master services

export const getSingleUser = async (id) => {
  try {
    const response = await doGet(`${Api.GET_SINGLE_USER}?adminId=${id}`);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const editProfile = async (data) => {
  try {
    const response = await doPost(Api.EDIT_PROFILE, data);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = async (data) => {
  try {
    const response = await doPost(Api.CHANGE_PASSWORD, data);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

// #Indent request
export const addIndentRequestAction = async (data) => {
  try {
    const response = await doPost(Api.ADD_INDENT_REQUEST, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateIndentRequestAction = async (data) => {
  try {
    const response = await doPost(Api.UPDATE_INDENT_REQUEST, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};
// get all product  without pagination

export const getAllProductWithoutPagination = async () => {
  try {
    const response = await doGet(Api.GET_ALL_PRODUCT_WITHOUT_PAGINATION);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllVendorWithoutPagination = async () => {
  try {
    const response = await doGet(Api.GET_ALL_VENDOR_WITHOUT_PAGINATION);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
// get indent request

export const getIndentRequestAction = async (data) => {
  try {
    const response = await doGet(
      Api.GET_INDENT_REQUEST +
      `?page=${data.page}&limit=${data.rowsPerPage}&search=${data.search}&type=${data.type}`
    );
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

// #Indent Approv reject
export const indentApproveRejectAction = async (data) => {
  try {
    const response = await doPost(Api.INDENT_APPROV_REJECT, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

//#region Activity logs apis
export const getAllActivityLogs = async () => {
  try {
    const response = await doGet(Api.GET_ACTIVITY_LOG_WITHOUT_PAGINATION);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

//#region Activity logs By module apis
export const getAllActivityLogsByModule = async (moduleId) => {
  try {
    const response = await doGet(`${Api.GET_ACTIVITY_LOG_BY_MODULE_WITHOUT_PAGINATION}/${moduleId}`);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

//#region Add Inward
export const addInward = async (data) => {
  try {
    const response = await doPost(Api.ADD_INWARD, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};


//#region Get Inward by indent id
export const getSingleInwardFromIndentId = async (IndentId) => {
  try {
    const response = await doGet(`${Api.GET_INWARD_BY_INDENT_ID}/${IndentId}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

//#region Get PO list
export const getAllPlamingoOrthopedicList = async ({ page, rowsPerPage, search }) => {
  try {
    const response = await doGet(
      `${Api.GET_PLAMINGO_LIST}?page=${page}&limit=${rowsPerPage}&search=${search}`
    );
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

//#region Get Stock list
export const getAllStockList = async ({ page, rowsPerPage, search }) => {
  try {
    const response = await doGet(
      `${Api.GET_STOCK_LIST}?page=${page}&limit=${rowsPerPage}&search=${search}`
    );
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

//#region Get Stock by product wise list
export const getAllStockListByProductWise = async ({ page, rowsPerPage, search }) => {
  try {
    const response = await doGet(
      `${Api.GET_STOCK_LIST_PRODUCT_WISE}?page=${page}&limit=${rowsPerPage}&search=${search}`
    );
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllStockListByProductId = async ({ productId,page, rowsPerPage, search }) => {
  try {
    const response = await doGet(
      `${Api.GET_ALL_STOCK_LIST_PRODUCT_ID}/${productId}?page=${page}&limit=${rowsPerPage}&search=${search}`
    );
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};


export const addBill = async (data) => {
  try {
    const response = await doPost(Api.ADD_BILL, data);
    if (response?.status === 200 || response?.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllBills = async (search) => {
  try {
    const response = await doGet(`${Api.GET_ALL_BILL}?search=${search}`);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateBillStatus = async (data) => {
  try {
    const response = await doPost(Api.UPDATE_BILL_STATUS, data);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};



export const printBill = async (id) => {
  console.log(id,1111111111);
  
  try {
    const response = await doPost(Api.PRINT_PDF,id);
    if (response?.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};