
import "./assets/style/global.css";
import "./assets/style/responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/Routes";
import { lazy, Suspense } from "react";
import Loading from "./component/common/Loading";

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <div className="App">
        <ToastContainer />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;
