import "jspdf-autotable";
import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import Paper from "@mui/material/Paper";
import PageIndex from "../../../PageIndex";
import { PageLoader } from "../../../../component/common/button/ButtonLoader";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import Completed icon
import CancelIcon from "@mui/icons-material/Cancel";
import PrintButton from "./PrintButton";
import PrintButtonInward from "../indentRequest/PrintButtonInward";


const BillsList = () => {
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [removeId, setRemoveId] = useState("");
  const [status, setStatus] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCancel, setCancel] = useState(false);
  const [openCompleted, setCompleted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [productData, setProductData] = useState([]);

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.UserManagement;


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (e) => {
    const search = e.target.value
      .replace(/^\s+/, "")
      .replace(/\s\s+/g, " ")
      .slice(0, 30);
    setSearchTerm(search);
  };

  const getBillsList = async (searchTerm) => {
    try {
      setLoading(true);
      const res = await PageIndex.getAllBills(searchTerm);

      if (res?.status === 200) {
        setFilterData(res?.data);

        // setUserList(res?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
    setLoading(false);
  };

  const modalOpen = (data) => {
    setIsOpen(!isOpen);
    setProductData(data);
  };

  useEffect(() => {
    getBillsList(searchTerm);
  }, [searchTerm]);

  const handleStatus = async () => {
    const data = new URLSearchParams();
    data.append("id", removeId);
    data.append("status", status);

    const res = await PageIndex.updateBillStatus(data);
    if (res?.status == 200) {
      setCancel(false);
      setCompleted(false);
      setStatus("");
      setRemoveId("");
      getBillsList();
    }
  };

  const handleEdit = (item) => {
    navigate("/admin/bill/add-bill", {
      state: { item: item },
    });
  };


  if (permissions?.View) {
    return (
      <>
        <Index.Box className="admin-dashboard-content admin-user-containt">
          <Index.Box className="admin-user-list-flex">
            <Index.Box className="admin-breadcrumb-main">
              <img
                src={PageIndex.Svg.homeIcon}
                className="admin-breadcrumb-img"
                alt="breadcrumb"
              />
              <Index.Button
                className="admin-breadcrumb-text"
                onClick={() => {
                  navigate("/admin/dashboard");
                }}
              >
                Dashboard
              </Index.Button>
              <Index.Typography className="admin-breadcrumb-text">
                /
              </Index.Typography>
              <Index.Button className="admin-breadcrumb-text admin-breadcrumb-active">
                {" "}
                Bill Management
              </Index.Button>
            </Index.Box>
            <Index.Box className="admin-search-right-main">
              <Index.Box className="search-input-box admin-input-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    size="small"
                    placeholder="Search"
                    className="admin-form-control"
                    onChange={handleSearchChange}
                    value={searchTerm}
                  />
                  <img
                    src={PageIndex.Svg.searchIcon}
                    className="search-grey-img"
                    alt="search"
                  />
                </Index.Box>
              </Index.Box>
              {permissions?.Add && (
                <Index.Box className="primary-btn-main add-btn-main">
                  <Index.CommonButton
                    btnLabel="Add Bill"
                    startIcon={
                      <Index.AddCircleOutlinedIcon className="add-btn-icon" />
                    }
                    className={"primary-btn add-btn"}
                    onClick={() => navigate("/admin/bill/add-bill")}
                  />
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box className="common-card">
            <Index.Box className="grid-main">
              <Index.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="page-table-main bill-list-table-main">
                    <Paper
                      // sx={{ width: "100%", overflow: "hidden" }}
                      className="table-paper"
                    >
                      <Index.TableContainer
                        // sx={{ maxHeight: 480 }}
                        className="table-container"
                      >
                        <Index.Table
                          className="table"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                width="3%"
                                className="table-th"
                                variant="th"
                              >
                                Sr.No
                              </Index.TableCell>
                              <Index.TableCell
                                width="10%"
                                className="table-th"
                                variant="th"
                              >
                                Customer Name
                              </Index.TableCell>
                              <Index.TableCell
                                width="10%"
                                className="table-th"
                                variant="th"
                              >
                                Mobile Number
                              </Index.TableCell>

                              <Index.TableCell
                                width="20%"
                                className="table-th"
                                variant="th"
                              >
                                Product Name
                              </Index.TableCell>
                              {/* <Index.TableCell
                        width="10%"
                        className="table-th"
                        variant="th"
                      >
                        QTY
                      </Index.TableCell>
                      <Index.TableCell
                        width="10%"
                        className="table-th"
                        variant="th"
                      >
                        Discount (%)
                      </Index.TableCell> */}
                              <Index.TableCell
                                width="10%"
                                className="table-th"
                                variant="th"
                              >
                                Discount Amount
                              </Index.TableCell>
                              {/* <Index.TableCell
                        width="10%"
                        CGST
                      </Index.TableCell> */}
                              <Index.TableCell
                                width="10%"
                                className="table-th"
                                variant="th"
                              >
                                Price
                              </Index.TableCell>
                              {permissions?.Edit && (
                                <Index.TableCell
                                  width="10%"
                                  className="table-th"
                                  variant="th"
                                >
                                  Status
                                </Index.TableCell>
                              )}
                              {(permissions?.Edit || permissions?.Delete) && (
                                <Index.TableCell
                                  width="10%"
                                  className="table-th"
                                  variant="th"
                                  align="center"
                                >
                                  Action
                                </Index.TableCell>
                              )}
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData?.length > 0 ? (
                              filterData
                                ?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                ?.map((row, index) => (
                                  <Index.TableRow key={row._id} className="table-row">
                                    <Index.TableCell className="table-td" variant="td">
                                      <Index.Typography className="admin-table-data-text">
                                        {page * rowsPerPage + index + 1}
                                      </Index.Typography>
                                    </Index.TableCell>
                                    <Index.TableCell className="table-td" variant="td">
                                      <Index.Typography className="admin-table-data-text">
                                        {" "}
                                        {row?.customerName || "-"}
                                      </Index.Typography>
                                    </Index.TableCell>
                                    <Index.TableCell className="table-td" variant="td">
                                      <Index.Typography className="admin-table-data-text">
                                        {" "}
                                        {row?.mobileNumber || "-"}
                                      </Index.Typography>
                                    </Index.TableCell>

                                    <Index.TableCell className="table-td" variant="td">
                                      {row?.products?.length > 1 ? (
                                        <Index.Box
                                          className="product-qty-box"
                                          onClick={() => modalOpen(row?.products)}
                                        >
                                          <Index.Typography className="admin-table-data-text">
                                            {row?.products[0]?.productId?.productName}{" "}
                                          </Index.Typography>
                                          <span className="qty-title">
                                            {`(${PageIndex.numeralValueConverter(
                                              row?.products[0]?.productQuantity || 1
                                            )})`}
                                          </span>
                                          <span className="qty-title">
                                            +{row?.products?.length - 1}
                                          </span>
                                        </Index.Box>
                                      ) : (
                                        <Index.Box className="product-qty-box">
                                          <Index.Typography className="admin-table-data-text ">
                                            {row?.products[0]?.productId?.productName}{" "}
                                            {/* {/ Access productName from productId /} */}
                                          </Index.Typography>
                                          <span className="qty-title">
                                            {`(${PageIndex.numeralValueConverter(
                                              row?.products[0]?.productQuantity || 1 // Default quantity to 1 if not provided
                                            )})`}
                                          </span>
                                        </Index.Box>
                                      )}
                                    </Index.TableCell>
                                    {/* <Index.TableCell className="table-td" variant="td">
                              <Index.Typography className="admin-table-data-text">
                                {" "}
                                {row?.products[0]?.productQuantity || "-"}
                              </Index.Typography>
                            </Index.TableCell>
                            <Index.TableCell className="table-td" variant="td">
                              <Index.Typography className="admin-table-data-text">
                                {" "}
                                {row?.discount || "-"}
                              </Index.Typography>
                            </Index.TableCell> */}
                                    <Index.TableCell className="table-td" variant="td">
                                      <Index.Typography className="admin-table-data-text">
                                        {" "}
                                        {row?.discountPrice || "-"}
                                      </Index.Typography>
                                    </Index.TableCell>

                                    {/* <Index.TableCell className="table-td" variant="td">
                              <Index.Typography className="admin-table-data-text">
                                {" "}
                                {row?.sGst || "-"}
                              </Index.Typography>
                            </Index.TableCell>
                            <Index.TableCell className="table-td" variant="td">
                              <Index.Typography className="admin-table-data-text">
                                {row?.cGst || "-"}
                              </Index.Typography>
                            </Index.TableCell> */}
                                    <Index.TableCell className="table-td" variant="td">
                                      <Index.Typography className="admin-table-data-text">
                                        {row?.totalPrice?.toFixed(2) || "-"}
                                      </Index.Typography>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      className="table-td"
                                      variant="td"
                                      style={{
                                        color:
                                          row?.status === "Completed"
                                            ? "green"
                                            : row?.status === "Cancel"
                                              ? "red"
                                              : "black",
                                      }}
                                    >
                                      {row?.status || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      className=" table-td"
                                      variant="td"
                                    >
                                      <Index.Box className="admin-table-data-btn-flex">
                                        <>
                                          {permissions?.Edit && row.status !== "Cancel" && (
                                            <>
                                              <Index.Tooltip
                                                title="Status"
                                                arrow
                                                placement="bottom"
                                                className="admin-tooltip"
                                              >
                                                <Button
                                                  className="admin-table-data-btn admin-table-status-btn"
                                                  onClick={() => {
                                                    setRemoveId(row._id);
                                                    setStatus("Completed");
                                                    setCompleted(true);
                                                  }}
                                                >
                                                  <img
                                                    src={PageIndex.Svg.statusIcon}
                                                    alt="View Icon"
                                                    className="table-icon"
                                                  />
                                                </Button>
                                              </Index.Tooltip>

                                              <Index.Tooltip
                                                title="Cancell"
                                                arrow
                                                placement="bottom"
                                                className="admin-tooltip"
                                              >
                                                <Button
                                                  className="admin-table-data-btn admin-table-cancell-btn"
                                                  onClick={() => {
                                                    setRemoveId(row._id);
                                                    setStatus("Cancel");
                                                    setCancel(true);
                                                  }}
                                                >
                                                  <img
                                                    src={PageIndex.Svg.cancellIcon}
                                                    alt="View Icon"
                                                    className="table-icon"
                                                  />
                                                </Button>
                                              </Index.Tooltip>

                                              <Index.Tooltip
                                                title="Edit"
                                                arrow
                                                placement="bottom"
                                                className="admin-tooltip"
                                              >
                                                <Index.Button
                                                  className="admin-table-data-btn admin-table-edit-btn"
                                                  onClick={() => {
                                                    handleEdit(row);
                                                  }}
                                                >
                                                  <img
                                                    src={PageIndex.Svg.Edit}
                                                    alt="Edit Icon"
                                                    className="table-icon"
                                                  />
                                                </Index.Button>
                                              </Index.Tooltip>
                                            </>
                                          )}


                                          {permissions?.View && (
                                            <>
                                              <Index.Tooltip
                                                title="View"
                                                arrow
                                                placement="bottom"
                                                className="admin-tooltip"
                                              >
                                                <Button
                                                  className="status-btn admin-table-data-btn admin-table-view-btn"
                                                  onClick={() => {
                                                    navigate("/admin/bill/view", {
                                                      state: {
                                                        viewData: row,
                                                        _id: row._id,
                                                      },
                                                    });
                                                  }}
                                                >
                                                  <img
                                                    src={PageIndex.Png.view}
                                                    alt="View Icon"
                                                    className="table-icon"
                                                  />
                                                </Button>
                                              </Index.Tooltip>
                                              <Index.Tooltip
                                                title="Print"
                                                arrow
                                                placement="bottom"
                                                className="admin-tooltip"
                                              >
                                                <Index.Button
                                                  className="admin-table-data-btn admin-table-print-btn">
                                                  <PrintButton billData={{ row, index, id: row._id }} />
                                                </Index.Button>
                                              </Index.Tooltip>

                                            </>

                                          )}
                                        </>
                                      </Index.Box>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  colSpan={11}
                                  align="center"
                                  className="data-not-found-main"
                                >
                                  <p className="data-not-found-text">
                                    {loading ? <PageLoader /> : "No data found..."}
                                  </p>
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Paper>
                    {filterData?.length > 10 && (
                      <Index.Box className="tale-pagination">
                        <Index.TablePagination
                          component="div"
                          rowsPerPageOptions={[10, 20, 50]}
                          count={filterData?.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Index.Box>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <PageIndex.CompletedModal
          open={openCompleted}
          setOpen={setCompleted}
          handleStatus={handleStatus}
        />
        <PageIndex.CancelModal
          open={openCancel}
          setOpen={setCancel}
          handleStatus={handleStatus}
        />
        <PageIndex.BillModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          productData={productData}
          setProductData={setProductData}
        />
      </>
    );
  } else {
    navigate("/admin/dashboard");
  }
};

export default BillsList;
