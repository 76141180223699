const Api = {
  ADMIN_LOGIN: "/admin/signin",
  ADMIN_FORGOT_PASSWORD: "/admin/forget-password",
  ADMIN_OTP_VERIFY: "/admin/verify-otp",
  ADMIN_RESEND_OTP: "/admin/resend-otp",
  ADMIN_RESET_PASSWORD: "/admin/reset-password",
  ADD_EDIT_COUNTRY: "/admin/add-edit-country",
  GET_ALL_COUNTRY: "/admin/get-country-list",
  DELETE_COUNTRY: "/admin/delete-country",
  ACTIVE_DEACTIVE_COUNTRY: "/admin/active-deactive-country",
  ADD_EDIT_STATE: "/admin/add-edit-state",
  GET_ALL_STATE: "/admin/get-all-state",
  GET_STATE_LIST: "admin/get-state-list",
  DELETE_STATE: "/admin/delete-state",
  ACTIVE_DEACTIVE_STATE: "/admin/active-deactive-state",
  ADD_EDIT_CITY: "/admin/add-edit-city",
  GET_ALL_CITY: "/admin/get-all-city",
  GET_CITY_LIST: "/admin/get-city-list",
  DELETE_CITY: "/admin/delete-city",
  ACTIVE_DEACTIVE_CITY: "/admin/active-deactive-city",
  GET_ALL_ROLE: "/admin/get-all-role",
  DELETE_ROLE: "/admin/remove-role",
  ADD_EDIT_ROLE: "/admin/add-role-edit",
  ACTIVE_DEACTIVE_ROLE: "/admin/active-deactive",
  GET_ALL_USER: "/admin/get-all-user",
  GET_ALL_USER: "/admin/get-all-user",
  ADD_EDIT_USER: "/admin/add-edit-user",
  DELETE_USER: "/admin/delete-user",
  ACTIVE_DEACTIVE_USER: "/admin/user-active-deactive",
  ADD_EDIT_UNIT: "/admin/add-edit-unit",
  GET_ALL_UNIT: "/admin/get-unit-list",
  DELETE_UNIT: "/admin/delete-unit",
  ACTIVE_DEACTIVE_UNIT: "/admin/active-deactive-unit",
  GET_ALL_BRANDS: "/admin/get-all-brands",
  ADD_EDIT_BRAND: "/admin/add-edit-brand",
  DELETE_BRAND: "/admin/delete-brand",
  ACTIVE_DEACTIVE_BRAND: "/admin/brand-active-deactive",
  GET_ALL_CATEGORY: "/admin/get-all-category",
  GET_SINGLE_CATEGORY: "/admin/get-single-category",
  ADD_EDIT_CATEGORY: "/admin/add-edit-category",
  DELETE_CATEGORY: "/admin/delete-category",
  ACTIVE_DEACTIVE_CATEGORY: "/admin/active-deactive-category",
  ADD_EDIT_PRODUCT: "/admin/add-edit-product",
  GET_ALL_PRODUCT: "/admin/get-all-product",
  GET_SINGLE_PRODUCT: "/admin/get-single-product",
  DELETE_PRODUCT: "/admin/delete-product",
  ACTIVE_DEACTIVE_PRODUCT: "/admin/active-deactive-product",
  ADD_EDIT_VENDOR: "/admin/add-edit-vendor",
  DELETE_VENDOR: "admin/delete-vendor",
  GET_ALL_VENDOR: "admin/get-all-vendor",
  GET_INDENT_REQUEST: "admin/get-all-indent-request",
  ACTIVE_DEACTIVE_VENDOR: "/admin/active-deactive-vendor",
  GET_SINGLE_USER: "/admin/get-single-user",
  ADD_INDENT_REQUEST: "/admin/add-indent-request",
  UPDATE_INDENT_REQUEST: "/admin/update-indent-request",
  GET_ALL_PRODUCT_WITHOUT_PAGINATION:
    "/admin/get-all-product-without-pagination",
  GET_ALL_VENDOR_WITHOUT_PAGINATION:
    "/admin/get-all-vendor-with-out-pagination",
  EDIT_PROFILE: "/admin/edit-profile",
  CHANGE_PASSWORD: "/admin/change-password",
 INDENT_APPROV_REJECT:"/admin/approve-reject-indent-request",
 ADD_BILL : "/admin/add-edit-bill",
 GET_ALL_BILL : "/admin/get-all-bill",
 UPDATE_BILL_STATUS : "/admin/update-bill-status",

  INDENT_APPROV_REJECT: "/admin/approve-reject-indent-request",
  GET_ACTIVITY_LOG: "/admin/get-all-activity-log",
  GET_ACTIVITY_LOG_WITHOUT_PAGINATION: "/admin/get-all-activity-log-without-pagination",
  GET_ACTIVITY_LOG_BY_MODULE_WITHOUT_PAGINATION: "/admin/get-activity-log-by-module-without-pagination",
  ADD_INWARD: "/admin/add-indent-inward",
  GET_INWARD_BY_INDENT_ID: "/admin/get-inward-by-indent-id",
  GET_PLAMINGO_LIST: "/admin/get-plamingo-orthopedic-list",
  GET_STOCK_LIST: "/admin/get-all-stock-list",
  PRINT_PDF : "/admin/print-bill",
  GET_STOCK_LIST_PRODUCT_WISE: "/admin/get-all-stock-list-by-product",
  GET_ALL_STOCK_LIST_PRODUCT_ID: "/admin/get-all-stock-by-product-id",
};

export default Api;
