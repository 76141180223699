import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Paper from "@mui/material/Paper";
import PageIndex from "../../../PageIndex";
import { PageLoader } from "../../../../component/common/button/ButtonLoader";
import AddIcon from "@mui/icons-material/Add";
import AddEditCategory from "./AddEditCategory";
const CategoryMaster = () => {
  const navigate = Index.useNavigate();
  const [categoryData, setCategoryData] = useState([]);
  const [open, setOpen] = useState(false);
  const [removeId, setRemoveId] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editData, setEditData] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.CategoryManagement;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (value) => {
    const search = value.trim();
    const res = categoryData?.filter((item) => {
      return (
        item.category &&
        item.category?.toLowerCase()?.includes(search?.toLowerCase())
      );
    });
    setFilterData(res);
    setPage(0);
  };

  const handleStatus = async (id) => {
    const data = new URLSearchParams();
    data.append("categoryId", id);
    const res = await PageIndex.activeDeActiveCategory(data);
    if (res?.status == 200) {
      getAllCategoryData();
    }
  };

  const handleDelete = async () => {
    setBtnLoading(true);
    try {
      const res = await PageIndex.deleteCategory({ categoryId: removeId });
      if (res?.status === 200) {
        getAllCategoryData();
        setOpen(false);
        setRemoveId("");
      }
    } catch (error) {
      console.log(error, "error");
    }
    setBtnLoading(false);
  };

  const getAllCategoryData = async () => {
    setLoading(true);
    try {
      const res = await PageIndex.getAllCategory();

      if (res?.status === 200) {
        setFilterData(res?.data);
        setCategoryData(res?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllCategoryData();
  }, []);

  if (permissions?.View) {
    return (
      <>
        <Index.Box className="admin-dashboard-content admin-category-containt">
          <Index.Box className="admin-user-list-flex">
            <Index.Box className="admin-breadcrumb-main">
              <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
              <Index.Button className='admin-breadcrumb-text' onClick={() => {
                navigate("/admin/dashboard");
              }}>Dashboard</Index.Button>
              <Index.Typography className='admin-breadcrumb-text'>/</Index.Typography>
              <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'>Category Master</Index.Button>
            </Index.Box>
            <Index.Box className="admin-search-right-main">
              <Index.Box className="search-input-box admin-input-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    size="small"
                    placeholder="Search"
                    className="admin-form-control"
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s+/g, " ")
                        .slice(0, 30);
                      setSearchTerm(value);
                      handleSearchChange(value);
                    }}
                    value={searchTerm}
                  />
                  <img src={PageIndex.Svg.searchIcon} className="search-grey-img" alt="search" />
                </Index.Box>
              </Index.Box>

              {permissions?.Add && (
                <Index.Box className="primary-btn-main add-btn-main">
                  <Index.CommonButton
                    btnLabel="Add Category"
                    startIcon={<Index.AddCircleOutlinedIcon className="add-btn-icon" />}
                    className={"primary-btn add-btn"}
                    onClick={() => setOpenDrawer(true)}
                  />
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>

          <Index.Box className="common-card">
            <Index.Box className="page-table-main category-table-main">
              <Paper
                // sx={{ width: "100%", overflow: "hidden" }}
                className="table-paper"
              >
                <Index.TableContainer
                  // sx={{ maxHeight: 480 }}
                  className="table-container"
                >
                  <Index.Table
                    className="table"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <Index.TableHead className="table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell className="table-th" variant="th" width="20%">
                          Sr. No.
                        </Index.TableCell>
                        <Index.TableCell className="table-th" variant="th" width="40%">
                          Category
                        </Index.TableCell>

                        {permissions?.Edit && (
                          <Index.TableCell className="table-th" variant="th" width="20%">
                            Status
                          </Index.TableCell>
                        )}

                        {(permissions?.Edit || permissions?.Delete) && (
                          <Index.TableCell className="table-th" variant="th" width="10%" align="right">
                            Action
                          </Index.TableCell>
                        )}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {filterData.length > 0 ? (
                        filterData
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((row, index) => (
                            <Index.TableRow key={row.index} className="table-row">
                              <Index.TableCell className="table-td" variant="td">
                                <Index.Typography className="admin-table-data-text">
                                  {page * rowsPerPage + index + 1}
                                </Index.Typography>
                              </Index.TableCell>
                              <Index.TableCell className="table-td" variant="td">
                                <Index.Typography className="admin-table-data-text">
                                  {row?.category}
                                  {/* Admin Master */}
                                </Index.Typography>
                              </Index.TableCell>
                              {permissions?.Edit && (
                                <Index.TableCell className="table-td" variant="td">
                                  <Index.Switch
                                    className="status-checkbox"
                                    size="small"
                                    checked={row?.isActive}
                                    onClick={() =>
                                      handleStatus(
                                        row?._id,
                                        row?.isActive ? false : true
                                      )
                                    }
                                    color="success"
                                  />
                                </Index.TableCell>
                              )}

                              {(permissions?.Edit ||
                                permissions?.Delete) && (
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <Index.Box className="admin-table-data-btn-flex">
                                      {permissions?.Edit && (
                                        <Index.Tooltip
                                          title="Edit"
                                          arrow
                                          placement="bottom"
                                          className="admin-tooltip"
                                        >
                                          <Index.Button
                                            className="admin-table-data-btn admin-table-edit-btn"
                                            onClick={() => {
                                              setOpenDrawer(true);
                                              setEditData(row);
                                            }}
                                          >
                                            <img
                                              src={PageIndex.Svg.Edit}
                                              alt="Edit Icon"
                                              className="table-icon"
                                            />
                                          </Index.Button>
                                        </Index.Tooltip>
                                      )}
                                      {permissions?.Delete && (
                                        <Index.Tooltip
                                          title="Delete"
                                          arrow
                                          placement="bottom"
                                          className="admin-tooltip"
                                        >
                                          <Index.Button
                                            className="admin-table-data-btn admin-table-edit-btn"
                                            onClick={() => {
                                              setOpen(true);
                                              setRemoveId(row?._id);
                                            }}
                                          >
                                            <img
                                              src={PageIndex.Svg.Delete}
                                              alt="Delete Icon"
                                              className="table-icon"
                                            />
                                          </Index.Button>
                                        </Index.Tooltip>
                                      )}
                                    </Index.Box>
                                  </Index.TableCell>
                                )}
                            </Index.TableRow>
                          ))
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            colSpan={10}
                            align="center"
                            className="data-not-found-main"
                          >
                            <p className="data-not-found-text">
                              {loading ? <PageLoader /> : "No data found..."}
                            </p>
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Paper>
              {filterData?.length > 10 && (
                <Index.Box className="tale-pagination">
                  <Index.TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 20, 50]}
                    count={filterData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <PageIndex.DeleteModal
          open={open}
          setOpen={setOpen}
          handleDelete={handleDelete}
          loading={btnLoading}
        />
        <Index.Drawer
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(!openDrawer);
            setEditData(null);
          }}
          anchor="right"
        >
          <Index.Box className="drawer">
            <AddEditCategory
              setOpenDrawer={setOpenDrawer}
              editData={editData}
              setEditData={setEditData}
              getAllCategoryData={getAllCategoryData}
            />
          </Index.Box>
        </Index.Drawer>
      </>
    );
  } else {
    navigate("/admin/dashboard");
  }
};

export default CategoryMaster;
