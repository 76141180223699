import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Paper from "@mui/material/Paper";
import PageIndex from "../../../PageIndex";
import Avatar from "@mui/material/Avatar";
import { activeDeActiveUser, getAllStockList, getAllStockListByProductWise } from "../../../../redux/features/admin/AdminServices";
import { PageLoader } from "../../../../component/common/button/ButtonLoader";
import moment from "moment";

const label = { inputProps: { "aria-label": "Switch demo" } };

const StockList = () => {
    const navigate = Index.useNavigate();
    const [loadingRowId, setLoadingRowId] = useState();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [removeId, setRemoveId] = useState("");
    const [stockList, setStockList] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalCount, setTotalCount] = useState(0);

    const { adminData } = Index.useSelector((state) => state.admin);
    const permissions = adminData?.role?.permissions?.StockManagement;

    const getAllStockByProduct = (searchTerm, pageNo, dataPerPage, tabNo) => {
        // console.log(searchTerm, pageNo, dataPerPage);

        setLoading(true);
        const data = {
            page: pageNo ?? page,
            rowsPerPage: dataPerPage ?? rowsPerPage,
            search: searchTerm ?? search,
        };

        getAllStockListByProductWise(data).then((res) => {
            if (res?.status == 200) {
                setLoading(false);
                setStockList(res?.data || []);
                setTotalCount(res?.pagination?.totalDocuments || 0);
                setRowsPerPage(res?.pagination?.pageSize);
                setPage(res?.pagination?.currentPage);
            } else {
                setLoading(false);
            }
        });
    };

    const {
        page,
        tabValue,
        search,
        rowsPerPage,
        setPage,
        setRowsPerPage,
        handleSearchChange,
        handlePageChange,
        handleRowsPerPageChange,
        handleTabChange,
    } = PageIndex.useDebounceHook(getAllStockByProduct, setStockList);

    useEffect(() => {
        getAllStockByProduct();
    }, []);

    if (permissions?.View) {
        return (
            <>
                <Index.Box className="admin-dashboard-content admin-user-containt">
                    <Index.Box className="admin-user-list-flex">
                        <Index.Box className="admin-breadcrumb-main">
                            <img
                                src={PageIndex.Svg.homeIcon}
                                className="admin-breadcrumb-img"
                                alt="breadcrumb"
                            />
                            <Index.Button
                                className="admin-breadcrumb-text"
                                onClick={() => {
                                    navigate("/admin/dashboard");
                                }}
                            >
                                Dashboard
                            </Index.Button>
                            <Index.Typography className="admin-breadcrumb-text">
                                /
                            </Index.Typography>
                            <Index.Button className="admin-breadcrumb-text admin-breadcrumb-active">
                                Stock List
                            </Index.Button>
                        </Index.Box>
                        <Index.Box className="admin-search-right-main">
                            <Index.Box className="search-input-box admin-input-box">
                                <Index.Box className="admin-form-group">
                                    <Index.TextField
                                        size="small"
                                        placeholder="Search"
                                        className="admin-form-control"
                                        onChange={(e) => {
                                            const value = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s+/g, " ")
                                                .slice(0, 30);
                                            setSearchTerm(value);
                                            handleSearchChange(value);
                                        }}
                                        value={searchTerm}
                                    />
                                    <img
                                        src={PageIndex.Svg.searchIcon}
                                        className="search-grey-img"
                                        alt="search"
                                    />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>

                <Index.Box className="common-card">
                    <Index.Box className="page-table-main stock-table-main">
                        <Paper
                            // sx={{ width: "100%", overflow: "hidden" }}
                            className="table-paper"
                        >
                            <Index.TableContainer
                                // sx={{ maxHeight: 480 }}
                                className="table-container"
                            >
                                <Index.Table
                                    className="table"
                                    stickyHeader
                                    aria-label="sticky table"
                                >
                                    <Index.TableHead className="table-head">
                                        <Index.TableRow className="table-row">
                                            <Index.TableCell width="5%" className="table-th" variant="th">Sr.No
                                            </Index.TableCell>
                                            <Index.TableCell width="20%" className="table-th" variant="th">
                                                Product Name
                                            </Index.TableCell>
                                            {/* <Index.TableCell width="10%" className="table-th" variant="th">Batch No.
                                            </Index.TableCell>
                                            <Index.TableCell width="10%" className="table-th" variant="th">Expire Date
                                            </Index.TableCell> */}
                                            <Index.TableCell width="10%" className="table-th" variant="th">
                                                Product count
                                            </Index.TableCell>
                                            <Index.TableCell width="5%" className="table-th" variant="th" align="right">
                                                Action
                                            </Index.TableCell>
                                        </Index.TableRow>
                                    </Index.TableHead>
                                    <Index.TableBody className="table-body">
                                        {stockList?.length > 0 ? (
                                            stockList?.map((row, index) => (
                                                <Index.TableRow key={row._id} className="table-row">
                                                    <Index.TableCell className="table-td" variant="td">
                                                        <Index.Typography className="admin-table-data-text">
                                                            {rowsPerPage * (page - 1) + index + 1}
                                                        </Index.Typography>
                                                    </Index.TableCell>
                                                    <Index.TableCell className="table-td" variant="td">
                                                        <Index.Typography className="admin-table-data-text">
                                                            {row?.product?.productName || "-"}
                                                        </Index.Typography>
                                                    </Index.TableCell>
                                                    {/* <Index.TableCell className="table-td" variant="td">
                                                        <Index.Typography className="admin-table-data-text">
                                                            {" "}
                                                            {row?.batchNo || "-"}
                                                        </Index.Typography>
                                                    </Index.TableCell>
                                                    <Index.TableCell className="table-td" variant="td">
                                                        <Index.Typography className="admin-table-data-text">
                                                            {" "}
                                                            {moment(row?.expireDate).format("DD-MMM-YYYY") || "-"}
                                                        </Index.Typography>
                                                    </Index.TableCell> */}
                                                    <Index.TableCell className="table-td" variant="td">
                                                        <Index.Typography className="admin-table-data-text">
                                                            {" "}
                                                            {row?.quantity}
                                                        </Index.Typography>
                                                    </Index.TableCell>
                                                    <Index.TableCell className="table-td">
                                                        <Index.Box className="admin-table-data-btn-flex">
                                                            {permissions?.View && (
                                                                <Index.Tooltip
                                                                    title="View"
                                                                    arrow
                                                                    placement="bottom"
                                                                    className="admin-tooltip"
                                                                >
                                                                    <Index.Button
                                                                        className="admin-table-data-btn admin-table-view-btn"
                                                                        onClick={() => {
                                                                            navigate(
                                                                                "/admin/stock/view",
                                                                                { state: { viewData: row, _id: row._id } }
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={PageIndex.Png.view}
                                                                            alt="View Icon"
                                                                            className="table-icon"
                                                                        />
                                                                    </Index.Button>
                                                                </Index.Tooltip>
                                                            )}
                                                        </Index.Box>
                                                    </Index.TableCell>
                                                </Index.TableRow>
                                            ))
                                        ) : (
                                            <Index.TableRow>
                                                <Index.TableCell
                                                    colSpan={10}
                                                    align="center"
                                                    className="data-not-found-main"
                                                >
                                                    <p className="data-not-found-text">
                                                        {loading ? <PageLoader /> : "No data found..."}
                                                    </p>
                                                </Index.TableCell>
                                            </Index.TableRow>
                                        )}
                                    </Index.TableBody>
                                </Index.Table>
                            </Index.TableContainer>
                        </Paper>
                        {totalCount > 10 && (
                            <Index.Box className="tale-pagination">
                                <Index.TablePagination
                                    component="div"
                                    rowsPerPageOptions={[10, 20, 50]}
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page - 1}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                />
                            </Index.Box>
                        )}
                    </Index.Box>
                </Index.Box>
            </>
        );
    } else {
        navigate("/admin");
    }
};

export default StockList;
