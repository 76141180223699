import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import "./printButton.css"
import PageIndex from '../../../PageIndex';
import InwardPrintComponent from './InwardPrintComponent';
import { getSingleInwardFromIndentId } from '../../../../redux/features/admin/AdminServices';
const PrintButtonInward = ({ printData }) => {
    // console.log(printData);

    const [inward, setInward] = useState({});
    const getInward = () => {
        // getSingleInwardFromIndentId(printData?._id)
        getSingleInwardFromIndentId(printData?.IndentId)
            .then(res => {
                setInward(res?.data)
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        getInward();
    }, [])
    const componentRef = useRef();
    return (
        <>
            <ReactToPrint
                trigger={() => (
                    <img
                        src={PageIndex.Png.inwardPrinter}
                        alt="Printer Icon"
                            className="table-icon"
                    // onClick={handlePrint}
                    />
                )}
                content={() => componentRef.current}
            />
            <div className="print-only">
                <InwardPrintComponent printData={inward} ref={componentRef} />
            </div>
        </>
    );
}

export default PrintButtonInward