import axios from "axios";
import { toast } from "react-toastify";
var baseUrl = "https://inventory.appworkdemo.com/api";

const DataService = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL_LOCAL,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    // "Content-Type": "application/x-www-form-urlencoded",
  },
});

DataService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const doGet = async (url) => {
  try {
    const response = await DataService.get(url);
    return response?.data;
  } catch (error) {
    return error;
  }
};
export const doPost = async (url, data) => {
  try {
    const response = await DataService.post(url, data);
    toast.success(response?.data?.message);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const doDelete = async (url, id) => {
  try {
    const response = await DataService.delete(`${url}/${id}`);
    toast.success(response?.data?.message);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const doGetSingle = async (url, userId) => {
  try {
    const response = await DataService.get(`${url}/${userId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const doGetQueryData = async (url, data) => {
  try {
    const response = await DataService.get(`${url}?postId=${data}`);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export default DataService;
