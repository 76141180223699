import React from "react";
import { Routes as Routess, Route } from "react-router-dom";

// import DefaultAdminLayout from "../component/admin/DefaultLayout";

import { lazy, Suspense } from "react";
import Loading from "../component/common/Loading";
import AddUser from "../container/admin/pages/user/AddUser";
import AdminLayout from "../component/admin/AdminLayout";
import PrivateRoutes from "./PrivateRoutes";
import PageNotFound from "../component/common/PageNotFound/PageNotFound";
import Profile from "../container/admin/pages/profile/Profile";
import ChangePassword from "../container/admin/auth/ChangePassword";
import CountryList from "../container/admin/pages/country/CountryList";
import StatesList from "../container/admin/pages/state/StatesList";
import CityList from "../container/admin/pages/city/CityList";
import UnitList from "../container/admin/pages/unit/UnitList";
import CategoryMaster from "../container/admin/pages/category/Category";
import VendorMaster from "../container/admin/pages/vendorMaster/VendorMaster";
import AddEditVendor from "../container/admin/pages/vendorMaster/AddEditVendor";
import IndentRequestList from "../container/admin/pages/indentRequest/IndentRequestList";
import AddIndentRequest from "../container/admin/pages/indentRequest/AddIndentRequest";
import EditProfile from "../container/admin/pages/profile/editProfile";
import { ViewIndent } from "../container/admin/pages/indentRequest/ViewIndent";
import BillsList from "../container/admin/pages/bills/BillsList";
import { AddBill } from "../container/admin/pages/bills/AddBills";
import BillPdf from "../container/admin/pages/bills/billPdf";
import IndentInward from "../container/admin/pages/indentRequest/IndentInward";
import POGenerateList from "../container/admin/pages/POGenerate/POGenerateList";
import { ViewPoGenetare } from "../container/admin/pages/POGenerate/ViewPoGenetare";
import StockList from "../container/admin/pages/stock/StockList";
import DashBoard from "../container/admin/pages/dashBoard/DashBoard";
import { ViewBill } from "../container/admin/pages/bills/BillView";
import { ViewStock } from "../container/admin/pages/stock/ViewStock";
import POGeneratePrintComponent from "../container/admin/pages/indentRequest/POGeneratePrintComponent";

const Login = lazy(() => import("../container/admin/auth/Login"));
const ForgotPassword = lazy(() =>
  import("../container/admin/auth/ForgotPassword")
);
const Otp = lazy(() => import("../container/admin/auth/Otp"));
const ResetPassword = lazy(() =>
  import("../container/admin/auth/ResetPassword")
);
// const DefaultAdminLayout = lazy(() =>
//   import("../component/admin/DefaultLayout")
// );
const RolePermission = lazy(() =>
  import("../container/admin/pages/rolePermission/RolePermission")
);
const AddEditRolePermission = lazy(() =>
  import("../container/admin/pages/rolePermission/AddEditRolePermission")
);
const User = lazy(() => import("../container/admin/pages/user/User"));
const BrandMaster = lazy(() =>
  import("../container/admin/pages/brandmaster/BrandMaster")
);
const ProductMaster = lazy(() =>
  import("../container/admin/pages/product/ProductMaster")
);
const AddEditProduct = lazy(() =>
  import("../container/admin/pages/product/AddEditProduct")
);
const Routes = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routess>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" element={<Login />}></Route>
          <Route path="/oo" element={<Loading />}></Route>

          <Route
            path="/admin/forgot-password"
            element={<ForgotPassword />}
          ></Route>
          <Route path="/admin/verify-otp" element={<Otp />}></Route>
          <Route
            path="/admin/reset-password"
            element={<ResetPassword />}
          ></Route>
          <Route path="/" element={<Login />}></Route>
          <Route
            path="/admin/forgot-password"
            element={<ForgotPassword />}
          ></Route>
          <Route path="/admin/verify-otp" element={<Otp />}></Route>
          <Route
            path="/admin/reset-password"
            element={<ResetPassword />}
          ></Route>

          <Route element={<PrivateRoutes />}>
            <Route path="admin" element={<AdminLayout />}>
              <Route path="dashboard" element={<DashBoard />} />
              <Route path="category" element={<CategoryMaster />} />
              <Route path="role-permission" element={<RolePermission />} />
              <Route
                path="role-permission/add-edit"
                element={<AddEditRolePermission />}
              />
              <Route path="user" element={<User />} />
              <Route path="add-user" element={<AddUser />} />
              <Route path="profile" element={<Profile />} />
              <Route path="edit-profile" element={<EditProfile />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="country-master" element={<CountryList />} />
              <Route path="state-master" element={<StatesList />} />
              <Route path="city-master" element={<CityList />} />
              <Route path="unit-master" element={<UnitList />} />
              <Route path="brand-master" element={<BrandMaster />} />
              <Route path="product-master" element={<ProductMaster />} />
              <Route path="po-pdf" element={<POGeneratePrintComponent />} />
              <Route
                path="product-master/add-edit"
                element={<AddEditProduct />}
              />
              <Route path="vendor-master" element={<VendorMaster />} />
              <Route
                path="vendor-master/add-edit"
                element={<AddEditVendor />}
              />
              <Route
                path="indent-request-list"
                element={<IndentRequestList />}
              />
              <Route
                path="indent-request/add-edit"
                element={<AddIndentRequest />}
              />
              <Route
                path="indent-request/view-Indent"
                element={<ViewIndent />}
              />
              <Route path="bill/add-bill" element={<AddBill />} />
              <Route path="bill/get-all-bill" element={<BillsList />} />

              <Route
                path="po-generate"
                element={<POGenerateList />}
              />
              <Route
                path="po-generate/inward"
                element={<IndentInward />}
              />
              <Route
                path="po-generate/view"
                element={<ViewPoGenetare />}
              />
              <Route
                path="stock"
                element={<StockList />}
              />
              <Route path="bill/view" element={<ViewBill />} />
              <Route
                path="stock/view"
                element={<ViewStock />}
              />
            </Route>

            <Route path="bill" element={<BillPdf />} />

          </Route>
        </Routess>
      </Suspense>
    </>
  );
};

export default Routes;
