import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import AddState from "./AddState";

const label = { inputProps: { "aria-label": "Switch demo" } };

const StatesList = () => {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState({});
  const [open, setOpen] = useState(false);
  const [removeId, setRemoveId] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [totalCount, setTotalCount] = Index.useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editData, setEditData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.StateManagement;

  const getStateList = async (searchTerm, pageNo, dataPerPage) => {
    setLoading(true);
    dispatch(
      PageIndex.getAllState({
        page: pageNo ?? page,
        rowsPerPage: dataPerPage ?? rowsPerPage,
        searchTerm: searchTerm ?? search,
      })
    )
      .then((res) => {
        setFilterData(res?.payload?.data);
        setTotalCount(res?.payload?.pagination?.totalDocuments);
        setPage(res?.payload?.pagination?.currentPage);
        setRowsPerPage(res?.payload?.pagination?.pageSize);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getStateList();
  }, [openDrawer]);

  const {
    page,
    tabValue,
    search,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    handleSearchChange,
    handlePageChange,
    handleRowsPerPageChange,
    handleTabChange,
  } = PageIndex.useDebounceHook(getStateList, setFilterData);
  const handleDelete = async () => {
    dispatch(PageIndex.deleteState(removeId))
      .then((res) => {
        setOpen(false);
        getStateList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStatus = async (id, isActive) => {
    setLoadingRowId((prev) => ({ ...prev, [id]: true }));
    const data = new URLSearchParams();
    data.append("id", id);
    data.append("isActive", isActive);
    dispatch(PageIndex.activeDeactiveState({ id, isActive }))
      .then((res) => {
        getStateList();
        setLoadingRowId((prev) => ({ ...prev, [id]: false }));
      })
      .catch((err) => {
        console.log(err);
        setLoadingRowId((prev) => ({ ...prev, [id]: false }));
      });
  };

  const handleEdit = (item) => {
    setOpenDrawer(true);
    setEditData(item);
  };

  if (permissions?.View) {
    return (
      <>
        <Index.Drawer
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(!openDrawer);
            setEditData(null);
          }}
          anchor="right"
        >
          <Index.Box className="drawer">
            <AddState
              setOpenDrawer={setOpenDrawer}
              editData={editData}
              setEditData={setEditData}
            />
          </Index.Box>
        </Index.Drawer>

        <Index.Box className="admin-dashboard-content admin-state-containt">
          <Index.Box className="admin-user-list-flex">
            <Index.Box className="admin-breadcrumb-main">
              <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
              <Index.Button className='admin-breadcrumb-text' onClick={() => {
                navigate("/admin/dashboard");
              }}>Dashboard</Index.Button>
              <Index.Typography className='admin-breadcrumb-text'>/</Index.Typography>
              <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'>State Master</Index.Button>
            </Index.Box>
            <Index.Box className="admin-search-right-main">
              <Index.Box className="search-input-box admin-input-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    size="small"
                    placeholder="Search"
                    className="admin-form-control"
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s+/g, " ")
                        .slice(0, 30);
                      setSearchTerm(value);
                      handleSearchChange(value);
                    }}
                    value={searchTerm}
                  />
                  <img src={PageIndex.Svg.searchIcon} className="search-grey-img" alt="search" />
                </Index.Box>
              </Index.Box>

              {permissions?.Add && (
                <Index.Box className="primary-btn-main add-btn-main">
                  <Index.CommonButton
                    btnLabel="Add State"
                    startIcon={<Index.AddCircleOutlinedIcon className="add-btn-icon" />}
                    className={"primary-btn add-btn"}
                    onClick={() => setOpenDrawer(!openDrawer)}
                  />
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>

          <Index.Box className="common-card">
            <Index.Box className="page-table-main state-table-main">
              <Index.Paper
                // sx={{ width: "100%", overflow: "hidden" }}
                className="table-paper"
              >
                <Index.TableContainer
                  // sx={{ maxHeight: 480 }}
                  className="table-container"
                >
                  <Index.Table
                    className="table"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <Index.TableHead className="table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell className="table-th" variant="th">Sr.No</Index.TableCell>
                        <Index.TableCell className="table-th" variant="th">State name</Index.TableCell>
                        <Index.TableCell className="table-th" variant="th">Country name</Index.TableCell>
                        {permissions?.Edit && (
                          <Index.TableCell className="table-th" variant="th">Status</Index.TableCell>
                        )}
                        {(permissions?.Edit || permissions?.Delete) && (
                          <Index.TableCell className="table-th" variant="th" align="right">Action</Index.TableCell>
                        )}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {filterData?.length > 0 ? (
                        filterData?.map((row, index) => (
                          <Index.TableRow key={row._id} className="table-row">
                            <Index.TableCell className="table-td" variant="td">
                              <Index.Typography className="admin-table-data-text">
                                {rowsPerPage * (page - 1) + index + 1}
                              </Index.Typography>
                            </Index.TableCell>
                            <Index.TableCell className="table-td" variant="td">
                              <Index.Typography className="admin-table-data-text">
                                {row?.stateName ? row?.stateName : "-"}
                              </Index.Typography>
                            </Index.TableCell>
                            <Index.TableCell className="table-td" variant="td">
                              <Index.Typography className="admin-table-data-text">
                                {row?.countryId?.countryName
                                  ? row?.countryId?.countryName
                                  : "-"}
                              </Index.Typography>
                            </Index.TableCell>

                            {permissions?.Edit && (
                              <Index.TableCell className="table-td" variant="td">
                                <Index.Switch
                                  className="status-checkbox"
                                  size="small"
                                  {...label}
                                  checked={row?.isActive}
                                  onClick={() =>
                                    handleStatus(
                                      row?._id,
                                      row?.isActive ? false : true
                                    )
                                  }
                                  color="success"
                                  disabled={loadingRowId[row?._id] || false}
                                />
                              </Index.TableCell>
                            )}

                            {(permissions?.Edit || permissions?.Delete) && (
                              <Index.TableCell className="table-td" variant="td">
                                <Index.Box className="admin-table-data-btn-flex">
                                  {permissions?.Edit && (
                                    <Index.Tooltip
                                      title="Edit"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn admin-table-edit-btn"
                                        onClick={() => {
                                          handleEdit(row);
                                        }}
                                      >
                                        <img
                                          src={PageIndex.Svg.Edit}
                                          alt="Edit Icon"
                                          className="table-icon"
                                        />
                                      </Index.Button>
                                    </Index.Tooltip>
                                  )}

                                  {permissions?.Delete && (
                                    <Index.Tooltip
                                      title="Delete"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn admin-table-delete-btn"
                                        onClick={() => {
                                          setOpen(true);
                                          setRemoveId(row?._id);
                                        }}
                                      >
                                        <img
                                          src={PageIndex.Svg.Delete}
                                          alt="Delete Icon"
                                          className="table-icon"
                                        />
                                      </Index.Button>
                                    </Index.Tooltip>
                                  )}
                                </Index.Box>
                              </Index.TableCell>
                            )}
                          </Index.TableRow>
                        ))
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            colSpan={10}
                            align="center"
                            className="data-not-found-main"
                          >
                            <p className="data-not-found-text">
                              {loading ? (
                                <PageIndex.PageLoader />
                              ) : (
                                "No data found..."
                              )}
                            </p>
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Paper>
              {totalCount > 10 && (
                <Index.Box className="tale-pagination">
                  <Index.TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 20, 50]}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>
          <PageIndex.DeleteModal
            open={open}
            setOpen={setOpen}
            handleDelete={handleDelete}
          />
        </Index.Box>
      </>
    );
  } else {
    navigate("/admin/dashboard");
  }
};

export default StatesList;
