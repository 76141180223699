// component index

import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormHelperText,
  Modal,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Menu,
  MenuItem,
  Avatar,
  Grid,
  Tooltip,
  Badge,
  Select,
  Collapse,
  ListItemButton,
  styled,
  Paper
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ManageAccountsOutlined,
  AppRegistrationOutlined,
  ScatterPlotOutlined,
  SettingsOutlined,
  MenuOutlined, FlagOutlined, HomeWorkOutlined, ApartmentOutlined, Edit
} from "@mui/icons-material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useNavigate, useLocation, Link } from "react-router-dom";
import Png from "../assets/Png";
import Svg from "../assets/Svg";
import { useSelector, useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import BalanceOutlinedIcon from "@mui/icons-material/BalanceOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import BrandIcon from '@mui/icons-material/LocalOfferOutlined';
import ProductIcon from '@mui/icons-material/EventOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';


export default {
  PersonIcon,
  LogoutIcon,
  useDispatch,
  useSelector,
  Png,
  Svg,
  Link,
  SettingsIcon,
  useNavigate,
  ExpandLess,
  ExpandMore,
  CategoryOutlinedIcon,
  useLocation,
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormHelperText,
  Modal,
  InputAdornment,
  IconButton,
  OutlinedInput,
  ReceiptIcon,
  Menu,
  MenuItem,
  Avatar,
  Grid,
  Tooltip,
  Badge,
  Select,
  Collapse,
  ManageAccountsOutlined,
  AppRegistrationOutlined,
  PeopleAltIcon,
  ScatterPlotOutlined,
  SettingsOutlined,
  MenuOutlined,
  PersonIcon,
  DashboardIcon,
  LogoutIcon,
  ListItemButton,
  FlagOutlined,
  HomeWorkOutlined,
  ApartmentOutlined,
  Edit,
  PublicOutlinedIcon,
  MapOutlinedIcon,
  LocationCityOutlinedIcon,
  SchoolOutlinedIcon,
  BalanceOutlinedIcon,
  BrandIcon,
  ProductIcon,
  RecentActorsOutlinedIcon,
  styled,
  Paper,
  InventoryIcon
};
