import { Navigate } from "react-router-dom";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const AddCountry = (props) => {
  const dispatch = Index.useDispatch();
  const editData = props?.editData;
  const [loading, setLoading] = Index.useState(false);

  const initialValues = {
    countryName: editData?.countryName || "",
  };

  const addEditCountry = (formData) => {
    setLoading(true);
    dispatch(PageIndex.addEditCountry(formData))
      .then((res) => {
        if (res?.payload) {
          props.setOpenDrawer(false);
          props.setEditData(null);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    values,
    errors,
    handleBlur,
    setFieldValue,
    handleSubmit,
    touched,
    setTouched,
  } = Index.useFormik({
    initialValues: initialValues,
    validationSchema: Index.addCountryValidation,
    onSubmit: (values) => {
      const formData = {
        ...(editData?._id ? { id: editData._id } : {}),
        countryName: values.countryName?.trim(),
      };
      addEditCountry(formData);
    },
  });

  const handleBack = () => {
    props.setOpenDrawer(false);
    props.setEditData(null);
  };

  return (
    <Index.Box className="drawer-main">
      <Index.Box className="drawer-header">
        <Index.Typography className="drawer-header-title">
          {editData ? "Update" : "Add"} Country
        </Index.Typography>
        <Index.CloseIcon
          className="drawer-cancel-icon"
          onClick={handleBack}
        />
        <Index.Box>
        </Index.Box>
      </Index.Box>
      <form onSubmit={handleSubmit}>
        <Index.Box className="drawer-body">
          <Index.Box className="grid-main add-country-row">
            <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
              <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                className="grid-column">
                <Index.Box className="add-country-input-main admin-input-box">
                  <Index.FormHelperText className='admin-form-lable'>Country Name<span className="required-sign">*</span></Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <Index.TextField
                      className="admin-form-control"
                      placeholder="Enter country name"
                      type="text"
                      size="small"
                      name="countryName"
                      value={values.countryName}
                      inputProps={{ maxLength: 30 }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                          setFieldValue(
                            "countryName",
                            inputValue.replace(/^\s+/, "").replace(/\s+/g, " ")
                          );
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  </Index.Box>
                  <Index.Typography className="admin-error-text">
                    {touched?.countryName && errors?.countryName}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="drawer-footer">
          <Index.Box className="drawer-footer-button-main">
            <Index.Button className="drawer-footer-button" onClick={handleBack}>
              Cancel
            </Index.Button>
            <Index.CommonButton
              btnLabel={editData ? "Update" : "Add"}
              type="submit"
              loading={loading}
              className="drawer-footer-button"
            />
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Box>
  );
};

export default AddCountry;
