import { useState } from "react";
import _ from "lodash";

const useDebounceHook = (fetchData, setIndentRequest) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const handleSearch = _.debounce((value) => {
    setSearch(value);
    setIndentRequest([]);
    fetchData(value, 1, rowsPerPage, tabValue);
  }, 800);

  const debouncedPageChange = _.debounce((newPage) => {
    setPage(newPage + 1);
    setIndentRequest([]);
    fetchData(search, newPage + 1, rowsPerPage, tabValue);
  }, 800);

  const debouncedRowsPerPageChange = _.debounce((value) => {
    setRowsPerPage(value);
    setIndentRequest([]);
    setPage(1);
    fetchData(search, 1, value, tabValue);
  }, 800);

  const debouncedTabChange = _.debounce((newValue) => {
    setTabValue(newValue);
    fetchData(search, 1, rowsPerPage, newValue);
  }, 0);

  const handleSearchChange = (value) => {
    handleSearch(value.trim().toLowerCase());
  };

  const handlePageChange = (e, newPage) => {
    debouncedPageChange(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    debouncedRowsPerPageChange(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    debouncedTabChange(newValue);
  };

  return {
    page,
    tabValue,
    search,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    handleTabChange,
    handleSearchChange,
  };
};

export default useDebounceHook;
