
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { billMasterSchema } from "../../../../validation/Validation";
import { getAllProductWithoutPagination } from "../../../../redux/features/admin/AdminServices";

const AddBill = () => {
  const navigate = Index.useNavigate();

  const [loading, setLoading] = useState(false);
  const { state } = Index.useLocation();

  const [products, setProducts] = useState([]);

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.VendorManagement;

  let editData = state?.item;

  let initialValues = {
    totalPrice: Number(editData?.totalPrice) || "",
    sGst: editData?.sGst || 9,
    cGst: editData?.cGst || 9,
    products:
      editData?.products?.length > 0
        ? editData.products.map((product) => ({
          productId: product.productId._id || "",
          productName: product.productId?.productName || "",
          price: product.price || 0,
          productQuantity: product.productQuantity || 1,
          unitPrice: product.unitPrice || 0,
        }))
        : [{ productId: "", productName: "", price: 0, productQuantity: 1 }],
    customerName: editData?.customerName || "",
    mobileNumber: editData?.mobileNumber || "",
    discount: editData?.discount || 0,
    id: editData?._id || "",
    discountPrice: editData?.discountPrice || 0,
  };


  const handleFormSubmit = async (values, { resetForm }) => {
    setLoading(true);

    // Calculate total MRP based on product prices and quantities
    const totalMRP = values.products.reduce(
      (acc, product) => acc + product?.price * product?.productQuantity,
      0
    );

    // Retrieve the discount percentage (if any)
    const discountPercentage = values?.discount || 0;

    // Calculate SGST and CGST (9% each of totalMRP)
    const sGst = (totalMRP * 9) / 100;
    const cGst = (totalMRP * 9) / 100;

    // Calculate the total price (before discount)
    let totalPrice =
      (Number(totalMRP) || 0) + (Number(sGst) || 0) + (Number(cGst) || 0);

    // Calculate the discount amount and subtract it from the total price
    const discountAmount = (totalPrice * discountPercentage) / 100;
    totalPrice -= discountAmount;

    // Prepare the data object to be sent
    const data = {
      totalPrice: +totalPrice.toFixed(2) || 0,
      sGst: sGst.toFixed(2),
      cGst: cGst.toFixed(2),
      products: values?.products,
      customerName: values?.customerName || "",
      mobileNumber: values?.mobileNumber || "",
      discount: values?.discount || 0,
      id: editData?._id || "",
      discountPrice: discountAmount.toFixed(2) || 0,
    };

    let res = await PageIndex.addBill(data);
    resetForm();
    navigate("/admin/bill/get-all-bill");
    setLoading(false);
  };

  const { handleBlur, handleSubmit, touched, setFieldValue, values, errors } =
    Index.useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: billMasterSchema,
      onSubmit: handleFormSubmit,
    });

  const handleGetAllProduct = () => {
    getAllProductWithoutPagination().then((res) => {
      setProducts(
        res?.data?.map((item) => {
          return {
            productId: item?._id,
            productName: item?.productName,
            vendor: "",
            productQuantity: "",
            price: item?.MRP || 0,
          };
        })
      );
    });
  };

  useEffect(() => {
    handleGetAllProduct();
  }, []);

  const [totalPriceState, setTotalPriceState] = useState(0);
  const [discountPriceState, setdiscountPriceState] = useState(0);
  const [sGstState, setSGstState] = useState(0);
  const [cGstState, setCGstState] = useState(0);

  useEffect(() => {
    if (values?.products?.length > 0) {
      let totalMRP = values.products.reduce(
        (acc, product) =>
          acc + (product?.price || 0) * (product?.productQuantity || 0), // Multiply price with quantity
        0
      );

      const discountPercentage = values?.discount || 0;

      const sGst = (totalMRP * 9) / 100 || 0;
      const cGst = (totalMRP * 9) / 100 || 0;

      let price = totalMRP + sGst + cGst;
      const discountAmount = (price * discountPercentage) / 100;
      price -= discountAmount;

      setTotalPriceState(price.toFixed(2));
      setdiscountPriceState(discountAmount.toFixed(2));
      setSGstState(sGst.toFixed(2));
      setCGstState(cGst.toFixed(2));
    }
  }, [
    values?.products,
    values?.products?.map((product) => product?.productQuantity),
  ]); // Watch for changes in product quantities


  if (permissions?.Add || permissions?.Edit) {
    return (
      <>
        <Index.Box className="admin-dashboard-content admin-add-bill-containt">
          <Index.Box className="admin-user-list-flex">
            <Index.Box className="admin-breadcrumb-main">
              <img
                src={PageIndex.Svg.homeIcon}
                className="admin-breadcrumb-img"
                alt="breadcrumb"
              />
              <Index.Button
                className="admin-breadcrumb-text"
                onClick={() => {
                  navigate("/admin/user");
                }}
              >
                Bill
              </Index.Button>
              <Index.Typography className="admin-breadcrumb-text">
                /
              </Index.Typography>
              <Index.Button className="admin-breadcrumb-text admin-breadcrumb-active">
                {" "}
                {editData ? "Update" : "Add"} Bill
              </Index.Button>
            </Index.Box>
          </Index.Box>




          <form onSubmit={handleSubmit}>
            <Index.Box className="common-card add-bill-card">
              <Index.Box className="card-title-main">
                <Index.Typography className="card-title">
                  Bill Information
                </Index.Typography>
              </Index.Box>
              <Index.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="add-user-input-main admin-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Customer Name
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Customer name"
                        className="admin-form-control"
                        name="customerName"
                        onChange={(e) => {
                          const inputValue = e?.target?.value;
                          if (inputValue?.toString()?.length <= 30) {
                            setFieldValue(
                              "customerName",
                              inputValue
                                .replace(/^\s+/, "")
                                .replace(/\s+/g, " ")
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        value={values?.customerName}
                      />
                      <Index.FormHelperText className="admin-error-text">
                        {touched?.customerName && errors?.customerName}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="add-user-input-main admin-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Mobile Number
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="filled-hidden-label-normal"
                        type="text"
                        placeholder="Mobile number"
                        className="admin-form-control"
                        name="mobileNumber"
                        onBlur={handleBlur}
                        value={values?.mobileNumber}
                        onChange={(e) => {
                          const inputValue = e?.target?.value?.replace(
                            /[^\d]/g,
                            ""
                          );
                          if (inputValue?.toString()?.length <= 10) {
                            setFieldValue("mobileNumber", inputValue);
                          }
                        }}
                        startAdornment={
                          <Index.InputAdornment position="start"></Index.InputAdornment>
                        }
                      />
                      <Index.FormHelperText className="admin-error-text">
                        {touched?.mobileNumber && errors?.mobileNumber}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="common-card add-bill-card">
              <Index.Box className="grid-main add-more-row add-lable-row">
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 1",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box add-more-product-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Sr.No.
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box add-more-product-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        {" "}
                        Product Name
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box add-more-product-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Quantity
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 2",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box add-more-product-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Unit Price
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 2",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box add-more-product-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Price
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 2",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box add-more-product-input-box">
                      <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              {/* Always show one default row */}

              {/* Additional rows when products are added */}
              {values?.products?.length > 0 &&
                values?.products?.map((product, index) => (
                  <Index.Box
                    className="grid-main add-more-row add-input-row "
                    key={index}
                  >
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 1",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            {index + 1}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>

                      {/* Autocomplete for product selection */}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.Box className="admin-form-group">
                            {/* Autocomplete for product selection */}
                            <Index.Autocomplete
                              className=" admin-form-control admin-autocomplete"
                              options={products}
                              getOptionLabel={(option) =>
                                option.productName || ""
                              }
                              disableClearable
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  const selectedProduct = products.find(
                                    (prod) =>
                                      prod.productId === newValue.productId
                                  );
                                  setFieldValue(`products[${index}]`, {
                                    productId: selectedProduct?.productId || "",
                                    productName:
                                      selectedProduct?.productName || "",
                                    price: selectedProduct?.price || 0,
                                    productQuantity: 1,
                                    unitPrice: selectedProduct?.unitPrice || 0,
                                  });
                                } else {
                                  setFieldValue(`products[${index}]`, {
                                    productId: "",
                                    productName: "",
                                    price: 0,
                                    productQuantity: "",
                                  });
                                }
                              }}
                              value={
                                product.productId !== ""
                                  ? values.products[index]
                                  : values.products[index].productId
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.productId === value.productId
                              }
                              renderOption={(props, option) => (
                                <Index.MenuItem
                                  {...props}
                                  key={`${option.productId}-${option.productName}`}
                                  value={option.productId}
                                >
                                  {option.productName}
                                </Index.MenuItem>
                              )}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Select Product"
                                />
                              )}
                            />

                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      {/* Quantity input */}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              className="admin-form-control"
                              hiddenLabel
                              placeholder="Quantity"
                              name={`products[${index}].productQuantity`}
                              value={product?.productQuantity || ""}
                              onChange={(e) => {
                                const inputValue = e.target.value.replace(
                                  /[^\d]/g,
                                  ""
                                ); // Only allow numbers
                                setFieldValue(
                                  `products[${index}].productQuantity`,
                                  inputValue
                                );
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      {/* Unit Price input */}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 2",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              hiddenLabel
                              placeholder="Unit Price"
                              className="admin-form-control"
                              name={`products[${index}].price`}
                              value={product?.price || ""}
                              onChange={(e) => {
                                const inputValue = e.target.value.replace(
                                  /[^\d]/g,
                                  ""
                                ); // Only allow numbers
                                setFieldValue(
                                  `products[${index}].price`,
                                  inputValue
                                );
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      {/* Price calculation */}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 2",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-user-input-main admin-input-box">
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              hiddenLabel
                              placeholder="Price"
                              className="admin-form-control"
                              name={`products[${index}].price`}
                              value={
                                product?.price * product?.productQuantity || ""
                              }
                              disabled
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      {/* Add/Delete buttons */}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 1",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-more-btn-main">
                          {/* Add button for last row */}
                          {index === values?.products?.length - 1 && (
                            <Index.Button
                              className="add-more-btn"
                              variant="contained"
                              onClick={() => {
                                const newProduct = {
                                  productId: "",
                                  productName: "",
                                  productQuantity: "1",
                                  price: "",
                                  unitPrice: "",
                                };
                                setFieldValue("products", [
                                  ...(values?.products || []),
                                  newProduct,
                                ]);
                              }}
                            >
                              <img
                                src={PageIndex.Svg.plusIcon}
                                alt="Plus Icon"
                                className="add-icon"
                              />
                            </Index.Button>
                          )}

                          {/* Delete button for rows except default row */}
                          {values.products?.length > 0 && index > 0 && (
                            <Index.Button
                              className="add-more-btn"
                              variant="contained"
                              onClick={() => {
                                const updatedProducts =
                                  values?.products?.filter(
                                    (prod, idx) => idx !== index
                                  );
                                setFieldValue("products", updatedProducts);
                              }}
                            >
                              <img
                                src={PageIndex.Svg.trashbinIcon}
                                alt="Delete Icon"
                                className="add-icon"
                              />
                            </Index.Button>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                ))}
            </Index.Box>

            <Index.Box className="common-card add-bill-card">
              <Index.Box className="grid-main">
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 2",
                      lg: "span 2",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Discount(%)
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          fullWidth
                          id="filled-hidden-label-normal"
                          type="text"
                          placeholder="Discount"
                          className="admin-form-control"
                          name="discount"
                          onBlur={handleBlur}
                          value={values.discount}
                          onChange={(e) => {
                            const inputValue = e?.target?.value?.replace(
                              /[^\d]/g,
                              ""
                            );
                            if (inputValue?.toString()?.length <= 10) {
                              setFieldValue("discount", inputValue);
                            }
                          }}
                          startAdornment={
                            <Index.InputAdornment position="start"></Index.InputAdornment>
                          }
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {touched?.discount && errors?.discount}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 2",
                      lg: "span 2",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Discount Amount
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Discount Amount"
                          className="admin-form-control"
                          name="sGst"
                          value={discountPriceState}
                          disabled
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        SGST (9%)
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="SGST"
                          className="admin-form-control"
                          name="sGst"
                          value={sGstState}
                          disabled
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        CGST (9%)
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="CGST"
                          className="admin-form-control"
                          name="cGst"
                          value={cGstState}
                          disabled
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 2",
                      lg: "span 2",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="add-user-input-main admin-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Price
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Total Price"
                          className="admin-form-control"
                          name="totalPrice"
                          value={totalPriceState}
                          disabled
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="admin-btn-flex-end primary-btn-main">
              <Index.Button
                variant="contained"
                type="submit"
                disabled={loading}
                className="primary-btn"
              >
                {editData ? "Update" : "Submit"}
              </Index.Button>
            </Index.Box>
          </form>
        </Index.Box>
      </>
    );
  }
};

export { AddBill };