import GroupIcon from "../assets/images/svg/group.svg";
import groupSvg from "../assets/images/svg/group.svg";
import usergroup from "../assets/images/svg/usergroup.svg";
import UserManagement from "../assets/images/svg/UserManageSvg";
import RoleSvg from "../assets/images/svg/RoleSvg";
import CountrySvg from "../assets/images/svg/country.svg";
import Edit from "../assets/images/svg/edit.svg";
import Delete from "../assets/images/svg/delete.svg";
import Approve from "../assets/images/svg/approve.svg";
import Reject from "../assets/images/svg/remove.svg";
import Approved from "../assets/images/svg/approved.svg";
import Rejected from "../assets/images/svg/rejected.svg";
import homeIcon from "../assets/images/svg/home.svg";
import searchIcon from "../assets/images/svg/search.svg";
import avtarIcon from "../assets/images/svg/avtar.svg";
import totalUserIcon from "../assets/images/svg/total-user.svg";
import vendorIcon from "../assets/images/svg/vendor.svg";
import productIcon from "../assets/images/svg/product-owner.svg";
import categoryIcon from "../assets/images/svg/category.svg";
import nextArrowIcon from "../assets/images/svg/next-arrow.svg";
import pencilIcon from "../assets/images/svg/pencil.svg";
import trashbinIcon from "../assets/images/svg/trash-bin.svg";
import plusIcon from "../assets/images/svg/plus-sign.svg";
import mobileMenuIcon from "../assets/images/svg/mobile-menu.svg";
import downloadIcon from "../assets/images/svg/download.svg";
import cancellIcon from "../assets/images/svg/cancell.svg";
import statusIcon from "../assets/images/svg/status.svg";



export default {
  GroupIcon,
  groupSvg,
  usergroup,
  GroupIcon,
  UserManagement,
  RoleSvg,
  CountrySvg,
  Edit,
  Delete,
  Approved,
  Rejected,
  Reject,
  Approve,
  homeIcon,
  searchIcon,
  avtarIcon,
  totalUserIcon,
  vendorIcon,
  productIcon,
  categoryIcon,
  nextArrowIcon,
  pencilIcon,
  trashbinIcon,
  plusIcon,
  mobileMenuIcon,
  downloadIcon,
  cancellIcon,
  statusIcon,
};
