import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const Profile = () => {
  const navigate = Index.useNavigate();
  const [isSave, setIsSave] = useState(false);
  const { adminData } = Index.useSelector((state) => state.admin);
// console.log(adminData,'adminData');
// console.log(process.env.REACT_APP_IMAGE_URL,'adminData');

  return (
    <>
<Index.Box className="admin-dashboard-content admin-profile-containt">
      <Index.Box className="admin-user-list-flex">
        <Index.Box className="admin-breadcrumb-main">
          <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
          <Index.Button className='admin-breadcrumb-text' onClick={() => {
            navigate("/admin/user");
          }}>User</Index.Button>
          <Index.Typography className='admin-breadcrumb-text'>/</Index.Typography>
          <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'>Profile Detail</Index.Button>
        </Index.Box>
        <Index.Box className="edit-btn-main">
          <Index.Button
            variant="contained"
            type="submit"
            size="small"
            onClick={() => navigate("/admin/edit-profile")}
            className="edit-btn"
          >
            Edit
            <img src={PageIndex.Svg.Edit} alt="edit" className="edit-icon-svg" />
          </Index.Button>
        </Index.Box>
      </Index.Box>
      <Index.Box
        className={`profile-flex common-card ${isSave ? "" : "disable"}`}
      >

        <Index.Box className="profile-view-img-box">
          <img
            src={
              adminData?.profile
                ? `${process.env.REACT_APP_IMAGE_URL}${adminData?.profile}`
                : ""
            }
            onError={(e) => {
              e.target.src = PageIndex.Png.profile;
            }}
            alt="Profile Image"
            className="profile-view-img"
          />
        </Index.Box>

        <Index.Box className="profile-view-box">
          <Index.Box className="page-detail-flex">
            <Index.Typography
              className="page-lable"
            >
              First Name <span>:</span>
            </Index.Typography>

            <Index.Typography
              className="page-value"
            >
              {adminData?.firstName}
            </Index.Typography>
          </Index.Box>

          <Index.Box className="page-detail-flex">
            <Index.Typography
              className="page-lable"
            >
              Last Name <span>:</span>
            </Index.Typography>
            <Index.Typography
              className="page-value"
            >
              {adminData?.lastName}
            </Index.Typography>
          </Index.Box>

          <Index.Box className="page-detail-flex">
            <Index.Typography
              className="page-lable"
            >
              Email <span>:</span>
            </Index.Typography>
            <Index.Typography
              className="page-value"
            >
              {adminData?.email}
            </Index.Typography>
          </Index.Box>

          <Index.Box className="page-detail-flex">
            <Index.Typography
              className="page-lable"
            >
              Role<span>:</span>
            </Index.Typography>
            <Index.Typography
              className="page-value"
            >
              {adminData?.role?.role}
            </Index.Typography>
          </Index.Box>

          <Index.Box className="page-detail-flex">
            <Index.Typography
              className="page-lable"
            >
              Mobile Number<span>:</span>
            </Index.Typography>
            <Index.Typography
              className="page-value"
            >
              {adminData?.mobileNumber}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      </Index.Box>
    </>
  );
};

export default Profile;
