import React from "react";

const RoleSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="17"
      height="17"
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_75"
            data-name="Rectangle 75"
            width="18"
            height="18"
            transform="translate(20 106)"
            // fill="#fff"
            // stroke="#707070"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_11"
        data-name="Mask Group 11"
        transform="translate(-20 -106)"
        clip-path="url(#clip-path)"
      >
        <g id="__TEMP__SVG__" transform="translate(18.714 104.714)">
          <path
            id="Path_14"
            data-name="Path 14"
            d="M16.071,6.429,17.1,8.357l2.19.266-1.607,1.448L18,12.214l-1.929-1.205-1.929,1.205.321-2.143L12.857,8.623l2.25-.266Z"
            // fill="#fff"
            fill="currentColor"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M14.143,19.286H12.857V16.071a3.218,3.218,0,0,0-3.214-3.214H5.786a3.218,3.218,0,0,0-3.214,3.214v3.214H1.286V16.071a4.505,4.505,0,0,1,4.5-4.5H9.643a4.505,4.505,0,0,1,4.5,4.5Z"
            // fill="#fff"
            fill="currentColor"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M7.714,2.571A3.214,3.214,0,1,1,4.5,5.786,3.214,3.214,0,0,1,7.714,2.571m0-1.286a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,7.714,1.286Z"
            // fill="#fff"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default RoleSvg;
