import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { ActivityLog } from "../activityLog/ActivityLog";

export const ViewBill = () => {
  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.IndentManagement;
  const navigate = Index.useNavigate();
  const { state } = Index.useLocation();

  if (permissions?.View) {
    return (
      <>
        <Index.Box className="admin-dashboard-content admin-user-containt">
          <Index.Box className="admin-user-list-flex">
            <Index.Box className="admin-breadcrumb-main">
              <img
                src={PageIndex.Svg.homeIcon}
                className="admin-breadcrumb-img"
                alt="breadcrumb"
              />
              <Index.Button
                className="admin-breadcrumb-text"
                onClick={() => {
                  navigate("/admin/indent-request-list");
                }}
              >
                Bill Master
              </Index.Button>
              <Index.Typography className="admin-breadcrumb-text">
                /
              </Index.Typography>
              <Index.Button className="admin-breadcrumb-text admin-breadcrumb-active">
                View Bill
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="common-card">
          <Index.Box className="card-title-main">
            <Index.Typography className="card-title">
              View Bill
            </Index.Typography>
          </Index.Box>

          <Index.Box className="view-page-main view-indent-card">
            <Index.Box className="page-detail-flex">
              <Index.Typography
                variant="label"
                component="label"
                className="page-lable"
              >
                Customer Name <span>:</span>
              </Index.Typography>
              <Index.Typography
                variant="label"
                component="label"
                className="page-value"
              >
                {state?.viewData?.customerName}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="page-detail-flex">
              <Index.Typography
                variant="label"
                component="label"
                className="page-lable"
              >
                Mobile Number <span>:</span>
              </Index.Typography>
              <Index.Typography
                variant="label"
                component="label"
                className="page-value"
              >
                {state?.viewData?.mobileNumber}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="page-detail-flex">
              <Index.Typography
                variant="label"
                component="label"
                className="page-lable"
              >
                Created Date <span>:</span>
              </Index.Typography>
              <Index.Typography
                variant="label"
                component="label"
                className="page-value"
              >
                {Index.moment(state?.viewData?.createdAt).format("DD/MM/YYYY")}
              </Index.Typography>
            </Index.Box>
           
            <Index.Box className="page-detail-flex">
              <Index.Typography
                variant="label"
                component="label"
                className="page-lable"
              >
                SGST <span>:</span>
              </Index.Typography>
              <Index.Typography
                variant="label"
                component="label"
                className="page-value"
              >
                {state?.viewData?.sGst}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="page-detail-flex">
              <Index.Typography
                variant="label"
                component="label"
                className="page-lable"
              >
                CGST <span>:</span>
              </Index.Typography>
              <Index.Typography
                variant="label"
                component="label"
                className="page-value"
              >
                {state?.viewData?.cGst}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="page-detail-flex">
              <Index.Typography
                variant="label"
                component="label"
                className="page-lable"
              >
                Discount <span>:</span>
              </Index.Typography>
              <Index.Typography
                variant="label"
                component="label"
                className="page-value"
              >
                {state?.viewData?.discount}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="page-detail-flex">
              <Index.Typography
                variant="label"
                component="label"
                className="page-lable"
              >
                Discount Amount<span>:</span>
              </Index.Typography>
              <Index.Typography
                variant="label"
                component="label"
                className="page-value"
              >
                {state?.viewData?.discountPrice}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="page-detail-flex">
              <Index.Typography
                variant="label"
                component="label"
                className="page-lable"
              >
                Price <span>:</span>
              </Index.Typography>
              <Index.Typography
                variant="label"
                component="label"
                className="page-value"
              >
                {state?.viewData?.totalPrice}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="page-detail-flex">
              <Index.Typography
                variant="label"
                component="label"
                className="page-lable"
              >
                Status <span>:</span>
              </Index.Typography>
              <Index.Typography
                variant="label"
                component="label"
                className="page-value"
              >
                {state?.viewData?.status}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card view-indent-card">
          <Index.Box className="page-table-main user-table-main">
            <Index.Paper
              className="table-paper"
            >
              <Index.TableContainer
                className="table-container"
              >
                <Index.Table
                  className="table custom-table"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        width="10%"
                        className="table-th"
                        variant="th"
                      >
                        Sr.No.
                      </Index.TableCell>
                      <Index.TableCell
                        width="10%"
                        className="table-th"
                        variant="th"
                      >
                        Product Name
                      </Index.TableCell>
                      <Index.TableCell
                        width="10%"
                        className="table-th"
                        variant="th"
                      >
                        Product Code
                      </Index.TableCell>
                      <Index.TableCell
                        width="10%"
                        className="table-th"
                        variant="th"
                      >
                        Quantity
                      </Index.TableCell>
                      <Index.TableCell
                        width="10%"
                        className="table-th"
                        variant="th"
                      >
                        Unit Price
                      </Index.TableCell>
                      <Index.TableCell
                        width="10%"
                        className="table-th"
                        variant="th"
                      >
                        Total Price
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {state?.viewData?.products.map((product, index) => (
                      <Index.TableRow
                        key={product?.product}
                        className="table-row"
                      >
                        <Index.TableCell className="table-td" variant="td">
                          <Index.Typography className="admin-table-data-text">
                            {index + 1}
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell className="table-td" variant="td">
                          <Index.Typography className="admin-table-data-text">
                            {product?.productId?.productName || "-"}
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell className="table-td" variant="td">
                          <Index.Typography className="admin-table-data-text">
                            {product?.productId?.productCode || "-"}
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell className="table-td" variant="td">
                          <Index.Typography className="admin-table-data-text">
                            {PageIndex.numeralValueConverter(product?.productQuantity)}
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell className="table-td" variant="td">
                          <Index.Typography className="admin-table-data-text">
                            {(product?.price)}
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell className="table-td" variant="td">
                          <Index.Typography className="admin-table-data-text">
                            {(product?.price * product.productQuantity)}
                          </Index.Typography>
                        </Index.TableCell>
                      </Index.TableRow>
                    ))}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Paper>
          </Index.Box>
        </Index.Box>

        {/* {/ Activity Logs /} */}
        <ActivityLog moduleId={state?._id} />
      </>
    );
  }
};
