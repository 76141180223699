import React, { useState } from 'react'
import Index from '../../../Index';
import PageIndex from "../../../PageIndex";



export default function DashBoard() {
      const navigate = Index.useNavigate();
      const [rowsPerPage, setRowsPerPage] = useState(10);
      const [page, setPage] = useState(0);
      const [filterData, setFilterData] = useState([]);
      const handleChangePage = (event, newPage) => {
            setPage(newPage);
      };
      const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
      };


      return (
            <>
                  <Index.Box className="admin-dashboard-content">
                        <Index.Box className="admin-user-list-flex">
                              <Index.Box className="admin-breadcrumb-main">
                                    <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
                                    <Index.Button className='admin-breadcrumb-text' onClick={() => {
                                          navigate("/admin/dashboard");
                                    }}></Index.Button>
                                    <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'>Dashboard</Index.Button>
                              </Index.Box>
                        </Index.Box>


                        <Index.Box className='dashboard-grid-row'>
                              <Index.Box sx={{ width: 1 }} className="grid-main">
                                    <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }}
                                                className="grid-column">
                                                <Index.Box className="admin-dashboard-box">
                                                      <Index.Box className="admin-dash-icon-flex">
                                                            <Index.Box className="admin-dash-content-box">
                                                                  <Index.Typography className='admin-dash-text' component='p'>Users</Index.Typography>
                                                                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>1325M</Index.Typography>
                                                            </Index.Box>
                                                            <Index.Box className="admin-dash-icon-box">
                                                                  <img src={PageIndex.Svg.totalUserIcon} className="admin-dash-icons" alt='dashboard icon' />
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="admin-dash-btn-box">
                                                            <Index.Button variant="contained" className='admin-dash-btn'>See Details<span className='next-arrow-span'>
                                                                  <img src={PageIndex.Svg.nextArrowIcon} alt='next arrow' className='next-arrow' /></span></Index.Button>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>


                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}
                                                className="grid-column">
                                                <Index.Box className="admin-dashboard-box">
                                                      <Index.Box className="admin-dash-icon-flex">
                                                            <Index.Box className="admin-dash-content-box">
                                                                  <Index.Typography className='admin-dash-text' component='p'>Products</Index.Typography>
                                                                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>1325M</Index.Typography>
                                                            </Index.Box>
                                                            <Index.Box className="admin-dash-icon-box">
                                                                  <img src={PageIndex.Svg.productIcon} className="admin-dash-icons" alt='dashboard icon' />
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="admin-dash-btn-box">
                                                            <Index.Button variant="contained" className='admin-dash-btn'>See Details<span className='next-arrow-span'>
                                                                  <img src={PageIndex.Svg.nextArrowIcon} alt='next arrow' className='next-arrow' /></span></Index.Button>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>

                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}
                                                className="grid-column">
                                                <Index.Box className="admin-dashboard-box">
                                                      <Index.Box className="admin-dash-icon-flex">
                                                            <Index.Box className="admin-dash-content-box">
                                                                  <Index.Typography className='admin-dash-text' component='p'>Category</Index.Typography>
                                                                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>1325M</Index.Typography>
                                                            </Index.Box>
                                                            <Index.Box className="admin-dash-icon-box">
                                                                  <img src={PageIndex.Svg.categoryIcon} className="admin-dash-icons" alt='dashboard icon' />
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="admin-dash-btn-box">
                                                            <Index.Button variant="contained" className='admin-dash-btn'>See Details<span className='next-arrow-span'>
                                                                  <img src={PageIndex.Svg.nextArrowIcon} alt='next arrow' className='next-arrow' /></span></Index.Button>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>

                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}
                                                className="grid-column">
                                                <Index.Box className="admin-dashboard-box">
                                                      <Index.Box className="admin-dash-icon-flex">
                                                            <Index.Box className="admin-dash-content-box">
                                                                  <Index.Typography className='admin-dash-text' component='p'>Vendors</Index.Typography>
                                                                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>1325M</Index.Typography>
                                                            </Index.Box>
                                                            <Index.Box className="admin-dash-icon-box">
                                                                  <img src={PageIndex.Svg.vendorIcon} className="admin-dash-icons" alt='dashboard icon' />
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="admin-dash-btn-box">
                                                            <Index.Button variant="contained" className='admin-dash-btn'>See Details<span className='next-arrow-span'>
                                                                  <img src={PageIndex.Svg.nextArrowIcon} alt='next arrow' className='next-arrow' /></span></Index.Button>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                        <Index.Box className="common-card">
                              <Index.Box className="card-title-flex">
                                    <Index.Typography className='card-title-text'>Listing List</Index.Typography>
                                    <Index.CommonButton className='primary-btn' btnLabel="Add Listing" />
                              </Index.Box>
                              <Index.Box className="admin-dashboard-table-main page-table-main">
                                    <Index.TableContainer component={Index.Paper} className='table-container'>
                                          <Index.Table aria-label="simple table" className='table'>
                                                <Index.TableHead className='table-head'>
                                                      <Index.TableRow className='table-row'>
                                                            <Index.TableCell component='th' className='table-th' width="10%">Name</Index.TableCell>
                                                            <Index.TableCell component='th' className='table-th' width="15%">City</Index.TableCell>
                                                            <Index.TableCell component='th' className='table-th' width="10%">Number</Index.TableCell>
                                                            <Index.TableCell component='th' className='table-th' width="10%" align='right'>Status</Index.TableCell>
                                                            <Index.TableCell component='th' className='table-th' width="10%" align='right'>Action</Index.TableCell>
                                                      </Index.TableRow>
                                                </Index.TableHead>
                                                <Index.TableBody className='table-body'>
                                                      <Index.TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                      >
                                                            <Index.TableCell component='td' scope="row" className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><img src={PageIndex.Svg.avtarIcon} className="admin-table-data-img " alt='User'></img> <Index.Typography className='admin-table-data-text'>GASTON</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'> Id, Cl New Kaelachester</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'>623-880-0509 X6880</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td' align='right'>Status</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'>
                                                                  <Index.Box className="admin-table-data-btn-flex">
                                                                        <Index.Tooltip
                                                                              title="Edit"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn admin-table-edit-btn'>
                                                                                    <img src={PageIndex.Svg.Edit} className='admin-icon' alt='Edit' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>

                                                                        <Index.Tooltip
                                                                              title="Delete"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn admin-table-delete-btn'>
                                                                                    <img src={PageIndex.Svg.Delete} className='admin-icon' alt='Trash' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>
                                                                  </Index.Box>
                                                            </Index.TableCell>
                                                      </Index.TableRow>

                                                      <Index.TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                      >
                                                            <Index.TableCell component='td' scope="row" className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><img src={PageIndex.Svg.avtarIcon} className="admin-table-data-img " alt='User'></img> <Index.Typography className='admin-table-data-text'>GASTON</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'> Id, Cl New Kaelachester</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'>623-880-0509 X6880</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td' align='right'>Status</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'>
                                                                  <Index.Box className="admin-table-data-btn-flex">
                                                                        <Index.Tooltip
                                                                              title="Edit"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn admin-table-edit-btn'>
                                                                                    <img src={PageIndex.Svg.Edit} className='admin-icon' alt='Edit' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>

                                                                        <Index.Tooltip
                                                                              title="Delete"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn admin-table-delete-btn'>
                                                                                    <img src={PageIndex.Svg.Delete} className='admin-icon' alt='Trash' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>
                                                                  </Index.Box>
                                                            </Index.TableCell>
                                                      </Index.TableRow>


                                                      <Index.TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                      >
                                                            <Index.TableCell component='td' scope="row" className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><img src={PageIndex.Svg.avtarIcon} className="admin-table-data-img " alt='User'></img> <Index.Typography className='admin-table-data-text'>GASTON</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'> Id, Cl New Kaelachester</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'>623-880-0509 X6880</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td' align='right'>Status</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'>
                                                                  <Index.Box className="admin-table-data-btn-flex">
                                                                        <Index.Tooltip
                                                                              title="Edit"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn admin-table-edit-btn'>
                                                                                    <img src={PageIndex.Svg.Edit} className='admin-icon' alt='Edit' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>

                                                                        <Index.Tooltip
                                                                              title="Delete"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn admin-table-delete-btn'>
                                                                                    <img src={PageIndex.Svg.Delete} className='admin-icon' alt='Trash' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>
                                                                  </Index.Box>
                                                            </Index.TableCell>
                                                      </Index.TableRow>

                                                      <Index.TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                      >
                                                            <Index.TableCell component='td' scope="row" className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><img src={PageIndex.Svg.avtarIcon} className="admin-table-data-img " alt='User'></img> <Index.Typography className='admin-table-data-text'>GASTON</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'> Id, Cl New Kaelachester</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'>623-880-0509 X6880</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td' align='right'>Status</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'>
                                                                  <Index.Box className="admin-table-data-btn-flex">
                                                                        <Index.Tooltip
                                                                              title="Edit"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn admin-table-edit-btn'>
                                                                                    <img src={PageIndex.Svg.Edit} className='admin-icon' alt='Edit' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>

                                                                        <Index.Tooltip
                                                                              title="Delete"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn admin-table-delete-btn'>
                                                                                    <img src={PageIndex.Svg.Delete} className='admin-icon' alt='Trash' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>
                                                                  </Index.Box>
                                                            </Index.TableCell>
                                                      </Index.TableRow>

                                                      <Index.TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                      >
                                                            <Index.TableCell component='td' scope="row" className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><img src={PageIndex.Svg.avtarIcon} className="admin-table-data-img " alt='User'></img> <Index.Typography className='admin-table-data-text'>GASTON</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'> Id, Cl New Kaelachester</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'>623-880-0509 X6880</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td' align='right'>Status</Index.TableCell>
                                                            <Index.TableCell component='td' className='table-td'>
                                                                  <Index.Box className="admin-table-data-btn-flex">
                                                                        <Index.Tooltip
                                                                              title="Edit"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn admin-table-edit-btn'>
                                                                                    <img src={PageIndex.Svg.Edit} className='admin-icon' alt='Edit' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>

                                                                        <Index.Tooltip
                                                                              title="Delete"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn admin-table-delete-btn'>
                                                                                    <img src={PageIndex.Svg.Delete} className='admin-icon' alt='Trash' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>
                                                                  </Index.Box>
                                                            </Index.TableCell>
                                                      </Index.TableRow>
                                                </Index.TableBody>
                                          </Index.Table>
                                    </Index.TableContainer>
                              </Index.Box>
                              <Index.Box className="admin-pagination-main">
                                    {filterData?.length > 10 && (
                                          <Index.Box className="tale-pagination">
                                                <Index.TablePagination
                                                      component="div"
                                                      rowsPerPageOptions={[10, 20, 50]}
                                                      count={filterData?.length}
                                                      rowsPerPage={rowsPerPage}
                                                      page={page}
                                                      onPageChange={handleChangePage}
                                                      onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                          </Index.Box>
                                    )}
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
