import { useEffect, useRef } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const AddState = (props) => {
  const dispatch = Index.useDispatch();
  const editData = props?.editData;
  const [countryList, setCountryList] = Index.useState([]);
  const [loading, setLoading] = Index.useState(false);

  const getCountryById = (id) =>
    countryList?.find((country) => country._id === id) || null;

  const initialValues = {
    countryId: editData?.countryId?._id || "",
    stateName: editData?.stateName || "",
  };

  const addEditState = (formData) => {
    setLoading(true);
    dispatch(PageIndex.addEditState(formData))
      .then((res) => {
        if (res?.payload) {
          props.setOpenDrawer(false);
          props.setEditData(null);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBack = () => {
    props.setOpenDrawer(false);
    props.setEditData(null);
  };

  const handleInput = (event) => {
    let value = event.target.value;
    value = value
      .replace(/[^a-zA-Z\s]/g, "")
      .replace(/^\s+/, "")
      .replace(/\s\s+/g, " ")
      .slice(0, 30);

    event.target.value = value;
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    setTouched,
  } = Index.useFormik({
    initialValues: initialValues,
    validationSchema: Index.addStateValidation,
    onSubmit: (values) => {
      const formData = {
        ...(editData?._id ? { id: editData._id } : {}),
        countryId: values.countryId,
        stateName: values.stateName?.trim(),
      };
      addEditState(formData);
    },
  });

  const getAllCountryApi = () => {
    dispatch(PageIndex.getAllCountry())
      .then((res) => {
        setCountryList(res?.payload?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  Index.useEffect(() => {
    getAllCountryApi();
  }, []);

  return (
    <Index.Box className="drawer-main">
      <Index.Box className="drawer-header">
        <Index.Typography className="drawer-header-title">
          {editData ? "Update" : "Add"} State
        </Index.Typography>
        <Index.Box>
          <Index.CloseIcon
            className="drawer-cancel-icon"
            onClick={handleBack}
          />
        </Index.Box>
      </Index.Box>

      <form onSubmit={handleSubmit}>
        <Index.Box className="drawer-body">
          <Index.Box className="grid-main add-country-row">
            <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
              <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                className="grid-column">
                <Index.Box className="add-state-input-main admin-input-box">
                  <Index.FormHelperText className='admin-form-lable'>Country Name</Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <Index.Autocomplete
                  className="admin-form-control admin-autocomplete"
                      size="small"
                      name="countryId"
                      disablePortal
                      options={countryList}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, countryId: true });
                      }}
                      getOptionLabel={(option) => option.countryName}
                      value={getCountryById(values.countryId) || null}
                      onChange={(event, newValue) => {
                        handleChange({
                          target: {
                            name: "countryId",
                            value: newValue ? newValue._id : "",
                          },
                        });
                      }}
                      renderOption={(props, option) => (
                        <Index.Box component="li" {...props} className="admin-autocomplete-option">
                          {option.countryName}
                        </Index.Box>
                      )}
                      renderInput={(params) => (
                        <Index.TextField
                          {...params}
                          placeholder="Search Country"
                    className="admin-form-control"
                          onInput={handleInput}
                        />
                      )}
                    />
                    <Index.Typography  className="admin-error-text">
                      {touched?.countryId && errors?.countryId}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                className="grid-column">
                <Index.Box className="add-state-input-main admin-input-box">
                  <Index.FormHelperText className='admin-form-lable'>State Name</Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <Index.TextField
                      className="admin-form-control"
                      placeholder="Enter state name"
                      type="text"
                      size="small"
                      name="stateName"
                      value={values.stateName}
                      inputProps={{ maxLength: 30 }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                          setFieldValue(
                            "stateName",
                            inputValue.replace(/^\s+/, "").replace(/\s+/g, " ")
                          );
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  </Index.Box>
                  <Index.Typography className="admin-error-text">
                    {touched?.stateName && errors?.stateName}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>


        <Index.Box className="drawer-footer">
          <Index.Box className="drawer-footer-button-main">
            <Index.Button className="drawer-footer-button" onClick={handleBack}>
              Cancel
            </Index.Button>
            <Index.CommonButton
              btnLabel={editData ? "Update" : "Add"}
              type="submit"
              loading={loading}
              className="drawer-footer-button"
            />
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Box>
  );
};

export default AddState;
