import React from "react";
import Index from "../../Index";
import PageIndex from "../../PageIndex";
const PageNotFound = () => {

  const navigate = PageIndex.useNavigate()
  return (
    <>
      <Index.Box className="page-not-found-main">
        <Index.Typography className="page-not-found-title" >Page Not Found </Index.Typography>
        <Index.Typography className="page-not-found-para">We couldn't find page you are looking for</Index.Typography>
        <img className="page-not-found-img" src={Index.Png.pagenotfound} alt="img" />
        <Index.Button className="page-not-found-btn" onClick={() => {
          navigate("/")
        }}>Back to home</Index.Button>
      </Index.Box>
    </>
  );
};

export default PageNotFound;