import vh_logo from "../assets/images/png/vh-logo.png";
import virtual_logo from "../assets/images/png/virtualHeightLogo.png";
import bg_babypink from "../assets/images/png/bg_babypink.png";
import bg_black from "../assets/images/png/bg_black.png";
import bg_gray from "../assets/images/png/bg_gray.png";
import bg_light_pink from "../assets/images/png/bg_light_pink.png";
import bg_light_whitish from "../assets/images/png/bg_light_pink.png";
import bg_one from "../assets/images/png/bg_one.png";
import bg_pink from "../assets/images/png/bg_pink.png";
import bg_rama from "../assets/images/png/bg_rama.png";
import bg_soli from "../assets/images/png/bg_soli.png";
import bg_whitish from "../assets/images/png/bg_whitish.png";
import bg_yellow from "../assets/images/png/bg_yellow.png";
import role_permission from "./images/png/role_permission.png";
import pagenotfound from "./images/png/pagenotfound.png";
// import happy_ganesh from "./image/png/happy_ganesh.png";

import happy_ganesh from "../assets/images/png/happy_ganesh.png";
import sidebar_profile from "./images/png/sidebar-profile.png";
import search_logo from "./images/png/search.png";
import profile from "./images/png/profile.png";
import view from "./images/png/view.png";
import printer from "./images/png/printer.png";
import VHLogo from "./images/png/virtualHeightLogo.png";
import inwardPrinter from "./images/png/inwardPrinter.png";
import dataArchitecture from "./images/png/data-architecture.png";


export default {
  dataArchitecture,
  inwardPrinter,
  VHLogo,
  printer,
  sidebar_profile,
  vh_logo,
  bg_babypink,
  bg_black,
  bg_gray,
  bg_light_pink,
  bg_light_whitish,
  bg_one,
  bg_pink,
  bg_rama,
  bg_soli,
  bg_whitish,
  bg_yellow,
  role_permission,
  pagenotfound,
  happy_ganesh,
  search_logo,
  profile,
  view,
  virtual_logo
};
