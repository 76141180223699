import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const AddEditCategory = (props) => {
  const editData = props?.editData;
  const [btnLoading, setBtnLoading] = useState(false);

  const initialValues = {
    category: editData?.category || "",
  };

  const addEditCategoryData = async (values) => {
    setBtnLoading(true);
    try {
      const res = await PageIndex.addEditCategory({
        categoryId: editData?._id ? editData?._id : "",
        category: values?.category,
      });
      if (res.status === 201 || 200) {
        props.getAllCategoryData();
        props.setOpenDrawer(false);
        props.setEditData("");
      }
    } catch (error) {
      console.log(error, "error");
    }
    setBtnLoading(false);
  };

  const { values, errors, handleBlur, setFieldValue, handleSubmit } =
    Index.useFormik({
      initialValues: initialValues,
      validationSchema: Index.addCategoryValidation,
      onSubmit: (values) => {
        addEditCategoryData(values);
      },
    });

  return (
    <Index.Box className="drawer-main">
      <Index.Box className="drawer-header">
        <Index.Typography className="drawer-header-title">
          {editData ? "Update" : "Add"} Category
        </Index.Typography>
        <Index.Box>
          <Index.CloseIcon
            className="drawer-cancel-icon"
            onClick={() => {
              props.setOpenDrawer(false);
              props.setEditData("");
            }}
          />
        </Index.Box>
      </Index.Box>

      <form onSubmit={handleSubmit}>
        <Index.Box className="drawer-body">
          <Index.Box sx={{ width: 1 }} className="grid-main add-category-row">
            <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
              <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                className="grid-column">
                <Index.Box className="add-category-input-main admin-input-box">
                  <Index.FormHelperText className='admin-form-lable'>Category Name{" "}</Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <Index.TextField
                      className="admin-form-control"
                      placeholder="Enter category name"
                      type="text"
                      size="small"
                      inputProps={{ maxLength: 30 }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                          setFieldValue(
                            "category",
                            inputValue.replace(/^\s+/, "").replace(/\s+/g, " ")
                          );
                        }
                      }}
                      name="category"
                      value={values.category}
                      // onChange={(e) => setFieldValue("category", e.target.value)}
                      onBlur={handleBlur}
                    />
                    {errors?.category ? (
                      <Index.Typography className="admin-error-text">
                        {errors?.category}
                      </Index.Typography>
                    ) : null}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="drawer-footer">
          <Index.Box className="drawer-footer-button-main">
            <Index.Button className="drawer-footer-button" onClick={() => props.setOpenDrawer(false)}>
              Cancel
            </Index.Button>
            <Index.CommonButton
              btnLabel={editData ? "Update" : "Add"}
              type="submit"
              loading={btnLoading}
              className="drawer-footer-button"
            />
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Box>
  );
};

export default AddEditCategory;
