// PrintButton.js
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import "./printButton.css"
import PageIndex from '../../../PageIndex';
import PrintableComponent from './PrintableComponent';
const PrintButton = ({ billData }) => {
  const componentRef = useRef();

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <img
            src={PageIndex.Png.printer}
            className="table-icon"
            alt="Printer Icon"
          // onClick={handlePrint}
          />
        )}
        content={() => componentRef.current}
      />
      <div className="print-only">
        <PrintableComponent billData={billData} ref={componentRef} />
      </div>
    </>
  );
};

export default PrintButton;
