import { Box, Modal } from "@mui/material";
import React from "react";
import Index from "../Index";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};


const LogoutModal = ({ open, setOpen, handleLogout }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        className="admin-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style} className="admin-modal-inner admin-delete-modal-inner-main">
          <Index.Typography className="admin-delete-modal-title">
            Are you sure, you want to Log out?
          </Index.Typography>

          <Index.Box className="admin-delete-modal-btn-flex">
            <Index.Button className="admin-modal-delete-btn" onClick={handleClose}>Cancel</Index.Button>
            <Index.Button className="admin-modal-delete-btn" onClick={() => handleLogout()}>Yes</Index.Button>
          </Index.Box>
        </Index.Box>
      </Modal>
    </>
  );
};

export default LogoutModal;
