import React from "react";
const Loading = () => {
  return (
    <div className="loading-main">

      <div className="dots-loader-main">
        <div class="dot-loader"></div>
        <div class="dot-loader dot-loader--2"></div>
        <div class="dot-loader dot-loader--3"></div>
      </div>
      <div className="dots-loader-text">
        I&nbsp;N&nbsp;V&nbsp;E&nbsp;N&nbsp;T&nbsp;O&nbsp;R&nbsp;Y&nbsp;&nbsp;&nbsp;M&nbsp;A&nbsp;N&nbsp;A&nbsp;G&nbsp;E&nbsp;M&nbsp;E&nbsp;N&nbsp;T
      </div>
    </div>


  );
};

export default Loading;
