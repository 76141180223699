import * as Yup from "yup";

const charSpace = (value) =>
  /^[^-\s][a-zA-Z\s]+$/.test(value) || value.length === 0;
// Validation schema
export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Email is not valid"
    )
    .required("Email is required"),
  //   password: Yup.string()
  //     .required("Password is required")
  //     .matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //       "Password length should be minimum 8 character ex. 99@Inchroad"
  //     ),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters"),
});

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Email is not valid"
    )
    .required("Email is required"),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required ")
    .min(8, "Password too short. Minimum 8 characters required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/,
  //   "Must contain 8 characters, one uppercase, one lowercase, one number and one special case characters"
  // ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords do not match. Please try again.")
    .required("Confirm password is required"),
});

export const addCategoryValidation = Yup.object().shape({
  category: Yup.string().required("Please enter Category name"),
});

export const addCountryValidation = Yup.object().shape({
  countryName: Yup.string().required("Please enter country name"),
});
export const addStateValidation = Yup.object().shape({
  countryId: Yup.string().required("Please select country"),
  stateName: Yup.string().required("Please enter state name"),
});
export const addCityValidation = Yup.object().shape({
  countryId: Yup.string().required("Please select country"),
  stateId: Yup.string().required("Please select state"),
  cityName: Yup.string().required("Please enter city name"),
});

export const addUnitValidation = Yup.object().shape({
  name: Yup.string().required("Please enter unit name"),
});

//  users validations
export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name is too short, min 2 characters")
    .max(16, "First name is too long , max 16 characters")
    .required("First name is required")
    .test(
      "First name",
      "First name allows character with space between",
      charSpace
    ),
  lastName: Yup.string()
    .min(2, "Last name is too short ,min 2 characters")
    .max(16, "Last name is too long ,max 16 characters")
    .required("Last name is required")
    .test(
      "Last  name",
      "Last name allows character with space between",
      charSpace
    ),
  email: Yup.string()
    .email("Enter the valid email")
    .matches(
      /^(([^<>()[\]\\.,;:-\s@#"]+(\.[^<>()[\]\\.,;:-\s@#"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid email"
    )
    .required("Email is required"),

  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .min(10, "Please enter valid mobile number")
    .max(10, "Please enter valid mobile number"),
  // address: Yup.string().required("Address is required"),
  roleType: Yup.string().required("Role is required"),
  password: Yup.string().when("isEdit", {
    is: false,
    then: () =>
      Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character."
        ),
    otherwise: () => Yup.string().nullable(),
  }),
  confirmPassword: Yup.string().when("isEdit", {
    is: false,
    then: () =>
      Yup.string()
        .required("Confirm Password is required")
        .oneOf(
          [Yup.ref("password")],
          "Passwords do not match. Please try again."
        ),
    otherwise: () => Yup.string().nullable(),
  }),

  profile: Yup.mixed()
    .required("Image is required")
    .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file); //edit
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type); //add
      }
    }),
});

export const rolePermissionSchema = Yup.object().shape({
  roleName: Yup.string()
    .required("Please enter role")
    .min(3, "Role should be minimum 3 characters long")
    .max(30, "Role should not contain more than 30 characters")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Role must not contain numbers or special characters"
    ),
  // permissions: Yup.array().min(1, 'Please select at least one permission')
});

export const brandMasterSchema = Yup.object().shape({
  brandName: Yup.string()
    .required("Please enter brand name")
    .min(2, "Brand name is too short ,min 2 characters")
    .max(25, "Brand name is too long ,max 25 characters"),
});

export const productMasterSchema = Yup.object().shape({
  stockType: Yup.string().required("Stock type is required"),
  productCode: Yup.string().required("Product code is required"),
  productName: Yup.string().required("Product name is required"),
  baseUnit: Yup.string().required("Base unit is required"),
  HSNCode: Yup.string().required("HSN code is required"),
  productCategory: Yup.string().required("Product category is required"),
  description: Yup.string().required("Description is required"),
  purchaseRate: Yup.string().required("Purchase rate is required"),
  saleRate: Yup.string().required("SL rate is required"),
  MRP: Yup.string().required("MRP is required"),
  image: Yup.mixed()
    .required("Image is required")
    .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file); //edit
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type); //add
      }
    }),
});

export const vendorMasterSchema = Yup.object().shape({
  vendorName: Yup.string().required("Vendor name is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  address: Yup.string().required("Address is required"),
  email: Yup.string()
    .email("Enter the valid email")
    .matches(
      /^(([^<>()[\]\\.,;:-\s@#"]+(\.[^<>()[\]\\.,;:-\s@#"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid email"
    )
    .required("Email is required"),
  contactNo: Yup.string()
    .required("Contact number is required")
    .min(10, "Please enter valid mobile number")
    .max(10, "Please enter valid mobile number"),
});

export const billMasterSchema = Yup.object().shape({
  customerName: Yup.string().required("Customer Name is required"),
  mobileNumber: Yup.number().required("Mobile Number is required"),
});

export const profileValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  gender: Yup.string().required("Gender is required"),
  dob: Yup.date()
    .required("DateOfBirth is required")
    .max(new Date(), "Future dates are not allowed"),
  profile: Yup.mixed()
    .required("Image is required")
    .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file); //edit
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type); //add
      }
    }),
});

export const addIndentRequestValidation = Yup.object({
  items: Yup.array()
    .of(
      Yup.object({
        product: Yup.string().required("Product is required"),
        quantity: Yup.number().required("Quantity is required"),
        // vendor: Yup.string().required("Vendor is required"),
      })
    )
    .min(1, "Product is required"),
  remark: Yup.string().max(1000, "Remarks must be at most 1000 characters"),
});

export const indentInwardValidation = Yup.object({
  items: Yup.array()
    .of(
      Yup.object({
        product: Yup.string().required("Product is required"),
        quantity: Yup.number().required("Quantity is required"),
        batchNo: Yup.string().required("Batch no. is required"),
        expireDate: Yup.date().required("Expire date is required"),
      })
    )
    .min(1, "Product is required"),
  invoiceNo: Yup.string().required("Invoice no. is required"),
  invoiceDate: Yup.date().required("Invoice date is required"),
  invoiceAmount: Yup.number().required("Invoice ammount is required"),
  supplierName: Yup.string().required("Supplier name is required"),
  image: Yup.string().required("Bill is required"),
  remark: Yup.string().max(1000, "Remarks must be at most 1000 characters"),
});

export const changePasswordValidation = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character."
    ),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf(
      [Yup.ref("newPassword")],
      "Passwords do not match. Please try again."
    ),
});
