import Index from "../../../container/Index";
import PageIndex from "../../../container/PageIndex";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

const IndentModal = (props) => {
    const formatQuantity = (quantity) => {
        if (quantity == null) return '0';
        return PageIndex.numeralValueConverter(quantity)
    };

    const formattedItems = props.productData.map((row) => {
        const productName = row?.product?.productName
        const quantity = formatQuantity(row?.quantity);
        return `${productName} (${quantity})`;
    });

    const joinedItems = formattedItems.join(', ');

    return (
        <Index.Modal
            className="admin-modal"
            open={props.isOpen}
            onClose={() => {
                props.setIsOpen(false)
                props.setProductData([])
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Index.Box sx={style} className="admin-modal-inner admin-delete-modal-inner-main">
                <Index.Box className='admin-modal-header'>
                    <Index.Typography className="admin-modal-title">Product Name</Index.Typography>
                    <Index.CloseIcon
                        className="modal-close-btn"
                        sx={{ cursor: "pointer" }}
                        onClick={() => props.setIsOpen(false)}
                    />

                </Index.Box>
                <Index.Box className='product-box'>
                    {/* {
                        props.productData.map((row) => (
                            <Index.Box className="product-qty-box">
                                <span className="product-qty-title">{row?.product?.productName}</span>
                                <span className="qty-title">({row?.quantity
                                    ? Number.isInteger(row?.quantity)
                                        ? Index.numeral(row?.quantity).format('0a')
                                        : Index.numeral(row?.quantity).format('0.000a')
                                    : 0})</span>

                            </Index.Box>
                        ))
                    } */}
                        <Index.Typography className="qty-title">{joinedItems}</Index.Typography>
                </Index.Box>
            </Index.Box>

        </Index.Modal>
    );
};

export default IndentModal;
