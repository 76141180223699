import Png from "../assets/Png";
import DeleteModal from "../component/common/deleteModal/DeleteModal";
import {
  deleteRole,
  getAllRole,
  getAllUser,
  deleteUser,
  activeDeActiveUser,
  addEditRole,
  addEditCountry,
  deleteCountry,
  activeDeactiveCountry,
  getAllCountry,
  getAllState,
  addEditState,
  deleteState,
  activeDeactiveState,
  getStateListByCountry,
  addEditCity,
  deleteCity,
  getAllCity,
  activeDeactiveCity,
  addEditUnit,
  deleteUnit,
  getAllUnit,
  activeDeactiveUnit,
  getAllBrandMasters,
  activeDeActiveBrand,
  addEditBrand,
  deleteBrand,
  getAllCategory,
  getSingleCategory,
  addEditCategory,
  activeDeActiveCategory,
  deleteCategory,
  getAllBaseUnit,
  addEditProduct,
  getAllProduct,
  activeDeActiveProduct,
  deleteProduct,
  getCityListByState,
  addEditVendor,
  deleteVendor,
  activeDeActiveVendor,
  getAllVendor,
  getSingleUser,
  addEditUser,
  editProfile,
  changePassword,
  addBill,
  getAllBills,
  updateBillStatus,
  getAllActivityLogs,
  printBill
  
} from "../redux/features/admin/AdminServices";
import { useFormik } from "formik";
import Svg from "../assets/Svg";
import Jpg from "../assets/Jpg";
import Gif from "../assets/Gif";
import useDebounceHook from "../component/common/commonHooks/useDebounceHook";
import { numeralValueConverter } from "../component/common/numeralValue/numeralValueConverter";
import ApproveModal from "../component/common/approveModal/ApproveModal";
import IndentModal from "../component/common/indentModal/IndentModal";
import { PageLoader } from "../component/common/button/ButtonLoader";
import CancelModal from "../component/common/billStatusModal/BillCancelModal";
import CompletedModal from "../component/common/billStatusModal/BillCompletedModal";
import { BillModal } from "../component/common/billStatusModal/BillModal";
export default {

  addEditRole,
  Png,
  Gif,
  DeleteModal,
  getAllCategory,
  getSingleCategory,
  addEditCategory,
  activeDeActiveCategory,
  deleteCategory,
  deleteRole,
  getAllRole,
  BillModal,
  useFormik,
  getAllUser,
  deleteUser,
  activeDeActiveUser,
  Svg,
  addEditCountry,
  deleteCountry,
  activeDeactiveCountry,
  getAllCountry,
  getAllState,
  getStateListByCountry,
  addEditState,
  deleteState,
  activeDeactiveState,
  addEditCity,
  deleteCity,
  getAllCity,
  getCityListByState,
  activeDeactiveCity,
  addEditUnit,
  deleteUnit,
  getAllUnit,
  activeDeactiveUnit,
  getAllBrandMasters,
  activeDeActiveBrand,
  addEditBrand,
  deleteBrand,
  getAllBaseUnit,
  addEditProduct,
  getAllProduct,
  activeDeActiveProduct,
  deleteProduct,
  addEditVendor,
  deleteVendor,
  activeDeActiveVendor,
  getAllVendor,
  getSingleUser,
  addEditUser,
  editProfile,
  changePassword,
  Jpg,
  CancelModal,
  CompletedModal,
  useDebounceHook,
  numeralValueConverter,
  useDebounceHook,
  ApproveModal,
  IndentModal,
  PageLoader,
  addBill,
  printBill,
  getAllBills,
  updateBillStatus,
  getAllActivityLogs,
};
