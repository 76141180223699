import React, { useState } from "react";
import Index from "../../Index";
import PageIndex from "../../PageIndex";

const ChangePassword = () => {
  const navigate = Index.useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (formData, action) => {
    try {
      const res = await PageIndex.changePassword(formData);
      if (res?.status == 200) {
        action.resetForm();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const initialValues = {
    newPassword: "",
    oldPassword: "",
    confirmPassword: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = Index.useFormik({
    initialValues: initialValues,
    validationSchema: Index.changePasswordValidation,
    onSubmit: (values, action) => {
      const formData = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      handleFormSubmit(formData, action);
    },
  });

  return (
    <>

      <Index.Box className="admin-dashboard-content admin-role-containt">
        <Index.Box className="admin-user-list-flex">
          <Index.Box className="admin-breadcrumb-main">
            <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
            <Index.Button className='admin-breadcrumb-text' onClick={() => {
              navigate("/admin/profile");
            }}>Account</Index.Button>
            <Index.Typography className='admin-breadcrumb-text'>/</Index.Typography>
            <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'> Change Password</Index.Button>
          </Index.Box>
        </Index.Box>


        <Index.Box className="change-pass-main common-card">
          <Index.Typography className="change-pass-para">
            In order to keep your account safe you need to create a strong
            password
          </Index.Typography>


          <form onSubmit={handleSubmit}>
            <Index.Box className='grid-row'>
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }}
                    className="grid-column">
                    <Index.Box className="admin-change-pass-input-main admin-input-box">
                      <Index.FormHelperText className='admin-form-lable'>Old Password*</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Stack component="label" noValidate>
                          <Index.OutlinedInput
                            className="admin-form-control admin-form-control-eye"
                            name="oldPassword"
                            onBlur={handleBlur}
                            placeholder="Enter old password"
                            type={showOldPassword ? "text" : "password"}
                            inputProps={{ maxLength: 16 }}
                            onChange={(e) =>
                              setFieldValue("oldPassword", e.target.value.trim())
                            }
                            value={values.oldPassword}
                            endAdornment={
                              <Index.InputAdornment
                                position="end"
                                className="pass_position"
                              >
                                <Index.IconButton
                                  className="password-eye-icon"
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowOldPassword(!showOldPassword)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showOldPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                          <Index.FormHelperText className="admin-error-text">
                            {touched?.oldPassword && errors?.oldPassword}
                          </Index.FormHelperText>
                        </Index.Stack>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }}
                    className="grid-column">
                    <Index.Box className="admin-change-pass-input-main admin-input-box">
                      <Index.FormHelperText className='admin-form-lable'>New Password*</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Stack component="label" noValidate>
                          <Index.OutlinedInput
                            className="admin-form-control-eye admin-form-control"
                            name="newPassword"
                            onBlur={handleBlur}
                            placeholder="Enter new password"
                            type={showNewPassword ? "text" : "password"}
                            inputProps={{ maxLength: 16 }}
                            onChange={(e) =>
                              setFieldValue("newPassword", e.target.value.trim())
                            }
                            value={values.newPassword}
                            endAdornment={
                              <Index.InputAdornment
                                position="end"
                                className="pass_position"
                              >
                                <Index.IconButton
                                  className="password-eye-icon"
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowNewPassword(!showNewPassword)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                          <Index.FormHelperText className="admin-error-text">
                            {touched?.newPassword && errors?.newPassword}
                          </Index.FormHelperText>
                        </Index.Stack>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}
                    className="grid-column">
                    <Index.Box className="admin-change-pass-input-main admin-input-box">
                      <Index.FormHelperText className='admin-form-lable'> Confirm Password*</Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Stack component="label" noValidate>
                          <Index.OutlinedInput
                            className="admin-form-control-eye admin-form-control"
                            name="confirmPassword"
                            onBlur={handleBlur}
                            placeholder="Re-enter new password"
                            type={showConfirmPassword ? "text" : "password"}
                            inputProps={{ maxLength: 16 }}
                            onChange={(e) =>
                              setFieldValue("confirmPassword", e.target.value.trim())
                            }
                            value={values.confirmPassword}
                            endAdornment={
                              <Index.InputAdornment
                                position="end"
                                className="pass_position"
                              >
                                <Index.IconButton
                                  className="password-eye-icon"
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                          <Index.FormHelperText className="admin-error-text">
                            {touched?.confirmPassword && errors?.confirmPassword}
                          </Index.FormHelperText>
                        </Index.Stack>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-btn-flex-end primary-btn-main">
              <Index.CommonButton
                btnLabel="Submit"
                type="submit"
                className="primary-btn"
              />
            </Index.Box>
          </form>
        </Index.Box>
      </Index.Box>

    </>
  );
};

export default ChangePassword;
