import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { ButtonLoader } from "../button/ButtonLoader";
import Index from "../../../container/Index";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};


const CancelModal = ({ open, setOpen, handleStatus, loading }) => {
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <Modal
            className="admin-modal"
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Index.Box sx={style} className="admin-modal-inner admin-delete-modal-inner-main">
                <Typography
                    className="admin-delete-modal-title"
                    component="h2"
                >
                    Are You Sure ?
                </Typography>
                <Typography
                    className="admin-delete-modal-para"
                >
                    You want to Cancel the bill status?
                </Typography>

                <Index.Box className="admin-delete-modal-btn-flex">
                    <Index.Button className="admin-modal-delete-btn" onClick={() => setOpen(false)}>Cancel</Index.Button>
                    {loading ?
                        <Index.Button className="admin-modal-delete-btn"><ButtonLoader /></Index.Button> :
                        <Index.Button className="admin-modal-delete-btn" onClick={handleStatus}>Ok</Index.Button>
                    }
                </Index.Box>
            </Index.Box>
        </Modal>
    )
}

export default CancelModal