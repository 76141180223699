import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { PageLoader } from "../../../../component/common/button/ButtonLoader";

const label = { inputProps: { "aria-label": "Switch demo" } };

const VendorMaster = () => {
  const navigate = Index.useNavigate();
  const [loadingRowId, setLoadingRowId] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [removeId, setRemoveId] = useState("");
  const [removeIndex, setRemoveIndex] = useState();
  const [apiPage, setApiPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const apiChunkDataLimit = 50;
  const debouncTime = useRef(null);

  const { adminData } = Index.useSelector((state) => state.admin);
  const permissions = adminData?.role?.permissions?.VendorManagement;

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    if (newPage + 1 >= Math.ceil(vendorList.length / rowsPerPage)) {
      setApiPage(apiPage + 1);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    setApiPage(1);
  };

  const handleSearchChange = (e) => {
    if (debouncTime.current) {
      clearTimeout(debouncTime.current);
    }
    setPage(1);
    setApiPage(1);
    setSearchTerm(
      e.target.value.replace(/^\s+/, "").replace(/\s\s+/g, " ").slice(0, 30)
    );
    // debouncTime.current = setTimeout(() => {
    // }, 1000);
  };

  const getVendorList = async () => {
    setLoading(true);
    const res = await PageIndex.getAllVendor({
      page: apiPage,
      limit: apiChunkDataLimit,
      search: searchTerm,
    });

    if (res?.status === 200) {
      if (page === 1) {
        setVendorList(res?.data);
      } else {
        setVendorList((prev) => [...prev, ...res?.data]);
      }
      setTotalDataCount(res?.totalDocuments);
    }
    setLoading(false);
  };

  useEffect(() => {
    setFilterData(vendorList);
  }, [vendorList]);

  useEffect(() => {
    getVendorList();
  }, [apiPage, searchTerm]);

  const handleDelete = async () => {
    try {
      const res = await PageIndex.deleteVendor(removeId);
      if (res?.status === 200) {
        setOpen(false);
        setRemoveId("");
        let tempList = [...vendorList];
        tempList.splice(removeIndex, 1);
        setVendorList(tempList);
        if (totalDataCount > 0) {
          setTotalDataCount(totalDataCount - 1);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleStatus = async (vendorId, index) => {
    const data = new URLSearchParams();
    setLoadingRowId(vendorId);
    data.append("_id", vendorId);
    const res = await PageIndex.activeDeActiveVendor(data);
    if (res?.status === 200) {
      getVendorList();
      // let tempList = [...vendorList];
      // tempList[index].isActive = !tempList[index].isActive;
      // setVendorList(tempList);
    }
  };

  const handleEdit = (item) => {
    navigate("/admin/vendor-master/add-edit", {
      state: {
        item: {
          ...item,
          country: item?.country?._id,
          state: item?.state?._id,
          city: item?.city?._id,
        },
      },
    });
  };

  if (permissions?.View) {
    return (
      <>

        <Index.Box className="admin-dashboard-content admin-vendor-containt">
          <Index.Box className="admin-user-list-flex">
            <Index.Box className="admin-breadcrumb-main">
              <img src={PageIndex.Svg.homeIcon} className="admin-breadcrumb-img" alt="breadcrumb" />
              <Index.Button className='admin-breadcrumb-text' onClick={() => {
                navigate("/admin/dashboard");
              }}>Dashboard</Index.Button>
              <Index.Typography className='admin-breadcrumb-text'>/</Index.Typography>
              <Index.Button className='admin-breadcrumb-text admin-breadcrumb-active'>Vendor Master</Index.Button>
            </Index.Box>
            <Index.Box className="admin-search-right-main">
              <Index.Box className="search-input-box admin-input-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    size="small"
                    placeholder="Search"
                    className="admin-form-control"
                    onChange={handleSearchChange}
                    value={searchTerm}
                  />
                  <img src={PageIndex.Svg.searchIcon} className="search-grey-img" alt="search" />
                </Index.Box>
              </Index.Box>

              {permissions?.Add && (
                <Index.Box className="primary-btn-main add-btn-main">
                  <Index.CommonButton
                    btnLabel="Add Vendor"
                    startIcon={<Index.AddCircleOutlinedIcon className="add-btn-icon" />}
                    className={"primary-btn add-btn"}
                    onClick={() => navigate("/admin/vendor-master/add-edit")}
                  />
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>



          <Index.Box className="common-card">

            <Index.Box className='grid-row'>
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                    className="grid-column">
                    <Index.Box className="page-table-main vendor-table-main">
                      <Index.Paper
                        // sx={{ width: "100%", overflow: "hidden" }}
                        className="table-paper"
                      >
                        <Index.TableContainer
                          // sx={{ maxHeight: 480 }}
                          className="table-container"
                        >
                          <Index.Table
                            className="table"
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell className="table-th" variant="th">
                                  Sr.No
                                </Index.TableCell>
                                <Index.TableCell className="table-th" variant="th">
                                  Vendor Name
                                </Index.TableCell>
                                <Index.TableCell className="table-th" variant="th">
                                  Country Name
                                </Index.TableCell>
                                <Index.TableCell className="table-th" variant="th">
                                  State Name
                                </Index.TableCell>
                                <Index.TableCell className="table-th" variant="th">
                                  City Name
                                </Index.TableCell>
                                <Index.TableCell className="table-th" variant="th">
                                  Address
                                </Index.TableCell>
                                <Index.TableCell className="table-th" variant="th">
                                  Contact No.
                                </Index.TableCell>
                                <Index.TableCell className="table-th" variant="th">
                                  Email Id
                                </Index.TableCell>
                                {permissions?.Edit && (
                                  <Index.TableCell className="table-th" variant="th">
                                    Status
                                  </Index.TableCell>
                                )}

                                {(permissions?.Edit || Permissions?.Delete) && (
                                  <Index.TableCell className="table-th" variant="th" align="right">
                                    Action
                                  </Index.TableCell>
                                )}
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {filterData?.length > 0 ? (
                                filterData
                                  ?.slice(
                                    (page - 1) * rowsPerPage,
                                    (page - 1) * rowsPerPage + rowsPerPage
                                  )
                                  ?.map((row, index) => (
                                    <Index.TableRow key={row._id} className="table-row">
                                      <Index.TableCell className="table-td" variant="td">
                                        <Index.Typography className="admin-table-data-text">
                                          {(page - 1) * rowsPerPage + index + 1}
                                        </Index.Typography>
                                      </Index.TableCell>
                                      <Index.TableCell className="table-td" variant="td">
                                        <Index.Typography className="admin-table-data-text">
                                          {row?.vendorName || "-"}
                                        </Index.Typography>
                                      </Index.TableCell>

                                      <Index.TableCell className="table-td" variant="td">
                                        <Index.Typography className="admin-table-data-text">
                                          {row?.country?.countryName || "-"}
                                        </Index.Typography>
                                      </Index.TableCell>

                                      <Index.TableCell className="table-td" variant="td">
                                        <Index.Typography className="admin-table-data-text">
                                          {row?.state?.stateName || "-"}
                                        </Index.Typography>
                                      </Index.TableCell>

                                      <Index.TableCell className="table-td" variant="td">
                                        <Index.Typography className="admin-table-data-text">
                                          {row?.city?.cityName || "-"}
                                        </Index.Typography>
                                      </Index.TableCell>

                                      <Index.TableCell className="table-td" variant="td">
                                        <Index.Typography className="admin-table-data-text">
                                          {row?.address || "-"}
                                        </Index.Typography>
                                      </Index.TableCell>

                                      <Index.TableCell className="table-td" variant="td">
                                        <Index.Typography className="admin-table-data-text">
                                          {row?.contactNo || "-"}
                                        </Index.Typography>
                                      </Index.TableCell>

                                      <Index.TableCell className="table-td" variant="td">
                                        <Index.Typography className="admin-table-data-text">
                                          {row?.email || "-"}
                                        </Index.Typography>
                                      </Index.TableCell>

                                      {permissions?.Edit && (
                                        <Index.TableCell className="table-td" variant="td">
                                          {loading && loadingRowId === row?._id ? (
                                            <Index.CircularProgress
                                              color="secondary"
                                              size={20}
                                            />
                                          ) : (
                                            <Index.Switch
                                              className="status-checkbox"
                                              {...label}
                                              checked={row?.isActive}
                                              onClick={() =>
                                                handleStatus(
                                                  row?._id,
                                                  row?.isActive ? false : true
                                                )
                                              }
                                              color="success"
                                              size="small"
                                            />
                                          )}
                                        </Index.TableCell>
                                      )}
                                      {(permissions?.Edit || permissions?.Delete) && (
                                        <Index.TableCell className="table-td" variant="td">
                                          <Index.Box className="admin-table-data-btn-flex">
                                            {permissions?.Edit && (
                                              <Index.Tooltip
                                                title="Edit"
                                                arrow
                                                placement="bottom"
                                                className="admin-tooltip"
                                              >
                                                <Index.Button
                                                  className="admin-table-data-btn admin-table-edit-btn"
                                                  onClick={() => {
                                                    handleEdit(row);
                                                  }}
                                                >
                                                  <img
                                                    src={PageIndex.Svg.Edit}
                                                    alt="Edit Icon"
                                                    className="table-icon"
                                                  />
                                                </Index.Button>
                                              </Index.Tooltip>
                                            )}
                                            {permissions?.Delete && (
                                              <Index.Tooltip
                                                title="Delete"
                                                arrow
                                                placement="bottom"
                                                className="admin-tooltip"
                                              >
                                                <Index.Button
                                                  className="admin-table-data-btn admin-table-delete-btn"
                                                  onClick={() => {
                                                    setOpen(true);
                                                    setRemoveId(row?._id);
                                                    setRemoveIndex(
                                                      (page - 1) * rowsPerPage + index
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src={PageIndex.Svg.Delete}
                                                    alt="Delete Icon"
                                                    className="table-icon"
                                                  />
                                                </Index.Button>
                                              </Index.Tooltip>
                                            )}
                                          </Index.Box>
                                        </Index.TableCell>
                                      )}
                                    </Index.TableRow>
                                  ))
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    colSpan={10}
                                    align="center"
                                    className="data-not-found-main"
                                  >
                                    <p className="data-not-found-text">
                                      {loading ? <PageLoader /> : "No data found..."}
                                    </p>
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Paper>
                      {totalDataCount > 10 && (
                        <Index.Box className="tale-pagination">
                          <Index.TablePagination
                            component="div"
                            rowsPerPageOptions={[10, 20, 50]}
                            count={totalDataCount}
                            rowsPerPage={rowsPerPage}
                            page={page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Index.Box>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <PageIndex.DeleteModal
          open={open}
          setOpen={setOpen}
          handleDelete={handleDelete}
        />
      </>
    );
  } else {
    navigate("/admin/dashboard");
  }
};

export default VendorMaster;
